<template>

    <div>

        <FanAccount @onUpdateSelection="updateSelected"></FanAccount>
        <ArtistAccount @onUpdateSelection="updateSelected"></ArtistAccount>

    </div>
    
  
</template>

<script>

import FanAccount from "./account-type/FanAccount.vue"
import ArtistAccount from "./account-type/ArtistAccount.vue"

export default {
    name: "AccountTypeSelector",
    components: {
        FanAccount,
        ArtistAccount,
    },
    data() {
        return {
            selected: '',
            test: null,
        }
    },
    methods: {
        updateSelected(selected) {
            this.selected = selected;
            this.test = selected;
            this.$emit('onSelected', selected)
        }
    },
}
</script>

<style scoped>

</style>