var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-dark text-left mt-4",on:{"click":_vm.updateSelected}},[_c('div',{staticClass:"rounded-circle shadow circle-icon d-inline-block button-border",class:{
              'bg-dark': _vm.$parent.selected !== 'discover',
              'bg-light': _vm.$parent.selected === 'discover', 
              'text-light': _vm.$parent.selected !== 'discover',
              'text-dark': _vm.$parent.selected === 'discover'
          }},[(_vm.$parent.selected !== 'discover')?_c('img',{staticClass:"headphones mt-2",attrs:{"src":require("../../../assets/onboarding/headphones.svg")}}):_c('img',{staticClass:"headphones mt-2",attrs:{"src":require("../../../assets/onboarding/headphones-dark.svg")}})]),_c('div',{staticClass:"shadow fan-button-body button-border text-light text-left pl-3",class:{
              'bg-dark': _vm.$parent.selected !== 'discover',
              'bg-light': _vm.$parent.selected === 'discover', 
              'text-light': _vm.$parent.selected !== 'discover',
              'text-dark': _vm.$parent.selected === 'discover'
          }},[_vm._m(0)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"mb-0 button-text"},[_c('b',[_vm._v("Fan!")]),_vm._v(" "),_c('span',{staticClass:"font-weight-lighter"},[_vm._v("Just show me the music")])])}]

export { render, staticRenderFns }