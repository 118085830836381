<template>
  <div>
    <b-button
      block
      class="text-left text-light font-weight-lighter pl-0 pt-0 pb-0 mt-0 mt-1 hamburger-menu-link"
      size="lg"
      variant="link"
      :to="{
        name: 'LibraryArea',
        params: { area: 'discover', name: 'Library' },
      }"
      >My Collection</b-button
    >

    <b-button
      block
      class="text-left text-light font-weight-lighter pl-0 pt-0 pb-0 mt-0 mt-1 hamburger-menu-link"
      size="lg"
      variant="link"
      to="/"
      >View Feed</b-button
    >
    <b-button
      block
      class="text-left text-light font-weight-lighter pl-0 pt-0 pb-0 mt-0 mt-1 hamburger-menu-link"
      size="lg"
      variant="link"
      :to="{ name: 'AccountSettings' }"
      >Settings</b-button
    >
    <b-button
      block
      class="text-left text-light font-weight-lighter pl-0 pt-0 pb-0  mt-4 mt-md-5 hamburger-menu-link"
      size="lg"
      variant="link"
      :to="{ name: 'AppPage' }"
      >Trackd App</b-button
    >
    <b-button
      block
      class="text-left text-light font-weight-lighter pl-0 pt-0 pb-0 mt-0 mt-1 hamburger-menu-link"
      size="lg"
      variant="link"
      :to="{ name: 'AboutUs' }"
      >About Us</b-button
    >
    <b-button
      block
      class="text-left text-light font-weight-lighter pl-0 pt-0 pb-0 mt-0 mt-1 hamburger-menu-link"
      size="lg"
      variant="link"
      :to="{ name: 'MusicRights' }"
      >Your Music Rights</b-button
    >
  </div>
</template>

<script>
export default {
  name: "DiscoverNav",
};
</script>

<style></style>
