<template>
  <div>
    <!-- MODAL -->

    <b-modal
      id="chipin-creator-backstage-pass-modal"
      ref="chipin-creator-backstage-pass-modal"
      size="lg"
      content-class="bg-dark text-light  "
      centered
      hide-footer
      hide-header
      header-close-variant="light"
    >

    <b-container fluid>
      <b-row>

        <b-col cols="12" class="px-0">
            <div style="position: relative;">
                <div class="gradient">
                    <b-row>
                        <b-col class="float-left">
                            <img
                                src="../../../../assets/trackd-icon-colours.svg"
                                class="mb-4 mx-auto text-left mt-2"
                                height="25"
                                alt="Logo"
                            />
                        </b-col>

                        <b-col class="float-right">
                            <b-button
                                size="sm"
                                variant="link"
                                class="float-right"
                                @click="$bvModal.hide('chipin-creator-backstage-pass-modal')"
                            >
                                <img
                                src="../../../../assets/section-close.svg"
                                class="mx-auto text-right float-right mt-2"
                                height="20"
                                alt="Close"
                                />
                            </b-button>
                        </b-col>

                        <b-col class="mb-3" cols="10" offset="1" sm="8" offset-sm="2">
          
                            <b-row class="mb-3">
                                <b-col class="p-1">
                                <b-badge pill variant="info" class="w-100 text-info text-small"
                                    >&nbsp;a</b-badge
                                >
                                </b-col>
                                <b-col class="p-1">
                                <b-badge
                                    pill
                                    variant="info"
                                    class="w-100 text-info text-small"
                                    >&nbsp;b</b-badge
                                >
                                </b-col>
                                <b-col class="p-1">
                                <b-badge
                                    pill
                                    variant="warning"
                                    class="w-100 text-warning text-small"
                                    >&nbsp;c</b-badge
                                >
                                </b-col>
                            </b-row>
                        </b-col>

                        <b-col cols="12">
                        <b-button v-b-modal.chipin-creator-getstarted-modal variant="link" @click="$bvModal.hide('chipin-creator-backstage-pass-modal')">
                            <img src="../../../../assets/arrow-left.svg" />
                        </b-button>
                        </b-col>
                    </b-row>
                </div>
                <img src="../../../../assets/backstage-pass.svg"/>
                <div style="z-index:1000; position: absolute; top:270px; left: 0%;">
                    <b-row class="text-center">
                        <b-col>
                            <p class="heading-text backstage mb-0">Back Stage Pass</p>
                            <p class="jump-the-line mb-3">Jump The Line</p>
                            <b-row align-v="center" align-h="center" class="mb-2">
                                <span class="activate mt-1 ml-4 mr-2">Activate</span> 
                                <img src="../../../../assets/chipin-plus-text.svg" class="" height="35"/>
                            </b-row>
                            <p class="special-offer heading-text mb-1">Special Offer</p>
                            <h3 class="price text-yellow mb-0">$9.49</h3>
                            <p class="cancel text-yellow mb-0">Cancel Anytime. No Risk!</p>
                        </b-col>
                    </b-row>

                    <b-row class="mt-4 px-5">

                        <b-col cols="12" class="px-5">
                            <b-row class="px-3">
                                <b-col class="included-info" cols="2">
                                    <img src="../../../../assets/tick.svg" alt="" />
                                </b-col>
                                <b-col class="pl-0">
                                    <p class="font-weight-light mb-1 mt-1 subscription pl-0 pr-2">
                                    Instant ChipIn+ Activation. Charge users a monthly subscription now
                                    </p>
                                </b-col>
                            </b-row>
                            <b-row class="mt-3 pl-3 pr-4">
                                <b-col class="included-info" cols="2">
                                    <img src="../../../../assets/tick.svg" alt="" />
                                </b-col>
                                <b-col class="pl-0 pr-2">
                                    <p class="font-weight-light mb-1 mt-1 subscription">
                                    Retain 85% of your monthly subscription revenue!
                                    </p>
                                </b-col>
                            </b-row>
                            <b-row class="mt-3 mb-4 px-3">
                                <b-col class="included-info" cols="2">
                                    <img src="../../../../assets/tick.svg" alt="" />
                                </b-col>
                                <b-col class="pl-0 pr-5">
                                    <p class="font-weight-light mb-1 mt-1 subscription pl-0 ">
                                    BONUS! Full Access to iOS in-app<br/>  8-Track Studio Creator Tools
                                    </p>
                                </b-col>
                            </b-row>
                            <b-row class="mt-3 mb-4 px-3">
                                <b-col class="included-info" cols="2">
                                    <img src="../../../../assets/tick.svg" alt="" />
                                </b-col>
                                <b-col class="px-0 pr-4">
                                    <p class="font-weight-light mb-1 mt-1 subscription">
                                    Percentage of Subscription goes<br/> to our Charitable Artist Fund
                                    </p>
                                </b-col>
                            </b-row>
                            <b-row class="mt-3 mb-4 px-3">
                            <b-col class="included-info" cols="2">
                                <img src="../../../../assets/tick.svg" alt="" />
                            </b-col>
                            <b-col class="px-0">
                                <p class="font-weight-light mb-1 mt-1 subscription px-0">
                                Your profile will display Supporter Badge
                                </p>
                            </b-col>
                            <b-col cols="12" class="text-center">
                                <b-button
                                    @click="Purchase()"
                                    class="px-4 py-2 my-2 rounded-btn"
                                    size="md"
                                    variant="info"
                                >
                                    Purchase
                                </b-button>
                            </b-col>
                            </b-row>
                        </b-col>

                        
                        
                    </b-row>
                </div>
            </div>

            
          
        </b-col>
      </b-row>
    </b-container>

    </b-modal>
  </div>
</template>

<script src="https://js.stripe.com/v3/"></script>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  
  name: "ChipInCreatorBackstagePass",
  data() {
    return {
        stripe: self.stripe = Stripe(process.env.VUE_APP_STRIPE_TCLLC_KEY), 
    };
  },
  components: {

  },
  methods: {
      ...mapActions("Upgrade", ["subscribe"]),
      Purchase() {
        
        var self = this;

        this.subscribe({price_id: process.env.VUE_APP_STRIPE_UPGRADE_PRICE_ID})
        .then(res => {

            return self.stripe.redirectToCheckout({ 
                sessionId: res.checkout_session_id,
            })

        })
        .catch(error => {
          error
            this.name = 'Sorry something went wrong!';
            
        });

    }
    
  },
  computed: {
    ...mapGetters(['user_image'])
    
  },
  mounted: function () {
    
  },
};
</script>

<style scoped>
#chipin-form {
  width: 100%;
  margin-top: 10px;
}

.modal-content {
  width: auto !important;
  margin: auto;
}

#chipin-form > h1 {
  font-weight: 900 !important;
  font-size: 36px;
  line-height: 21px;
  letter-spacing: 0.152972px;
}

#chipin-form > p {
  font-weight: 300 !important;
  font-size: 12px;
  line-height: 21px;
  letter-spacing: 0.152972px;
}

#chipin-form > h4 {
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.152972px;
}

.light-grey-border {
  border: 2px solid #9b9b9b;
}

.red-text {
  color: #ff0000;
}

.dark-text {
  color: #595959;
}

.super-small {
  font-size: 0.7em;
  color: #595959;
}

.rounded-btn {
  border-radius: 10px;
}

.line-height {
  line-height: 1.45em;
}

.included-info {
  display: inline-flex;
  align-items: center;
  float: left;
}

.song-profile-des > p {
  font-size: 34px;
  line-height: 36px;
}

.song-profile-des > h5 {
  font-weight: 500 !important;
  font-size: 34px;
  line-height: 36px;
}

.song-profile-des > span {
  font-weight: 300 !important;
  font-size: 18px;
  line-height: 24px;
  display: block;
  margin-top: 20px;
}

.months-btn {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 10px;
}

.months-btn > button {
  border-radius: 7px;
  font-family: "SFPro";
}

.month-one {
  border-top-right-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
  background: white;
  color: black;
  border: 0.5px solid rgba(0, 0, 0, 0.04);
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04);
  width: 50%;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
  margin-right: -5px;
  z-index: 2;
}

.month-twelve {
  background: black;
  color: white;
  background: rgba(118, 118, 128, 0.12);
  border: 1px solid #ffffff;
  border-left: none;
  width: 50%;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
}

.subscription {
  font-weight: 300 !important;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.152972px;
}

.select-btn {
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
  width: 236px;
  height: 40px;
}

.show-btn {
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
  width: 236px;
  height: 40px;
  border: 1px solid #00AFDC;
  background-color: transparent;
}

.choose-text {
    font-size: 18px;
    font-weight: 700;
}

.btn-lg {
    width: 374px;
    height: 40px;
    font-size: 18px;
    line-height: 10px;
    font-weight: 500;
}

.btn-lg-outline {
    width: 374px;
    height: 40px;
    font-size: 18px;
    line-height: 10px;
    font-weight: 500;
    background-color: transparent;
    border: 2px solid #00AFDC;
}

.desc {
    font-size: 14px;
    font-weight: 400;
}

.or {
    font-size: 18px;
    font-weight: 700;
}

.complete-form {
    font-size: 18px;
    font-weight: 500;
}

.statement-text {
    font-size: 10px;
    font-weight: 300;
}

.btn-lg {
    width: 236px;
    height: 40px;
    font-size: 18px;
    line-height: 10px;
    font-weight: 500;
}

.form-label {
    font-size: 18px;
    font-weight: 300;
}

.heading-text {
  font-size: 31px;
}

.backstage {
    font-weight: 900;
}

.jump-the-line {
    font-size: 24px;
    font-weight: 200;
    line-height: 20px;
}

.activate {
    font-size: 19px;
    font-weight: 300;
}

.special-offer {
    font-weight: 300;
}

.price {
    font-size: 61px;
    font-weight: 900;
}

.cancel {
    font-size: 14px;
    font-weight: 500;
}

.text-yellow {
    color: #F2B471;
}

.gradient {
    position: absolute;
    width: 100%;
    height: 270px;
    background-image: linear-gradient(rgba(0,0,0,1) 40%, rgba(0,0,0,0))
}

</style>
