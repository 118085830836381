

<template>
  <div class="w-100">
    <b-navbar
      v-if="!isAuthenticated() || $route.name === 'Home' || $route.name === 'Legal' || $route.name === 'ForArtists'"
      toggleable="lg"
      fixed="top"
      type="dark"
      variant="dark"
      class="fixed-top py-0"
      v-bind:class="{
        nav_class: true,
        'nav-forartists': $route.name === 'ForArtists',
        'c-topbar--collapsed': topBarIsCollapsed || $route.name === 'ForArtists',
      }"
    >
      <b-container
        fluid
        class="pt-2"
        :class="{
          'nav-small': this.$route.name === 'ForArtists',
          'nav-container': isAuthenticated(),
          'nav-unauth': !isAuthenticated() && this.$route.name !== 'ForArtists',
          'topbar-content-collapsed': topBarIsCollapsed,
        }"
      >
        <b-col cols="2" md="1">
          <b-button
            v-if="$route.name === 'Home'"
            v-show="!topBarIsCollapsed || !use_collapsable_header"
            variant="link"
            class="inline-block mt-0 menu-icon"
            :to="{
              name: 'LibraryArea',
              params: { area: 'discover', name: 'Home' },
            }"
          >
            <img
              src="../assets/menu-dots-icon.svg"
              class="float-left menu-icon"
              alt="Logo"
            />
          </b-button>
          <b-button
            v-else-if="$route.name !== 'Legal'"
            variant="link"
            class="inline-block mt-0 menu-icon"
            :to="{
              name: 'Home',
            }"
          >
            <img
              src="../assets/menu-dots-icon.svg"
              class="float-left menu-icon"
              alt="Logo"
            />
          </b-button>
        </b-col>

        <!-- Required for equal spacing -->
        <b-col cols="2" md="4" lg="3" class="px-xl-1">
          <b-button
            v-if="!topBarIsCollapsed"
            variant="link"
            class="app-store-icon float-left px-0"
            @click="app_store_click"
          >
            <img
              src="../assets/app-store-dark.svg"
              class="app-store-icon"
              alt="Logo"
            />
          </b-button>

          <b-button
            v-if="
              !isAuthenticated() &&
                (!topBarIsCollapsed || !use_collapsable_header)
            "
            :to="{ name: 'ForArtists' }"
            content-class="bg-dark"
            variant="outline-light"
            class="mt-2 d-none d-md-inline-block mx-auto for-artists-btn"
            >Trackd for Artists</b-button
          >


          <b-button
            v-if="
              isAuthenticated() &&
                (!topBarIsCollapsed || !use_collapsable_header)
            "
            :to="{ name: 'Dashboard' }"
            content-class="bg-dark"
            variant="outline-light"
            class=" d-none d-md-inline-block mt-2 mx-auto for-artists-btn"
            >Trackd for Artists</b-button
          >

          <b-button
            variant="link"
            v-b-modal.login-modal
            class="mt-3 for-artists-icon float-left pl-0"
          >
            <img
              src="../assets/for-artists-mobile.svg"
              class="for-artists-icon"
              height="20"
              alt="Logo"
            />
          </b-button>
        </b-col>

        <b-col cols="4" md="2" lg="4" class="pl-0 pr-0 text-center">
          <b-button
            v-if="!topBarIsCollapsed || !use_collapsable_header"
            variant="link"
            :to="{ name: 'Home' }"
          >
            <img
              src="../assets/trackd-social-music-platform.svg"
              class="mx-auto logo trackd-logo text-center"
              alt="Logo"
            />
          </b-button>
          <b-row v-else>
            <b-col cols="12">
              <router-link :to="{ name: 'Home' }">
                <img
                  src="../assets/trackd-icon-white.svg"
                  class="mx-auto white-logo text-center"
                  alt="Logo"
                />
              </router-link>
            </b-col>
            <b-col>
              <img
                @click="toggleExpand()"
                src="../assets/arrow-down.svg"
                class="mx-auto text-center"
                alt="Logo"
                height="10"
              />
            </b-col>
          </b-row>
        </b-col>

        <!-- <b-col cols="12" class="d-block d-sm-none">
            <img
              src="../../assets/trackd-icon-white.svg"
              class="mb-4 mx-auto text-center mt-2"
              height="34"
              alt="Logo"
            />
          </b-col> -->

        <b-col cols="0" md="2" class="d-none d-md-inline-block px-0">
          <b-button
            v-if="!isAuthenticated()"
            v-b-modal.login-modal
            content-class="bg-dark text-light"
            variant="outline-light"
            class="
                d-none d-md-inline-block
                mt-2
                mt-md-3
                mt-lg-0
                mx-auto
                create-account-button
                start-button
              "
            >Start</b-button
          >

          <b-button
            v-else-if="!topBarIsCollapsed || !use_collapsable_header"
            @click="logout_"
            content-class="bg-dark text-light"
            variant="outline-light"
            class="
                d-none d-md-inline-block
                mt-md-3
                mt-lg-0
                mt-lg-1
                mx-auto
                create-account-button
                start-button
              "
            >Logout</b-button
          >
        </b-col>

        <!-- <b-col cols="1"></b-col> -->

        <b-col cols="4" md="3" lg="2" class="align-self-center">
          <b-button
            v-if="$route.name === 'Home' || $route.name === 'Library'"
            v-b-modal.filter-modal
            variant="link"
            class="filter-icon p-0"
          >
            <img
              src="../assets/filter-icon.svg"
              class="filter-icon"
              height="21"
              alt="Filter"
            />
          </b-button>

          <b-button
            v-if="$route.name === 'Home' || $route.name === 'Library'"
            v-b-modal.filter-modal
            variant="link"
            class="filter-icon-mobile p-0"
          >
            <img
              src="../assets/filter-icon-mobile.svg"
              class="filter-icon-mobile"
              alt="Filter"
            />
          </b-button>

          <b-button
            v-b-toggle.menu-sidebar
            variant="link"
            class="mt-0 float-right hamburger p-0"
          >
            <img
              src="../assets/hamburger-alt.svg"
              class="hamburger"
              alt="Menu"
            />
              <b-badge
                  v-if="unreadNotificationsCount > 0"
                  class="font-weight-normal text-danger"
                  style="position: relative;  height: 13px; width: 13px; right: -17px; top: -35px;"
                  round="circle"
                  variant="danger"
                  pill
                >1</b-badge>
          </b-button>
        </b-col>
      </b-container>
    </b-navbar>
    <b-navbar
      v-else-if="!dont_show_header"
      toggleable="lg"
      fixed="top"
      type="transparent"
      variant="transparent"
      class="py-2"
      v-bind:class="{
        'bg-dark-important': topBarIsCollapsed,
        'fade-in-long': topBarIsCollapsed,
      }"
    >
      <b-container fluid class="px-0">
        <b-row class="w-100 px-0 mx-0 mx-md-3">
          <b-col cols="4" class="text-left px-0">
            <b-button
              variant="link"
              class="c-btn-back-icon px-0"
              @click="$router.go(-1)"
              ><img
                class="align-top nav-bar-button-icon c-back-icon"
                src="../assets/back-icon.svg"
                @click="$router.go(-1)"
                alt="placeholder"
              />
            </b-button>
          </b-col>
          <b-col cols="4" class="text-center px-0">
            <b-button
              variant="link"
              class=""
              v-bind:class="{ 'fade-in-long': topBarIsCollapsed }"
              to="/"
              ><img
                class="align-top nav-bar-button-icon d-none"
                src="../assets/trackd-icon-colours.svg"
                alt="placeholder"
                v-bind:class="{ 'show-icon': topBarIsCollapsed }"
              />
            </b-button>
          </b-col>
          <b-col cols="4" class="text-right px-0">
            <b-button
              v-b-toggle.menu-sidebar
              variant="link"
              class="float-right px-0"
            >
              <img
                src="../assets/library-hamburguer-menu.svg"
                class="align-top nav-bar-button-icon c-vertical-hamburger"
                height="30"
                alt="Logo"
              />
              <b-badge
                  class="font-weight-normal text-danger"
                  style="position: relative;  height: 13px; width: 13px; left: -14px; top: -3px;"
                  round="circle"
                  variant="danger"
                  pill
                  v-if="unreadNotificationsCount > 0"
                >1</b-badge>
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-navbar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Header",
  data() {
    return {
      topBarIsCollapsed: false,
    };
  },
  mounted() {
    

    let that = this;

    window.addEventListener("scroll", function() {
      let scroll = this.scrollY;

      if (scroll > 0) {
        that.topBarIsCollapsed = true;
        return;
      }

      that.topBarIsCollapsed = false;
    });
  },
  computed: {
    ...mapGetters("Player", {
      initialPlay: "isInitialPlay",
    }),
    ...mapGetters(['unreadNotificationsCount']),

    hasHistory() {
      return window.history.length > 2;
    },
    nav_class() {
      /*if (this.$route.name === 'LibraryArea') {
        return 'trackd-red-bg'
      }*/
      return "bg-dark";
    },
    dark_nav() {
      return false;
      /*
      if (
        this.$route.name === 'Home' || this.$route.name === 'Legal' || this.$route.name === 'Upload' ||
        this.$route.name === 'EditSongProfile' || this.$route.name === 'EditProfile' || this.$route.name === 'Upload'
        ) {
          return false
      } else {
        return true
      }
      */
    },
    display_signup: function() {
      if (this.$route.name) {
        if (this.$route.name.includes("Onboarding")) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    display_trident: function() {
      if (this.$route.name) {
        if (this.$route.name === "TridentRecord") {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    dont_show_header() {
      if (
        this.$route.name === "Playlist" ||
        this.$route.name === "Song" ||
        this.$route.name === "Artist" ||
        this.$route.name === "ArtistV3" ||
        this.$route.name === "ArtistUsername" ||
        this.$route.name === "Song" ||
        this.$route.name === "SongProfileID" ||
        this.$route.name === "SongProfile" ||
        this.$route.name === "ArtistProfileList" ||
        this.$route.name === "SongProfileList" ||
        this.$route.name === "DiscoverOverlay" ||
        this.$route.name === "CreateOverlay" ||
        this.$route.name === "Lyrics"
      ) {
        return true;
      } else {
        return false;
      }
    },
    display_header: function() {
      var route = this.$route.name;

      // Settings
      if (
        this.$route.name === "AccountSettings" ||
        this.$route.name === "SetupChipIn" ||
        this.$route.name === "EditProfile" ||
        this.$route.name === "EditSongProfile" ||
        this.$route.name === "SignInSettings" ||
        this.$route.name === "EditAccountSettings" ||
        this.$route.name === "ChipInAccountType" ||
        this.$route.name === "ChipInPlusSettings" ||
        this.$route.name === "ChipInPlusSongSettings" ||
        this.$route.name === "ChangePassword" ||
        this.$route.name === "ChipInTransactions" ||
        this.$route.name === "ChipInSubscriptions" ||
        this.$route.name === "ChipInPayouts" ||
        this.$route.name === "ChipInAddCard" ||
        this.$route.name === "ChipInCards" ||
        this.$route.name === "ChipInAddBankAccount" ||
        this.$route.name === "ChipInBankAccounts" ||
        this.$route.name === "SongSettings" ||
        this.$route.name === "DonationsMade" ||
        this.$route.name === "SubscriptionsMade" ||
        this.$route.name === "Upload" ||
        this.$route.name === "Credits" ||
        this.$route.name === "NotificationsSettings" ||
        this.$route.name === "EmailSettings" ||
        this.$route.name === "PrivacySettings" ||
        this.$route.name === "ChipInAccount" ||
        this.$route.name === "EditSongProfileGenres" ||
        this.$route.name === "LibrarySectionList" ||
        this.$route.name === "EditUserProfileSkills" ||
        this.$route.name === "SongProfileList" ||
        this.$route.name === "ArtistProfileList" ||
        this.$route.name === "Playlist" ||
        this.$route.name === "Song" ||
        this.$route.name === "Artist" ||
        this.$route.name === "ArtistV3" ||
        this.$route.name === "ArtistUsername" ||
        this.$route.name === "Song" ||
        this.$route.name === "SongProfileID" ||
        this.$route.name === "SongProfile" ||
        this.$route.name === "ArtistProfileList" ||
        this.$route.name === "SongProfileList" ||
        this.$route.name === "Songs" ||
        this.$route.name === "Dashboard" ||
        this.$route.name === "Playlist" ||
        (this.$route.name === "Pricing" && this.isAuthenticated()) ||
        (this.$route.name === "AppPage" && this.isAuthenticated()) ||
        (this.$route.name === "AboutUs" && this.isAuthenticated())
      ) {
        return false;
      }

      let showForLibrary =
        this.$route.path == "/library/discover/Library" ||
        this.$route.path == "/library/create/Library";

      // Hide if route is MyCollection unless visiting when unauthenticated
      var hideForMyCollection =
        route === "MyCollection" &&
        !(route === "MyCollection" && !this.isAuthenticated);

      if (route === "Landing") {
        return false;
      }
      if (hideForMyCollection || showForLibrary) {
        return false;
      } else {
        return true;
      }
    },
    use_collapsable_header() {
      if (window.matchMedia("(min-width: 992px)").matches) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapGetters(["isAuthenticated"]),
    ...mapActions({ main_logout: "logout" }),

    toggleExpand() {
      this.topBarIsCollapsed = !this.topBarIsCollapsed;
    },

    redirectToAltHome() {
      
      if (this.$route.name === "Home") {
        this.$router.replace({
          name: "LibraryArea",
          params: { area: "discover", name: "Library" },
        });
      } else {
        this.$router.replace({ name: "Home" });
      }
    },

    logout_() {
      this.main_logout();
      if (this.$route.name !== "Home") {
        this.$router.replace("/");
      }
    },

    app_store_click() {
      window.open(
        "https://apps.apple.com/gb/app/trackd-songs-start-here/id1496465739",
        "_blank"
      );
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.c-topbar--collapsed .nav-container,
.c-topbar--collapsed {
  height: 75px;
}


.c-vertical-hamburger,
.c-back-icon {
  opacity: 0.8;
}

.nav-container {
  /* background-color: red; */
  height: 100px;
  transition: all 0.5s ease-in-out;
}

.bg-dark-important {
  background: rgba(1, 1, 1, 0.75) !important;
}

.nav-unauth {
  /* background-color: blue; */
  opacity: 5;
  height: 120px;
  transition: all 0.5s ease-in-out;
}

.nav-small {
  height: 80px !important;
  transition: all 0.5s ease-in-out;
}

.nav-small.nav-unauth,
.nav-forartists {
  height: 80px; 
  transition: all 0.5s ease-in-out;
}

.fade-in {
  transition: all 1s ease-in-out;
}

.fade-in-long {
  transition: all 1s ease-in-out;
}

.show-icon {
  display: block !important;
  transition: all 1s ease-in-out;
}

.create-account-button {
  width: 144px;
  height: 30px;
  font-size: 14px;
  border-radius: 10px;
}

.menu-icon {
  height: 22px;
  transform: translate(-27%, -32%);
  float: left;
}

.app-store-icon {
  display: none;
}

.start-button {
  transform: translate(30%, -40%);
  width: 120px;
}

.filter-icon {
  transform: translateX(60%);
  display: none;
}

.filter-icon-mobile {
  transform: translate(42%, -20%);
  height: 24px;
}

.nav-bar-button-icon {
  height: 40px;
  width: 40px;
}

.white-logo {
    height: 27px;
    /* transform: translateY(-20%); */
  }

@media (max-width: 575.98px) {
  .trackd-logo {
    height: 40px;
    transform: translate(-12.5%, -8%);
  }

  .for-artists {
    display: none;
  }

  .for-artists-icon {
    display: inline-block;
    transform: translate(-22%, -29%);
  }

  .app-store-icon {
    display: none;
  }

  .hamburger {
    transform: translate(20%, -25%);
    height: 20px;
    width: 25px;
  }
}

@media (min-width: 576px) {
  .for-artists {
    display: none;
  }

  .for-artists-icon {
    display: inline-block;
    transform: translate(7%, -29%);
  }

  .trackd-logo {
    height: 40px;
    transform: translate(2.2%, -7.5%);
  }

  .filter-icon {
    transform: translate(112%, -47%);
    height: 16.2px;
    display: inline-block;
  }

  .filter-icon-mobile {
    display: none;
  }

  .hamburger {
    transform: translate(20%, -25%);
    height: 20px;
    width: 30px;
  }

  .menu-icon {
    height: 22px;
    transform: translate(-27%, -32%);
  }

  .nav-small {
    height: 120px;
    transition: all 0.5s ease-in-out;
  }
}

@media (min-width: 768px) {
  .nav-container {
    /* background-color: red; */
    height: 110px;
    transition: all 0.5s ease-in-out;
  }

  .menu-icon {
    height: 20px;
    transform: translate(9%, -8%);
  }

  .for-artists {
    display: inline-block;
    font-size: 14px;
    line-height: 22px;
    font-weight: 200;
    transform: translate(60%, 15%);
  }

  .for-artists-icon {
    display: none;
  }

  .create-account-button {
    height: 30px;
    font-size: 14px;
    text-align: center;
    padding: 0;
    border-radius: 10px;
  }

  .filter-icon {
    transform: translate(178%, 10%);
    height: 21px;
    display: inline-block;
  }

  .filter-icon-mobile {
    display: none;
  }

  .hamburger {
    transform: translate(25%, 15%);
    height: 20px;
    width: 20px;
  }

  .start-button {
    width: 120px;
    height: 30px;
    font-size: 14px;
    font-weight: 200;
    border-radius: 8px;
    line-height: 22px;
    transform: translate(43%, -25%);
  }

  .for-artists-btn {
    width: 140px;
    height: 30px;
    font-size: 14px;
    font-weight: 200;
    border-radius: 8px;
    line-height: 16px;
    transform: translate(20%, -10%);
    
  }

  

  .trackd-logo {
    height: 40px;
    transform: translate(-9%, 10%);
  }
}

@media (min-width: 992px) {
  .nav-container {
    /* background-color: red; */
    height: 95px;
    transition: all 0.5s ease-in-out;
  }

  .nav-container:hover {
    /* background-color: red; */
    height: 120px;
    transition: all 0.5s ease-in-out;
  }

  .filter-icon {
    height: 22px;
    transform: translate(35%, 5%);
  }

  .hamburger {
    transform: translate(25%, 9%);
    height: 20px;
    width: 20px;
  }

  .start-button {
    width: 120px;
    height: 30px;
    font-size: 14px;
    font-weight: 200;
    border-radius: 10px;
    line-height: 22px;
    transform: translate(-10%, -5%);
  }

   .for-artists-btn {
    width: 140px;
    height: 30px;
    font-size: 14px;
    font-weight: 200;
    border-radius: 10px;
    line-height: 16px;
    transform: translate(50%, -15%);
  }

  .trackd-logo {
    transform: translate(2.5%, 2%);
  }

  .menu-icon {
    height: 20px;
    transform: translate(-15%, -15%);
  }
}

@media (min-width: 1200px) {
  .nav-container {
    /* background-color: red; */
    height: 95px;
    transition: all 0.5s ease-in-out;
  }

  .nav-container:hover {
    /* background-color: blue; */
    opacity: 5;
    height: 120px;
    transition: all 0.5s ease-in-out;
  }

  .trackd-logo {
    height: 54px;
    transform: translate(1.8%, -2%);
  }

  .menu-icon {
    height: 30px;
    transform: translate(-11%, -22%);
  }

  .app-store-icon {
    display: inline-block;
    height: 35px;
    transform: translate(-1%, -10%);
  }

  .for-artists {
    float: right;
    font-size: 15px;
    font-weight: 200;
    transform: translate(39%, -32%);
  }

  .start-button {
    width: 144px;
    height: 35px;
    font-size: 15px;
    font-weight: 200;
    border-radius: 10px;
    line-height: 22px;
    transform: translate(5.5%, -20%);
  }

  .for-artists-btn {
    width: 150px;
    height: 35px;
    font-size: 14px;
    font-weight: 200;
    border-radius: 10px;
    line-height: 18px;
    transform: translate(50%, -25%);
  }

  .filter-icon {
    height: 22px;
    transform: translate(120%, -15%);
  }

  .hamburger {
    transform: translate(0%, -8%);
    height: 27px;
    width: 27px;
  }

  .white-logo {
    height: 27px;
  }
}

@media (max-width: 576px) {
  .nav-bar-button-icon {
    width: 30px;
    height: 30px;
  }

  .topbar-content-collapsed {
    transition: all 0.3s ease-in-out;
    /* transform: translateY(-22px); */
  }
}

@media (max-width: 320px) {
  .nav-bar-button-icon {
    width: 25px;
    height: 25px;
  }
}


@media (min-width: 1400px) {
  .for-artists-btn {
    width: 143px;
    height: 35px;
    font-size: 14px;
    font-weight: 200;
    border-radius: 10px;
    line-height: 18px;
    transform: translate(70%, -25%);
  }
}

</style>

<style lang="scss">
@import "~bootstrap/scss/bootstrap.scss";
@import "~bootstrap-vue/src/index.scss";

$blue: rgb(62, 142, 222) !default;
</style>

