<template>
  <div
    id="app"
    class="bg-dark"
    v-bind:class="{
      'nav-enabled': dark_nav,
      'player-margin': !isMobile() && this.$route.name !== 'Legal',
      'mobile-header-margin': mobile_header_margin,
      'banner-margin': banner_margin,
    }"
  >
    <div
      v-if="
        this.$route.name !== 'ArtistUsername' &&
          this.$route.name !== 'SongProfile'
      "
    >
      <Header v-if="!isMobile()"></Header>
      <MobileHeader v-else v-bind:username="username"></MobileHeader>
    </div>

    <router-view :key="$route.fullPath"></router-view>

    <mainplayer></mainplayer>

    <!-- Sidebar -->
    <HamburgerMenu></HamburgerMenu>

    <!-- Modals -->
    <LoginModal></LoginModal>
    <ForgotPasswordModal></ForgotPasswordModal>
    <LoginEmailModal></LoginEmailModal>
    <SignUpModal></SignUpModal>
    <FilterModal></FilterModal>
    <OnboardingEmailModal></OnboardingEmailModal>
    <OnboardingAccountModal></OnboardingAccountModal>
    <OnboardingGenresModal></OnboardingGenresModal>
    <OnboardingSkillsModal></OnboardingSkillsModal>
    <OnboardingLocationModal></OnboardingLocationModal>
    <OnboardingGenderModal></OnboardingGenderModal>
    <OnboardingSSOUsername></OnboardingSSOUsername>
    <OnboardingUserTypeModal></OnboardingUserTypeModal>
    <ChipInCreatorModal></ChipInCreatorModal>
    <ChipInCreatorGetStartedModal></ChipInCreatorGetStartedModal>
    <ChipInCreatorArtistsManagersModal></ChipInCreatorArtistsManagersModal>
    <ChipInCreatorReferModal></ChipInCreatorReferModal>
    <ChipInCreatorThanksModal></ChipInCreatorThanksModal>
    <ChipInCreatorBackstagePassModal></ChipInCreatorBackstagePassModal>

    <cookie-law v-if="show_cookie" class="bg-dark" theme="blood-orange" style="z-index: 1000000;">
      <div slot="message" class="font-weight-light">
        This website uses cookies to ensure you get the best experience.
        <router-link to="/legal">Click here</router-link>
      </div>
    </cookie-law>

    <div
      v-if="loading"
      class="modal modal-spinner fade bd-example-modal-lg show"
      data-backdrop="static"
      data-keyboard="false"
      tabindex="-1"
    >
      <div class="modal-dialog modal-sm">
        <div class="modal-content text-center">
          <img
            alt="Trackd Music"
            src="./assets/trackd-loading.gif"
            height="60"
            style="z-index: 4000; opacity: 1"
            class="d-block mx-auto loading-icon"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script
  crossorigin="anonymous"
  src="https://connect.facebook.net/en_US/sdk.js"
></script>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

import Header from "./components/Header.vue";
import MobileHeader from "./components/MobileHeader.vue";
import FacebookButton from "./components/auth/facebook.vue";
import GoogleButton from "./components/auth/google.vue";
import AppleButton from "./components/auth/apple.vue";
import LoginModal from "./components/modal/Login.vue";
import ForgotPasswordModal from "./components/modal/ForgotPassword.vue";
import LoginEmailModal from "./components/modal/LoginEmail.vue";
import SignUpModal from "./components/modal/SignUp.vue";
import OnboardingEmailModal from "./components/modal/OnboardingEmail.vue";
import OnboardingAccountModal from "./components/modal/OnboardingAccount.vue";
import OnboardingGenresModal from "./components/modal/OnboardingGenres.vue";
import OnboardingSkillsModal from "./components/modal/OnboardingSkills.vue";
import OnboardingLocationModal from "./components/modal/OnboardingLocation.vue";
import OnboardingGenderModal from "./components/modal/OnboardingGender.vue";
import OnboardingSSOUsername from "./components/modal/OnboardingSSOUsername.vue";
import OnboardingUserTypeModal from "./components/modal/OnboardingUserType.vue";
import FilterModal from "./components/modal/Filter.vue";
import ChipInCreatorModal from "./components/modal/chipin/ChipinCreator.vue";
import ChipInCreatorGetStartedModal from "./components/modal/chipin/screens/GetStarted.vue";
import ChipInCreatorArtistsManagersModal from "./components/modal/chipin/screens/ArtistsManagers.vue";
import ChipInCreatorReferModal from "./components/modal/chipin/screens/Refer.vue";
import ChipInCreatorThanksModal from "./components/modal/chipin/screens/Thanks.vue";
import ChipInCreatorBackstagePassModal from "./components/modal/chipin/screens/BackstagePass.vue";
import HamburgerMenu from "./components/Hamburger/Menu.vue";
import Mainplayer from "./components/mainplayer.vue";
import CookieLaw from "vue-cookie-law";
import { eventPlayerDisplay } from "./services/PlayerDisplayEventBus";

export default {
  name: "App",
  metaInfo() {
    return {
      title: "Trackd Music : Social Music Platform",
      meta: [
        {
          name: "description",
          content:
            "Trackd Music : Listen and Connect directly with the world’s finest Artists.",
        },
        {
          property: "og:title",
          content: "Trackd Music : Social Music Platform",
        },
        { property: "og:site_name", content: "Trackd Music" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
  components: {
    Header,
    MobileHeader,
    FacebookButton,
    GoogleButton,
    AppleButton,
    LoginModal,
    ForgotPasswordModal,
    LoginEmailModal,
    SignUpModal,
    OnboardingSSOUsername,
    OnboardingEmailModal,
    OnboardingAccountModal,
    OnboardingGenresModal,
    OnboardingSkillsModal,
    OnboardingLocationModal,
    OnboardingGenderModal,
    OnboardingUserTypeModal,
    Mainplayer,
    FilterModal,
    CookieLaw,
    ChipInCreatorModal,
    ChipInCreatorGetStartedModal,
    ChipInCreatorArtistsManagersModal,
    ChipInCreatorReferModal,
    ChipInCreatorThanksModal,
    ChipInCreatorBackstagePassModal,
    HamburgerMenu,
  },
  data() {
    return {
      displayPlayerStatus: "",
      show_cookie: true,
    };
  },
  methods: {
    ...mapGetters(["whatUserType"]),
    ...mapActions("Auth", ["reauth"]),
    ...mapActions("Library", ["search"]),
    ...mapMutations("Auth", ["setShowHomeOverlay"]),
    ...mapMutations(["switchUserType", "kochavaEvent"]),
    ...mapMutations(["kochavaPage"]),
    ...mapActions({ main_logout: "logout", portal: "portal" }),
    scrollTo: function(hashtag) {
      setTimeout(() => {
        location.href = hashtag;
      }, 1000);
    },
  },
  computed: {
    ...mapGetters("Generic", { loading: "loading" }),
    ...mapGetters([
      "isAuthenticated",
      "username",
      "user_image",
      "location",
      "isChipInUser",
      "isSupporter",
      "showBilling",
      "isWebUser",
    ]),
    ...mapGetters("Player", [
      "pauseSong",
      "setSong",
      "playSong",
      "setPlayAfterAuth",
      "shouldPlayAfterAuth",
      "getPlayAfterAuth",
    ]),

    dark_nav: function() {
      if (this.$route.name === "Home" || this.$route.name === "Legal") {
        return true;
      } else {
        return false;
      }
    },
    mobile_header_margin() {
      if (!this.isMobile()) return false;
      if (this.$route.name === "Home") {
        return true;
      } else {
        return false;
      }
    },
    switchHomeView() {
      if (this.$route.name === "Home" || this.$route.name == "LibraryArea") {
        return false;
      }

      return true;
    },
    banner_margin() {
      if (this.isMobile()) return false;
      if (
        this.$route.fullPath === "/library/discover/Home" &&
        !this.isAuthenticated
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    this.reauth();
  },
  updated() {
    if (this.shouldPlayAfterAuth && this.isAuthenticated) {
      this.pauseSong();
      this.setSong(this.getPlayAfterAuth);
      this.playSong();
      this.setPlayAfterAuth({ shouldPlay: false, songToPlay: null });
    }
  },
  mounted() {
    this.kochavaPage;
    
    // Check if router is ready to access variable
    this.$router.onReady(() => {
      // From testing, without a brief timeout, it won't work.
      if (this.$route.hash) {
        setTimeout(() => this.scrollTo(this.$route.hash), 1000);
      }
      
      if (this.$route.path.includes('onboarding') || this.$route.path.includes('subscriptions-made')  || this.$route.path.includes('auth')) {
        this.show_cookie = false
      } else if (this.isAuthenticated) {
        this.show_cookie = false
      } else {
        this.show_cookie = true
      }

      if (
        this.$route.query.utm_campaign &&
        this.$route.query.utm_source &&
        this.$route.query.utm_medium
      ) {
        this.$cookie.set("utm_campaign", this.$route.query.utm_campaign, {
          domain: window.location.hostname,
        });
        this.$cookie.set("utm_source", this.$route.query.utm_source, {
          domain: window.location.hostname,
        });
        this.$cookie.set("utm_medium", this.$route.query.utm_medium, {
          domain: window.location.hostname,
        });
      }

      if (this.$route.query.signup) {
        this.$bvModal.show("signup-modal");
      }

      if (this.$route.query.login) {
        this.$bvModal.show("login-modal");
      }
    });

    if (this.$store.state.Auth.showLoginModal) {
      this.setShowHomeOverlay(false);
      this.$bvModal.show("login-modal");
      this.$bvModal.hide("home-overlay-modal");
    }

    //console.log(this.shouldPlayAfterAuth)
    //console.log(this.getPlayAfterAuth)

    if (this.shouldPlayAfterAuth && this.isAuthenticated) {
      this.pauseSong();
      this.setSong(this.getPlayAfterAuth);
      this.playSong();
      this.setPlayAfterAuth({ shouldPlay: false, songToPlay: null });
    }

    let that = this;

    eventPlayerDisplay.$on(
      "onChangePlayerShowStatus",
      (displayPlayerStatus) => {
        that.displayPlayerStatus = displayPlayerStatus;
      }
    );
  },
};
</script>

<style lang="scss">
// Import custom SASS variable overrides, or alternatively
// define your variable overrides here instead
@import "assets/custom.scss";

// Import Bootstrap and BootstrapVue source SCSS files
@import "~bootstrap/scss/bootstrap.scss";
@import "~bootstrap-vue/src/index.scss";

// General style overrides and custom classes
</style>

<style>
@font-face {
  font-family: "SFUI";
  src: url(./assets/font/SF-UI-Text.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "SFPro";
  src: url(./assets/font/SF-Pro-Text.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url(./assets/font/Roboto/Roboto-Thin.ttf) format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url(./assets/font/Roboto/Roboto-ThinItalic.ttf) format("truetype");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url(./assets/font/Roboto/Roboto-Light.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url(./assets/font/Roboto/Roboto-LightItalic.ttf) format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url(./assets/font/Roboto/Roboto-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url(./assets/font/Roboto/Roboto-Italic.ttf) format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url(./assets/font/Roboto/Roboto-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url(./assets/font/Roboto/Roboto-MediumItalic.ttf) format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url(./assets/font/Roboto/Roboto-Bold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url(./assets/font/Roboto/Roboto-BoldItalic.ttf) format("truetype");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url(./assets/font/Roboto/Roboto-Black.ttf) format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url(./assets/font/Roboto/Roboto-BlackItalic.ttf) format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
html {
  position: relative;
  min-height: 100%;
}
#app {
  height: 100%;
}
.modal-open {
  overflow: auto;
  padding-right: 0 !important;
}
#menu-sidebar {
  width: 33%;
  min-width: 350px;
  max-width: 450px;
}

.banner-margin {
  margin-top: 120px;
}

.c-sidebar--player-hidden {
  padding-bottom: 0px !important;
}
.c-sidebar--player-collapsed {
  padding-bottom: 105px !important;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #495057;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(128, 134, 139, 0.25);
}

.custom-control-label::before {
  color: #fff;
  border-color: #e76e66;
  background-color: #e76e66;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #42b883;
  background-color: #42b883;
}
/*
  .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60px;
    line-height: 60px; 
  }
  */

.text-small {
  font-size: 0.9em;
}

/* Search */
.search-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.search-input,
.search-input:focus {
  /*background-color: #4a4a4a;
  border-color: #4a4a4a;*/
  background-color: #fff;
  color: #c1c0c9;
  padding-top: 0px;
  padding-bottom: 0px;
  font-size: 1.05rem;
  border: none;
  padding-left: 0px;
  padding-right: 0px;
}

.search-input.input-group-prepend,
.search-input.input-group-append {
  background-color: #fff;
  /*background-color: #4a4a4a;
  border-color: #4a4a4a;*/
  color: #c1c0c9;
}

.c-playlist-input-search #input-search {
  border-radius: 0px;
  background-color: #fff;
  background-color: #191919;
  color: #9b9b9b;
  font-size: 1.05em;
}

#input-search {
  border-radius: 0px;
  background-color: #fff;
  /*background-color: #4a4a4a;
  border-color: #4a4a4a; */
  color: #9b9b9b;
  font-size: 16px;
  font-weight: 300;
  line-height: 21px;
}

#input-search::placeholder {
  color: #9b9b9b;
}

.mobile-search-bar {
  font-size: 1.25em;
}

.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none;
}

.trackd-red {
  color: #e76e66;
}

.trackd-green {
  color: #81cd5c;
}

.trackd-orange {
  color: #f2b471;
}

.trackd-blue {
  color: #00afdc;
}

.trackd-light-grey {
  color: #9b9b9b;
}

.trackd-dark-grey {
  color: #4a4a4a;
}

.bg-none {
  background: transparent !important;
}

.padding {
  margin-bottom: 4em;
}

.modal-header {
  border-bottom: none !important;
}

* {
  font-family: "Roboto";
}

.nav-enabled {
  margin-top: 120px;
}

.trackd-green-bg {
  background-color: #64d148 !important;
}
.trackd-green-text {
  color: #64d148;
}
.trackd-blue-bg {
  background-color: #01afdc !important;
}
.trackd-blue-text {
  color: #01afdc !important;
}
.trackd-yellow-bg {
  background-color: #f2b471 !important;
}
.trackd-yellow-text {
  color: #f2b471 !important;
}
.trackd-red-bg {
  background-color: #e76d66 !important;
}
.trackd-red-text {
  color: #e76d66 !important;
}

.modal-content {
  border: 1px solid #fff;
  border-radius: 20px;
}

.overlay-bg {
  background-color: #333;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  opacity: 0.75;
  display: table;
}

.modal > img {
  z-index: 1000;
  position: relative;
}

.bd-example-modal-lg .modal-dialog {
  display: table;
  position: relative;
  margin: 0 auto;
  top: calc(50% - 24px);
}

.modal-spinner {
  display: block;
  background: #333;
  opacity: 0.65;
}

.bd-example-modal-lg .modal-dialog .modal-content {
  background-color: transparent;
  border: none;
}

.loading-icon {
  height: 50px;
}

.carousel-indicators li.active {
  background-color: #eee;
}
.pulse {
  animation: pulse 1s infinite;
  margin: 0 auto;
  display: table;
  margin-top: 50px;
  animation-direction: alternate;
  -webkit-animation-name: pulse;
  animation-name: pulse;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.25);
  }
  100% {
    -webkit-transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.25);
  }
  100% {
    -webkit-transform: scale(1);
  }
}

.footer-logo {
  border-radius: 50%;
  -webkit-transition: -webkit-transform 0.8s ease-in-out;
  transition: transform 0.8s ease-in-out;
}
.footer-logo:hover {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}

.b-sidebar > .b-sidebar-header {
  display: unset;
  justify-content: space-between;
}

@media (min-width: 576px) {
  .search-input svg {
    width: 25px;
    height: 22px;
  }

  #input-search {
    font-size: 20px;
  }
}

@media (min-width: 768px) {
  .search-input {
    height: 40px;
  }
}

@media (min-width: 1200px) {
  .btn-lg,
  .btn-group-lg > .btn {
    font-size: 18px;
    line-height: 30px;
    font-weight: 300;
  }

  #input-search {
    font-size: 18px;
  }
}

@media (max-width: 992px) {
  #menu-sidebar {
    width: 45%;
  }
}

@media (max-width: 576px) {
  #menu-sidebar {
    width: 85%;
    min-width: 85%;
    max-width: auto !important;
  }

  .hamburger-menu-link {
    font-size: 18px;
  }
}

@media (max-width: 575.98px) {
  .mobile-search-bar {
    background-color: #fff;
    border: none;
    font-size: 14px;
  }

  .mobile-search-bar:focus {
    font-size: 14px;
  }

  #menu-sidebar {
    width: 87%;
    min-width: 87%;
    max-width: auto !important;
  }

  .loading-icon {
    height: 35px;
  }

  .onboarding-modal .modal-lg {
    margin: 0;
    max-width: 100%;
    height: 100%;
    border: none;
  }

  .onboarding-modal .modal-content {
    height: 100%;
    border: none;
    border-radius: 0;
  }

  .onboarding-modal .modal-header {
    padding-bottom: 0;
  }

  .onboarding-modal .modal-body {
    background: black;
    padding-top: 0;
  }

  .hamburger-menu-link {
    font-size: 16px;
  }

  #input-search {
    font-size: 16px !important;
    height: 2.25rem;
  }

  .text-muted {
    font-size: 11px !important;
  }
}

@media (max-width: 375px) {
  .text-muted {
    font-size: 10px !important;
  }
}

@media (max-width: 320px) {
  #menu-sidebar {
    width: 100%;
  }

  .hamburger-app-store {
    width: 80px;
  }

  #input-search {
    font-size: 1em !important;
  }

  .text-muted {
    font-size: 10px !important;
  }
}

.sidebar-colour {
  border-right: 7px solid #e76e66;
  border-image: linear-gradient(
    to bottom,
    #f2b471 33.33%,
    #01afdc 33.33%,
    #01afdc 66.67%,
    #e76e66 66.67%
  );
  border-image-slice: 1;
  border-top: 0;
  border-bottom: 0;
  border-left: 0;

  /*border-image: linear-gradient(to bottom, #F2B471 33.33%, #01AFDC 33.33%,#01AFDC 66.67%, #E76E66 66.67%);*/
}

.player-margin {
  margin-bottom: 180px !important;
}

.mobile-header-margin {
  margin-top: 130px;
}

.mobile-player-margin {
  margin-bottom: 200px;
}

.sidebar-footer-links {
  font-size: 0.8em;
  line-height: 95%;
}

.modal-lg {
  max-width: 600px !important;
}

.input-box {
  box-shadow: none !important;
  border: none !important;
  border-radius: 0 !important;
  font-size: 15px;
  font-weight: 300;
  line-height: 22px;
  color: #f5a623 !important;
}

.next-btn {
  border-radius: 10px !important;
  text-transform: uppercase;
  font-size: 15px;
  line-height: 22px;
  font-weight: 700;
}
.tag-box {
  border-radius: 10px;
}

.text-title {
  font-size: 34px;
  font-weight: 500;
  line-height: 36px;
}

.text-des {
  font-size: 34px;
  font-weight: 200;
  line-height: 36px;
}

.text-des > b {
  font-weight: 500 !important;
}

.text-fdes {
  font-size: 18px;
  font-weight: 300 !important;
  line-height: 24px;
}

.text-fdes-sec {
  font-size: 18px;
  font-weight: 200 !important;
  line-height: 24px;
}

.text-fdes > b {
  font-weight: 500 !important;
}

.text-fdes-sec > b {
  font-weight: 500 !important;
}

.text-sub {
  font-size: 15px;
  font-weight: 300 !important;
  line-height: 22px;
}

.text-short {
  font-size: 12px;
  font-weight: 300 !important;
  line-height: 16px;
}

.menu-text {
  font-weight: 300 !important;
  font-size: 18px;
  line-height: 40px;
}

.text-muted {
  font-weight: 300 !important;
  font-size: 12px;
  line-height: 16px;
  /* display: flex;
    align-items: flex-end; */
  color: #9b9b9b !important;
}

.menu-support {
  font-size: 14px !important;
  line-height: 24px !important;
}

.danger-upgrade {
  color: #e76e66 !important;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  width: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #333333;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #cfcfcf;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a3a3a3;
}

@media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
  html {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
}
</style>
