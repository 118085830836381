<template>
  <div>
    <!-- MODAL -->

    <b-modal
      id="chipin-creator-getstarted-modal"
      ref="chipin-creator-getstarted-modal"
      size="lg"
      content-class="bg-dark text-light  "
      centered
      hide-footer
      header-close-variant="light"
    >
      <template #modal-header="{ close }">
        <b-container fluid>
          <b-row>
            <b-col class="float-left">
              <img
                src="../../../../assets/trackd-icon-colours.svg"
                class="mb-4 mx-auto text-left mt-2"
                height="25"
                alt="Logo"
              />
            </b-col>

            <b-col class="float-right">
              <b-button
                size="sm"
                variant="link"
                class="float-right"
                @click="close()"
              >
                <img
                  src="../../../../assets/section-close.svg"
                  class="mx-auto text-right float-right mt-2"
                  height="20"
                  alt="Close"
                />
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </template>

    <b-container fluid>
      <b-row>
        <b-col class="mb-3" cols="10" offset="1" sm="8" offset-sm="2">
          
          <b-row class="mb-3">
            <b-col class="p-1">
              <b-badge pill variant="info" class="w-100 text-info text-small"
                >&nbsp;a</b-badge
              >
            </b-col>
            <b-col class="p-1">
              <b-badge
                pill
                variant="warning"
                class="w-100 text-warning text-small"
                >&nbsp;b</b-badge
              >
            </b-col>
            <b-col class="p-1">
              <b-badge
                pill
                variant="secondary"
                class="w-100 text-secondary text-small"
                >&nbsp;c</b-badge
              >
            </b-col>
          </b-row>
        </b-col>

        <b-col cols="2"></b-col>
        
        <b-col cols="2">
          <b-button v-b-modal.chipin-creator-modal variant="link" @click="$bvModal.hide('chipin-creator-getstarted-modal')">
            <img src="../../../../assets/arrow-left.svg" />
          </b-button>
        </b-col>

        <b-col cols="7">

          <div class="row m-0 pb-3 p-0">
            <div style="width: 100%">
              <b-row>
                <b-col col class="song-profile-des">
                  <p class="mb-1 mt-2 font-weight-bold">Let's Do This!</p>
                  <p class="mb-1 mt-2 font-weight-lighter">Choose how to get started below</p>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <b-container fluid>
        <b-row class="pb-5">
          <b-col class="text-center py-5">
              <b-button
                v-b-modal.chipin-creator-backstage-pass-modal
                @click="$bvModal.hide('chipin-creator-getstarted-modal')"
                class="px-4 py-2 rounded-btn btn-lg"
                size="md"
                variant="info"
              >
                Jump The Line with a Backstage Pass
              </b-button>

              <p class="desc px-5 pt-3">Cant wait? Activate ChipIn+ now. Supporter Upgrade<br/> required - $9.95/month subscription. Cancel Anytime</p>

              <p class="or pb-2">or</p>
              
              <b-button
                v-b-modal.chipin-creator-refer-modal
                @click="$bvModal.hide('chipin-creator-getstarted-modal')"
                class="px-4 py-2 rounded-btn btn-lg-outline"
                size="md"
                variant="info"
              >
                Refer 3 Genuine Artists to Trackd  - Free
              </b-button>

              <p class="desc px-5 pt-3">Activate ChipIn+ now. Refer 3 genuine qualified Artists.<br/> Terms and Checks Apply.</p>

              <p class="or pb-2">or</p>

              <b-button
                v-b-modal.chipin-creator-artists-managers
                @click="$bvModal.hide('chipin-creator-getstarted-modal')"
                class="px-4 py-2 rounded-btn btn-lg-outline"
                size="md"
                variant="info"
              >
                Artists & Managers Apply Now - Free
              </b-button>

              <p class="desc px-5 pt-3">Minimum content criteria applies. Your application will be assesed and following you will be contacted by a representative within 48 hours.</p>


          </b-col>
          

      </b-row>
            
    </b-container>

    </b-modal>
  </div>
</template>


<script>
import { mapGetters } from 'vuex';


export default {
  
  name: "ChipInGetStarted",
  data() {
    return {
        chipin_slide: false,
        pages: [

        ]
    };
  },
  methods: {
    
  },
  computed: {
    ...mapGetters(['user_image'])
    
  },
  mounted: function () {
    
  },
};
</script>

<style scoped>
#chipin-form {
  width: 100%;
  margin-top: 10px;
}

.modal-content {
  width: auto !important;
  margin: auto;
}

#chipin-form > h1 {
  font-weight: 900 !important;
  font-size: 36px;
  line-height: 21px;
  letter-spacing: 0.152972px;
}

#chipin-form > p {
  font-weight: 300 !important;
  font-size: 12px;
  line-height: 21px;
  letter-spacing: 0.152972px;
}

#chipin-form > h4 {
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.152972px;
}

.light-grey-border {
  border: 2px solid #9b9b9b;
}

.red-text {
  color: #ff0000;
}

.dark-text {
  color: #595959;
}

.super-small {
  font-size: 0.7em;
  color: #595959;
}

.rounded-btn {
  border-radius: 10px;
}

.line-height {
  line-height: 1.45em;
}

.included-info {
  display: inline-flex;
  align-items: center;
  float: left;
}

.song-profile-des > p {
  font-size: 34px;
  line-height: 36px;
}

.song-profile-des > h5 {
  font-weight: 500 !important;
  font-size: 34px;
  line-height: 36px;
}

.song-profile-des > span {
  font-weight: 300 !important;
  font-size: 18px;
  line-height: 24px;
  display: block;
  margin-top: 20px;
}

.months-btn {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 10px;
}

.months-btn > button {
  border-radius: 7px;
  font-family: "SFPro";
}

.month-one {
  border-top-right-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
  background: white;
  color: black;
  border: 0.5px solid rgba(0, 0, 0, 0.04);
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04);
  width: 50%;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
  margin-right: -5px;
  z-index: 2;
}

.month-twelve {
  background: black;
  color: white;
  background: rgba(118, 118, 128, 0.12);
  border: 1px solid #ffffff;
  border-left: none;
  width: 50%;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
}

.subscription {
  font-weight: 300 !important;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.152972px;
}

.select-btn {
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
  width: 236px;
  height: 40px;
}

.show-btn {
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
  width: 236px;
  height: 40px;
  border: 1px solid #00AFDC;
  background-color: transparent;
}

.choose-text {
    font-size: 18px;
    font-weight: 700;
}

.btn-lg {
    width: 374px;
    height: 40px;
    font-size: 18px;
    line-height: 10px;
    font-weight: 500;
}

.btn-lg-outline {
    width: 374px;
    height: 40px;
    font-size: 18px;
    line-height: 10px;
    font-weight: 500;
    background-color: transparent;
    border: 2px solid #00AFDC;
}

.desc {
    font-size: 14px;
    font-weight: 400;
}

.or {
    font-size: 18px;
    font-weight: 700;
}

</style>
