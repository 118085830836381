var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-dark",class:{
    'nav-enabled': _vm.dark_nav,
    'player-margin': !_vm.isMobile() && this.$route.name !== 'Legal',
    'mobile-header-margin': _vm.mobile_header_margin,
    'banner-margin': _vm.banner_margin,
  },attrs:{"id":"app"}},[(
      this.$route.name !== 'ArtistUsername' &&
        this.$route.name !== 'SongProfile'
    )?_c('div',[(!_vm.isMobile())?_c('Header'):_c('MobileHeader',{attrs:{"username":_vm.username}})],1):_vm._e(),_c('router-view',{key:_vm.$route.fullPath}),_c('mainplayer'),_c('HamburgerMenu'),_c('LoginModal'),_c('ForgotPasswordModal'),_c('LoginEmailModal'),_c('SignUpModal'),_c('FilterModal'),_c('OnboardingEmailModal'),_c('OnboardingAccountModal'),_c('OnboardingGenresModal'),_c('OnboardingSkillsModal'),_c('OnboardingLocationModal'),_c('OnboardingGenderModal'),_c('OnboardingSSOUsername'),_c('OnboardingUserTypeModal'),_c('ChipInCreatorModal'),_c('ChipInCreatorGetStartedModal'),_c('ChipInCreatorArtistsManagersModal'),_c('ChipInCreatorReferModal'),_c('ChipInCreatorThanksModal'),_c('ChipInCreatorBackstagePassModal'),(_vm.show_cookie)?_c('cookie-law',{staticClass:"bg-dark",staticStyle:{"z-index":"1000000"},attrs:{"theme":"blood-orange"}},[_c('div',{staticClass:"font-weight-light",attrs:{"slot":"message"},slot:"message"},[_vm._v(" This website uses cookies to ensure you get the best experience. "),_c('router-link',{attrs:{"to":"/legal"}},[_vm._v("Click here")])],1)]):_vm._e(),(_vm.loading)?_c('div',{staticClass:"modal modal-spinner fade bd-example-modal-lg show",attrs:{"data-backdrop":"static","data-keyboard":"false","tabindex":"-1"}},[_vm._m(0)]):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-dialog modal-sm"},[_c('div',{staticClass:"modal-content text-center"},[_c('img',{staticClass:"d-block mx-auto loading-icon",staticStyle:{"z-index":"4000","opacity":"1"},attrs:{"alt":"Trackd Music","src":require("./assets/trackd-loading.gif"),"height":"60"}})])])}]

export { render, staticRenderFns }