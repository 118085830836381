import silentAxios from '../api/silent'
import Vue from "vue";
import Vuex from "vuex";
import createLogger from "vuex/dist/logger";

import { Kochava } from 'kochava';

// import the auto exporter
import modules from "./modules";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  state: {
    accessToken: null,
    refreshToken: null,
    userid: null,
    username: null,
    user: null,
    location: null,
    user_image: null,
    chipin_user: false,
    supporter: false,
    signup_source: null,
    user_type: null,
    login_type: null,
    chipin_plus: false,
    chipin_account_type: "not_enabled",
    chosen_user_type: "discover",
    stripe_account_type: "tcllc",
    account_type: "starter",
    previousRoute: null,
    nextUrl: null,
    showModal: false,
    came_from_signup: false,
    came_from_campaign: false,
    hide_close: false,
    kochava: null,
    unread_notification_count: 0,
    has_stripe_account: false,
    stripe_payment_enabled: false,
  },
  getters: {
    showModal(state) {
      return state.showModal;
    },
    hideClose(state) {
      return state.hide_close;
    },
    isAuthenticated(state) {
      if (state.accessToken) {
        return true;
      } else if (localStorage.getItem("accessToken")) {
        return true;
      }
      return false;
    },
    my_user_id(state) {
      return state.userid;
    },
    username(state) {
      return state.username;
    },
    get_username(state) {
      return state.username;
    },
    location(state) {
      return state.location;
    },
    user_image(state) {
      return state.user_image;
    },
    get_user_image(state) {
      return state.user_image;
    },
    isChipInUser(state) {
      return state.chipin_user;
    },
    isChipInPlus(state) {
      return state.chipin_plus;
    },
    ChipInType(state) {
      return state.chipin_account_type;
    },
    isSupporter(state) {
      return state.supporter;
    },
    showBilling(state) {
      if (state.signup_source === "web" && state.supporter) {
        return true;
      } else {
        return false;
      }
    },
    isWebUser(state) {
      if (state.signup_source === "web") {
        return true;
      } else {
        return false;
      }
    },
    whatAccountType(state) {
      if (state.account_type === "free") {
        return "starter";
      } else if (state.account_type === "supporter") {
        return "access all areas";
      }
      return state.account_type;
    },
    hasStripeAccount(state) {
      return state.has_stripe_account
    },
    whatUserType(state) {
      return state.chosen_user_type;
    },
    whatStripeAccount(state) {
      return state.stripe_account_type;
    },
    cameFromSignUp(state) {
      return state.came_from_signup;
    },
    cameFromCampaign(state) {
      return state.came_from_campaign;
    },
    getPreviousRoute(state) {
      return state.previousRoute;
    },
    getNextUrl(state) {
      return state.nextUrl;
    },
    unreadNotifications(state) {
      if (state.unread_notification_count > 0) {
        return true;
      }
      return false;
    },
    unreadNotificationsCount(state) {
      return state.unread_notification_count;
    },
  },
  actions: {
    logout({ commit }) {
      commit("clearAuthData", { root: true });
    },

    get_data({ commit }, endpoint) {
      return new Promise((resolve, reject) => {
        silentAxios
          .get(endpoint)
          .then(
            (res) => {
              commit;
              resolve(res);
            },
            (error) => {
              if (error.response) {
                reject(error.response);
              } else if (error.request) {
                reject(error.request);
              } else {
                reject(error);
              }
            }
          )
          .catch((error) => {
            reject(error);
          });
      });
    },

    get_paginated_data({ commit }, url) {
      return new Promise((resolve, reject) => {
        silentAxios
          .get(url)
          .then(
            (res) => {
              commit;

              resolve(res);
            },
            (error) => {
              if (error.response) {
                reject(error.response);
              } else if (error.request) {
                reject(error.request);
              } else {
                reject(error);
              }
            }
          )
          .catch((error) => {
            reject(error);
          });
      });
    },

    portal({ commit }) {
      return new Promise((resolve, reject) => {
        silentAxios
          .post("/billing/portal/")
          .then(
            (res) => {
              commit;

              resolve(res.data);
            },
            (error) => {
              if (error.response) {
                reject(error.response);
              } else if (error.request) {
                reject(error.request);
              } else {
                reject(error);
              }
            }
          )
          .catch((error) => {
            reject(error);
          });
      });
    },

    send_verfication_email({ commit }) {
      return new Promise((resolve, reject) => {
        silentAxios
          .post("/users/email-verification/")
          .then(
            (res) => {
              commit;
              resolve(res.data);
            },
            (error) => {
              if (error.response) {
                reject(error.response);
              } else if (error.request) {
                reject(error.request);
              } else {
                reject(error);
              }
            }
          )
          .catch((error) => {
            reject(error);
          });
      });
    },
  },

  mutations: {
    decrementNotificationCount(state) {
      state.unread_notification_count -= 1;
    },
    setHideClose(state) {
      state.hide_close = true;
    },
    DontShowModal(state) {
      state.showModal = false;
    },
    DoShowModal(state) {
      state.showModal = true;
    },
    storePreviousRoute(state, previousRoute) {
      state.previousRoute = previousRoute;
    },
    storeNextUrl(state, nextUrl) {
      state.nextUrl = nextUrl;
    },
    storeLocation(state, location) {
      state.location = location;
    },
    storeUserType(state, user_type) {
      state.user_type = user_type;
    },
    storeProfileImage(state, image) {
      state.user_image = image;
    },
    storeAuthData(state, data) {
      state.accessToken = data.access;
      state.refreshToken = data.refresh;
      state.username = data.user.username;
      state.location = data.profile.location;
      state.user_image = data.profile.photo;
      state.supporter = data.profile.supporter;
      state.userid = data.profile.user_id;
      state.chipin_user = data.profile.chipin_enabled;
      if (data.profile.chipin_account_type === "chipin_plus") {
        state.chipin_plus = true;
      } else {
        state.chipin_plus = false;
      }
      state.stripe_account_type = data.profile.made_on_stripe_account;
      state.account_type = data.profile.account_type;
      state.chipin_account_type = data.profile.chipin_account_type;
      state.signup_source = data.profile.signup_source;
      state.login_type = data.profile.login_type;
      state.user_type = data.profile.user_type;
      state.chosen_user_type = data.profile.user_type;
      localStorage.setItem("accessToken", data.access);
      localStorage.setItem("chosenUserType", data.profile.user_type);
      state.has_stripe_account = data.profile.has_stripe_account

      state.unread_notification_count = data.unread_notifications;

      state.kochava.identify(
        {
          user_id: String(state.userid),
          signup_source: state.signup_source,
          username: state.username,
          supporter: state.supporter,
          user_type: state.user_type,
          login_type: state.email,
          account_type: state.account_type,
        },
        (e, v) => console.log(v)
      );
    },
    clearAuthData(state) {
      state.accessToken = null;
      state.refreshToken = null;
      state.username = null;
      state.location = null;
      state.user_image = null;
      state.unread_notification_count = 0;
      localStorage.clear();
    },
    setChipInType(state, type) {
      if (type === "chipin") {
        state.chipin_plus = false;
      }
      if (type === "chipin_plus") {
        state.chipin_plus = true;
      }
      state.chipin_account_type = type;
    },
    switchUserType(state) {
      if (state.chosen_user_type === "discover") {
        state.chosen_user_type = "creator";
      } else {
        state.chosen_user_type = "discover";
      }
    },
    setCameFromSignUp(state, data) {
      state.came_from_signup = data;
    },
    setCameFromCampaign(state, data) {
      state.came_from_campaign = data;
    },
    storekochava(state) {
      //console.log("[KOCHAVA]: initiating")
      state.kochava = new Kochava(
        "kotrackd-web-ohshv09",
        true,
        true,
        false,
        {}
      );
    },
    kochavaIdentify(state) {
      //console.log("[KOCHAVA]: Identifying user")
      if (!state.kochava) {
        this.storekochava(state);
      }
      // kochava identify user elevated
      state.kochava.identify(
        {
          user_id: String(state.userid),
          signup_source: state.signup_source,
          username: state.username,
          supporter: state.supporter,
          user_type: state.user_type,
          login_type: state.email,
          account_type: state.account_type,
        },
        (e, v) => console.log(v)
      );
    },
    kochavaEvent(state, name, data) {
      //console.log(`[KOCHAVA]: Custom Event ${name}`)
      if (!state.kochava) {
        this.storekochava(state);
      }
      // custom event
      state.kochava.activity(name, data, (e, t) => console.log(t));
    },
    kochavaPage(state) {
      //console.log("[KOCHAVA]: Page Event")
      if (!state.kochava) {
        this.storekochava(state);
      }
      // page event
      state.kochava.page();
    },
  },
  modules,
  strict: debug,
  plugins: debug ? [createLogger()] : [], // set logger only for development
});
