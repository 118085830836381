<template>
  <div>
    <!-- MODAL -->

    <b-modal
      id="chipin-creator-thanks-modal"
      ref="chipin-creator-thanks-modal"
      size="lg"
      content-class="bg-dark text-light  "
      centered
      hide-footer
      header-close-variant="light"
    >
      <template #modal-header="{ close }">
        <b-container fluid>
          <b-row>
            <b-col class="float-left">
              <img
                src="../../../../assets/trackd-icon-colours.svg"
                class="mb-4 mx-auto text-left mt-2"
                height="25"
                alt="Logo"
              />
            </b-col>

            <b-col class="float-right">
              <b-button
                size="sm"
                variant="link"
                class="float-right"
                @click="close()"
              >
                <img
                  src="../../../../assets/section-close.svg"
                  class="mx-auto text-right float-right mt-2"
                  height="20"
                  alt="Close"
                />
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </template>

        <b-container fluid class="pb-5">
            <b-row class="pb-5">
                <b-col class="mb-3" cols="10" offset="1" sm="8" offset-sm="2">
                
                <b-row class="mb-3">
                    <b-col class="p-1">
                    <b-badge pill variant="info" class="w-100 text-info text-small"
                        >&nbsp;a</b-badge
                    >
                    </b-col>
                    <b-col class="p-1">
                    <b-badge
                        pill
                        variant="info"
                        class="w-100 text-info text-small"
                        >&nbsp;b</b-badge
                    >
                    </b-col>
                    <b-col class="p-1">
                    <b-badge
                        pill
                        variant="info"
                        class="w-100 text-info text-small"
                        >&nbsp;c</b-badge
                    >
                    </b-col>
                </b-row>
                </b-col>

                <b-col cols="2"></b-col>
                
                <b-col cols="2">
                <b-button v-b-modal.chipin-creator-modal variant="link" @click="$bvModal.hide('chipin-creator-thanks-modal')">
                    <img src="../../../../assets/arrow-left.svg" />
                </b-button>
                </b-col>

                <b-col cols="7" class="pr-0 pb-5">

                    <div class="row m-0 pb-5 p-0">
                        <div style="width: 100%">
                            <b-row>
                                <b-col col class="song-profile-des">
                                    <p class="mb-1 mt-2 font-weight-bold">Thanks!</p>
                                    <p class="mb-1 mt-2 font-weight-lighter">We'll be right<br/> back at ya!</p>
                                </b-col>
                                <b-col cols="12" class="">
                                    <p class="dont-miss mt-5">Don't miss an email from us!</p>
                                    <p class="info-text">
                                        Make sure you add ‘hello@trackdmusic.com' to your white list to 
                                        make sure you dont miss a message from Trackd.
                                    </p>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </b-modal>
  </div>
</template>


<script>
import { mapGetters } from 'vuex';


export default {
  
  name: "ChipInCreatorThanks",
  props: ['from'],
  data() {
    return {
        chipin_slide: false,
        pages: [

        ]
    };
  },
  methods: {
    
  },
  computed: {
    ...mapGetters(['user_image'])
    
  },
  mounted: function () {
    
  },
};
</script>

<style scoped>
#chipin-form {
  width: 100%;
  margin-top: 10px;
}

.modal-content {
  width: auto !important;
  margin: auto;
}

#chipin-form > h1 {
  font-weight: 900 !important;
  font-size: 36px;
  line-height: 21px;
  letter-spacing: 0.152972px;
}

#chipin-form > p {
  font-weight: 300 !important;
  font-size: 12px;
  line-height: 21px;
  letter-spacing: 0.152972px;
}

#chipin-form > h4 {
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.152972px;
}

.light-grey-border {
  border: 2px solid #9b9b9b;
}

.red-text {
  color: #ff0000;
}

.dark-text {
  color: #595959;
}

.super-small {
  font-size: 0.7em;
  color: #595959;
}

.rounded-btn {
  border-radius: 10px;
}

.line-height {
  line-height: 1.45em;
}

.included-info {
  display: inline-flex;
  align-items: center;
  float: left;
}

.song-profile-des > p {
  font-size: 34px;
  line-height: 36px;
}

.song-profile-des > h5 {
  font-weight: 500 !important;
  font-size: 34px;
  line-height: 36px;
}

.song-profile-des > span {
  font-weight: 300 !important;
  font-size: 18px;
  line-height: 24px;
  display: block;
  margin-top: 20px;
}

.months-btn {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 10px;
}

.months-btn > button {
  border-radius: 7px;
  font-family: "SFPro";
}

.select-btn {
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
  width: 236px;
  height: 40px;
}

.show-btn {
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
  width: 236px;
  height: 40px;
  border: 1px solid #00AFDC;
  background-color: transparent;
}

.choose-text {
    font-size: 18px;
    font-weight: 700;
}

.btn-lg {
    width: 374px;
    height: 40px;
    font-size: 18px;
    line-height: 10px;
    font-weight: 500;
}

.btn-lg-outline {
    width: 374px;
    height: 40px;
    font-size: 18px;
    line-height: 10px;
    font-weight: 500;
    background-color: transparent;
    border: 2px solid #00AFDC;
}

.desc {
    font-size: 14px;
    font-weight: 400;
}

.or {
    font-size: 18px;
    font-weight: 700;
}

.dont-miss {
    font-size: 18px;
    font-weight: 500;
}

.info-text {
    font-size: 14px;
    font-weight: 400;
}


</style>
