<template>
  <div>
    <b-button
      block
      variant="outline-light"
      class="
        login-btn-spacing
        pr-0 pr-md-5
        py-2
        my-2
        font-weight-light
        text-left
      "
      @click="logInWithGoogle"
    >
      <img
        src="../../assets/auth/google.svg"
        class="move-up google-image"
        width="24"
        alt="Email Icon"
      />
      {{ text }} with Google
    </b-button>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";

export default {
  props: ["text"],
  name: "googleLogin",
  methods: {
    ...mapActions("Auth", ["login_sso"]),
    ...mapMutations("Auth", ["setShowHomeOverlay"]),
    ...mapMutations(["kochavaEvent"]),
    logInWithGoogle() {
      this.$gAuth
        .signIn()
        .then((response) => {
          let access_token = null;

          let res = Object.keys(response);

          for (const key of res) {
            let inner = Object.keys(response[key]);

            for (const ikey of inner) {
              if (ikey === "access_token") {
                access_token = response[key][ikey];
              }
            }
          }

          if (!access_token) {
            this.$parent.$data.responseMsg = "Sorry there was a problem";
            this.$parent.$data.responseClass = "text-danger";
            return;
          }

          let sso_data = {
            backend: "google-oauth2",
            token: access_token,
          };

          var utm_campaign = this.$cookie.get("utm_campaign");
          var utm_medium = this.$cookie.get("utm_medium");
          var utm_source = this.$cookie.get("utm_source");

          if (utm_campaign) {
            sso_data["utm_campaign"] = utm_campaign;
          }

          if (utm_source) {
            sso_data["utm_source"] = utm_source;
          }

          if (utm_medium) {
            sso_data["utm_medium"] = utm_medium;
          }

          this.login_sso(sso_data)
            .then((res) => {
              if (res.data.profile.onboarded) {
                this.kochavaEvent("login", {
                  username: res.data.username,
                  user_type: res.data.profile.user_type,
                });
                this.$bvModal.hide("login-modal");
                this.setShowHomeOverlay(false);
              } else {
                this.$bvModal.hide("login-modal");
                this.$bvModal.hide("signup-modal");
                this.$bvModal.show("sso-username-onboarding-modal");
                this.setShowHomeOverlay(false);
                if (res.data.profile.user_type === "discover") {
                  if (this.$store.state.nextUrl) {
                    this.$router.push(this.$store.state.nextUrl);
                  } else {
                    this.$router.push({ name: "Home" });
                  }
                } else {
                  if (this.$store.state.nextUrl) {
                    this.$router.push(this.$store.state.nextUrl);
                  } else {
                    this.$router.push({ name: "Dashboard" });
                  }
                }
              }
            })
            .catch((error) => {
              if (error.data.errors) {
                this.$parent.$data.responseMsg = error.data.errors[0];
                this.responseClass = "text-danger";
              } else if (error.data.non_field_errors) {
                this.$parent.$data.responseMsg = error.data.non_field_errors[0];
                this.$parent.$data.responseClass = "text-danger";
              } else {
                this.$parent.$data.responseMsg = error.data.message;
                this.$parent.$data.responseClass = "text-danger";
              }
            });

          this.$router.push("/");
        })
        .catch((error) => {
          error;
        });
    },
  },
};
</script>

<style scoped>
.login-btn-spacing {
  padding-left: 5.5em;
}

.login-btn-spacing > img {
  filter: invert(0);
}

.login-btn-spacing:hover > img {
  filter: invert(1);
}

.google-image {
  margin-right: 12px;
}
</style>
