import silentAxios from "../../api/silent";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

// State object
const state = {
  // Song
  song: {
    id: null,
    title: null,
    username: null,
    file: null,
    image: null,
    locked: null,
    chipin_plus: null,
    user_id: null,
    indexed: null,
    radio: null,
  },

  // Queue
  previous: [],
  songs: [],
  queue_unshuffled: null,
  shuffle: false,
  repeat: false,

  player_hide: false,
  playing: false,
  paused: false,
  player: null,
  initialPlay: true,
  current_playlist: null,
  /* 30 second countdown to play increment
   * When time reaches 0, play count is incremeneted and play_time set to -1 */
  play_time: null,
  // Countdown function
  player_timer: null,

  show_locked_modal: false,
  play_after_auth: {
    shouldPlay: false,
    songToPlay: null,
  },
  preview: false,
  modal_id: null,
  preview_started: false,
  preview_time_played: 0,

  // Radio
  skips_left: null, // How many skips the user has left
  max_skips: null,  // How many skips are allowed
};

// Getter functions
const getters = {
  getPlayingSong(state) {
    return state.song;
  },
  playerHidden(state) {
    return state.player_hide;
  },
  isPlaying(state) {
    if (!state.song.id) {
      return false;
    }

    return state.playing;
  },
  getPreview(state) {
    return state.preview;
  },
  isPaused(state) {
    return state.paused;
  },
  loadedSong(state) {
    if (state.song.id) {
      return true;
    } else {
      return false;
    }
  },
  isSongLoaded(state) {
    if (state.song.id) {
      return true;
    } else {
      return false;
    }
  },
  getLoadedSong(state) {
    if (state.song.id) {
      return state.song.id;
    } else {
      return false;
    }
  },
  getLoadedSongData(state) {
    if (state.song.id) {
      return state.song;
    } else {
      return false;
    }
  },
  playedTime() {},
  duration() {},
  getSongQueue(state) {
    return state.songs;
  },
  getPreviousQueue(state) {
    return state.previous;
  },
  isInitialPlay(state) {
    return state.initialPlay;
  },
  getCurrentPlaylist(state) {
    return state.current_playlist;
  },
  getPlayTime(state) {
    return state.play_time;
  },
  getPlayerTimer(state) {
    return state.player_timer;
  },
  isShuffled(state) {
    return state.shuffle;
  },
  getRepeat(state) {
    return state.repeat;
  },
  getChipinPlus(state) {
    return state.song.chipin_plus;
  },
  getUserId(state) {
    return state.song.user_id;
  },
  isLocked(state) {
    return state.song.locked;
  },
  getUsername(state) {
    return state.song.username;
  },
  showLockedModal(state) {
    return state.show_locked_modal;
  },
  shouldPlayAfterAuth(state) {
    return state.play_after_auth.shouldPlay;
  },
  getPlayAfterAuth(state) {
    return state.play_after_auth.songToPlay;
  },  
  getModalId(state) {
    return state.modal_id;
  },
  getPreviewStarted(state) {
    return state.preview_started;
  },
  getPreviewTimePlayed(state) {
    return state.preview_time_played;
  },
  getRemainingSkips(state) {
    return state.skips_left;
  }
};

// Actions
const actions = {
  track_play({ commit }, { song_id, is_radio, type = 'fullplay'}) {
    // fullplay - tracks / increments play count by 1
    var post_data = {
      song: song_id,
      type: type,
      timestamp: 30,
      radio: is_radio,
    };

    commit;
    return new Promise((resolve, reject) => {
      silentAxios
        .post("/playbackhistory/", post_data)
        .then(
          (res) => {
            resolve(res);
            
          },
          (error) => {
            if (error.response) {
              reject(error.response);
            } else if (error.request) {
              reject(error.request);
            } else {
              reject(error);
            }
          }
        )
        .catch((error) => {
          reject(error);
        });
    });
  },
  radio_queue({ commit }, [type, id]) {
    var url = "";

    if (type === "user") {
      url = "/radio/?user_id=" + id;
    }
    if (type === "song") {
      url = "/radio/?song_id=" + id;
    }

    commit;
    return new Promise((resolve, reject) => {
      silentAxios
        .get(url)
        .then(
          (res) => {
            //commit('resetPlayer')
            commit("clearQueue");
            
            commit("setSong", res.data.playlist[0]);
            res.data.playlist.shift()

            commit("setSkips", res.data.skips_remaining);
            // commit("setSkips", 5);
            commit("setMaxSkips", res.data.skips_total);

            //commit('playSong')
            for (const song of res.data.playlist) {
              commit("addSong", song);
            }

            resolve(res);
          },
          (error) => {
            if (error.response) {
              reject(error.response);
            } else if (error.request) {
              reject(error.request);
            } else {
              reject(error);
            }
          }
        )
        .catch((error) => {
          reject(error);
        });
    });
  },

  startTimer(context) {
    var interval = setInterval(() => {
      if (getters.getPlayTime(state) > 0) {
        context.commit("decrementTimer");
      } else {
        context.commit("setPlayTime", -1);
      }
    }, 1000);
    context.commit("setPlayerTimer", interval);
  },
  stopTimer(context) {
    context.commit("clearPlayerTimer");
  },
};

// Mutations
const mutations = {
  setSong(state, data) {
    if (data) {
      if (data.name) {
        state.song.title = data.name;
      }
      if (data.song_name) {
        state.song.title = data.song_name;
      }
      if (data.title) {
        state.song.title = data.title;
      }

      if (data.username) {
        state.song.username = data.username;
      }
      if (data.artist_name) {
        state.song.username = data.artist_name;
      }

      if (data.preview) {
        state.preview = true
      } else {
        state.preview = false
      }

      if (data.modal_id) {
        state.modal_id = data.modal_id
      } else {
        state.modal_id = null
      }

      state.song.id = data.id;

      if (data.file) {
        state.song.file = data.file;
      }
      if (data.song_url) {
        state.song.file = data.song_url;
      }
      state.song.image = data.image;

      state.song.locked = data.locked;

      state.song.chipin_plus = data.chipin_plus;

      state.song.user_id = data.user_id;

      if (data.user_id) { state.song.user_id = data.user_id; }
      if (data.artist_id) { state.song.user_id = data.artist_id; }

      state.song.radio = data.radio;

      state.song.indexed = data.indexed;

      state.show_locked_modal = false;

      // state.songs.push(data)
      //state.player = player
      // state.playing = true
      state.player_hide = false;
      state.play_time = 30;
    } else {
      // reset the player
      state.playing = false;
      state.paused = false;
      state.preview = false
      state.song = {
        id: null,
        title: null,
        username: null,
        file: null,
        image: null,
      };
      state.current_playlist = null;
    }
  },
  hidePlayer(state) {
    state.player_hide = true;
  },
  addSong(state, data) {
    state.songs.push(data);
  },
  addPreviousSong(state, data) {
    state.previous.push(data);
  },
  playSong(state) {
    state.playing = true;
    state.paused = false;
  },
  resetPlayer(state) {
    // Don't reset the player after the preview ends un
    if (!state.preview || (state.preview_started && state.play_time === -1)) {
      state.playing = false;
      state.paused = false;
      state.preview = false
      state.song = {
        id: null,
        title: null,
        username: null,
        file: null,
        image: null,
      };
      state.current_playlist = null;
    }
  },
  pauseSong(state) {
    state.playing = false;
    state.paused = true;
  },
  clearQueue(state) {
    state.songs.splice(0);
  },
  nextSong(state) {
    if (state.songs.length <= 0) {
      state.playing = false;
      state.paused = false;
      state.song = {
        id: null,
        title: null,
        username: null,
        file: null,
        image: null,
        radio: null,
      };
      state.songs.splice(0);
      state.previous.splice(0);
    } else {
      // console.log("Loading in next song")
      // Add currently playing song to previous queue
      let previous = Object.assign({}, state.song);
      state.previous.unshift(previous);

      // Pop from the front of the player queue and set next song
      let next = state.songs.shift();

      if (next.name) { state.song.title = next.name; }
      if (next.song_name) { state.song.title = next.song_name; }
      if (next.title) { state.song.title = next.title; }

      if (next.username) { state.song.username = next.username; }
      if (next.artist_name) { state.song.username = next.artist_name; }
      
      state.song.id = next.id;

      if (next.user_id) { state.song.user_id = next.user_id; }
      if (next.artist_id) { state.song.user_id = next.artist_id; }


      if (next.file) {state.song.file = next.file; }
      if (next.song_url) { state.song.file = next.song_url; }

      state.song.image = next.image;

      state.song.locked = next.locked;

      state.song.chipin_plus = next.chipin_plus;

      state.song.indexed = next.indexed;

      state.show_locked_modal = false;

      state.song.radio = next.radio;

    }
  },
  previousSong(state) {
    if (state.previous.length <= 0) {
      // Restart current song?
      state.playing = false;
      state.paused = false;
      state.song = {
        id: null,
        title: null,
        username: null,
        file: null,
        image: null,
      };
      state.songs.splice(0);
      state.previous.splice(0);
    } else {
      // Add current song to front of song queue
      let next = Object.assign({}, state.song);
      state.songs.unshift(next);

      // // Pop from front of previous queue
      let previous = state.previous.shift();

      if (previous.name) {
        state.song.title = previous.name;
      }
      if (previous.song_name) {
        state.song.title = previous.song_name;
      }
      if (previous.title) {
        state.song.title = previous.title;
      }

      state.song.username = previous.username;
      state.song.id = previous.id;

      if (previous.file) {
        state.song.file = previous.file;
      }
      if (previous.song_url) {
        state.song.file = previous.song_url;
      }
      state.song.image = previous.image;

      state.song.locked = previous.locked;

      state.song.chipin_plus = previous.chipin_plus;

      if (previous.user_id) { state.song.user_id = previous.user_id; }
      if (previous.artist_id) { state.song.user_id = previous.artist_id; }

      state.song.indexed = previous.indexed;

      state.show_locked_modal = false;
    }
  },
  setInitialPlay(state, data) {
    state.initialPlay = data;
  },
  setCurrentPlaylist(state, data) {
    state.current_playlist = data;
  },
  setPlayTime(state, data) {
    state.play_time = data;
  },
  setPlayerTimer(state, data) {
    state.player_timer = data;
  },
  decrementTimer(state) {
    state.play_time--;
  },
  clearPlayerTimer(state) {
    clearInterval(state.player_timer);
  },
  toggleShuffle(state) {
    if (!state.shuffle) {
      state.queue_unshuffled = state.songs.slice(0);
      state.songs = ShuffleQueue(state.songs);
      state.shuffle = true;
    } else {
      state.songs = state.queue_unshuffled;
      state.queue_unshuffled = null;
      state.shuffle = false;
    }
  },
  toggleRepeat(state) {
    if (state.repeat) {
      state.repeat = false;
    } else {
      state.repeat = true;
    }
  },
  setShowLockedModal(state, data) {
    state.show_locked_modal = data;
  },
  setPlayAfterAuth(state, data) {
    state.play_after_auth = data;
  },
  setPreviewStarted(state, data) {
    state.preview_started = data;
  },
  setPreviewTimePlayed(state, data) {
    state.preview_time_played = data;
  },
  decrementSkips(state) {
    state.skips_left--;
  },
  resetSkips(state) {
    state.skips_left = 5;
  },
  setSkips(state, data) {
    state.skips_left = data;
  },
  setMaxSkips(state, data) {
    state.max_skips = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

const ShuffleQueue = (array) => {
  /* Fisher-Yates in place shuffle - O(n) */
  var n = array.length;
  var index;
  var temp;
  // While remaining elements to shuffle
  while (n) {
    // Pick a random unshuffled element
    index = Math.floor(Math.random() * n--);

    // Swap with current element
    temp = array[n];
    array[n] = array[index];
    array[index] = temp;
  }
  return array;
};

