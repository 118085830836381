<template>
  <div>
    <b-sidebar
      id="menu-sidebar"
      bg-variant="dark"
      shadow
      backdrop
      text-variant="light"
      v-bind:sidebar-class="{
        'sidebar-colour': true,
        'px-3 pb-3 c-sidebar--player-hidden':
          this.displayPlayerStatus == 'hidden',
        'px-3 pb-3 c-sidebar--player-collapsed':
          this.displayPlayerStatus == 'collapsed',
        'px-3 pb-3 c-sidebar--player-expanded':
          this.displayPlayerStatus == 'expanded',
      }"
    >
      <template #header="{ hide }">
        <b-button
          size="sm"
          variant="link"
          class="text-right float-right"
          @click="hide"
        >
          <img
            src="../../assets/section-close.svg"
            class="mx-auto text-right float-right mt-2"
            height="23"
            alt="Close"
          />
        </b-button>
      </template>

      <div class="px-3 py-2 text-light" style="margin-left: 15px">
        <!-- Authenticated -->
        <div v-if="isAuthenticated">
          <b-media>
            <template #aside>
              <b-img
                v-if="!user_image"
                blank
                rounded="circle"
                blank-color="#ccc"
                width="60"
                height="60"
                alt="placeholder"
              ></b-img>
              <b-img
                v-else
                :src="getImagePath(user_image, { width: 60, height: 60 })"
                rounded="circle"
                blank-color="#ccc"
                width="60"
                height="60"
                alt="placeholder"
              ></b-img>
            </template>
            <h5
              class="mt-1 mb-1 pb-0 text-light font-weight-light"
              style="font-size: 1.5em"
            >
              <router-link
                v-if="isAuthenticated"
                class="text-light pb-0 mb-0 mt-0 pt-0 asset-user"
                :to="{ name: 'ArtistUsername', params: { id: username } }"
                v-html="elipsis(username)"
              ></router-link>
            </h5>
            <p
              class="text-muted text-light font-weight-lighter"
              style="font-size: 0.875em"
            >
              {{ location }}
            </p>
          </b-media>
        </div>

        <!-- Not Authenticated -->
        <div class="mb-4" v-else>
          <img
            src="../../assets/trackd-logo.svg"
            height="30"
            class="mb-4"
            alt="Trackd logo"
          />
        </div>

        <label class="sr-only" for="input-search">Search</label>
        <b-input-group class="mb-2 mt-2 mt-md-4 mr-sm-2 mb-sm-0 pb-1 pb-md-3">
          <b-input-group-prepend
            class="px-2 search-input"
            style="
              border-top-left-radius: 10px;
              border-bottom-left-radius: 10px;
            "
          >
            <b-icon-search font-scale="1" class="mx-auto m-2"></b-icon-search>
          </b-input-group-prepend>
          <b-form-input
            id="input-search"
            class="search-input font-weight-light "
            style="
              border-top-right-radius: 0px;
              border-bottom-right-radius: 0px;
            "
            placeholder="Search Artist, Song or Playlist"
            v-model="search_param"
            v-on:input="search_change()"
          ></b-form-input>
          <b-input-group-append
            class="px-2 search-input"
            style="
              border-top-right-radius: 10px;
              border-bottom-right-radius: 10px;
              margin-left:0px;
              border-left: none;
            "
          >
            <b-icon-x-circle-fill
              v-if="search_results"
              font-scale="1"
              class="mx-auto"
              @click="clearSearch"
            />
          </b-input-group-append>
        </b-input-group>

        <div v-if="search_results">
          <div v-if="search_results.users">
            <h4 class="text-light font-weight-normal pt-3 pb-0 mb-0">
              Artists
            </h4>
            <hr class="bg-light mb-0" />
            <b-list-group
              variant="dark"
              v-for="(result, index) in search_results.users"
              :key="index"
            >
              <b-list-group-item
                class="text-light font-weight-light bg-dark pl-0 ml-0 mb-0 pb-0"
                :to="{
                  name: 'ArtistUsername',
                  params: { id: result.username },
                }"
                >{{ result.username }}</b-list-group-item
              >
            </b-list-group>
          </div>

          <div v-if="search_results.results">
            <h4 class="text-light font-weight-normal pt-3 pb-0 mb-0">Songs</h4>
            <hr class="bg-light mb-0" />
            <b-list-group
              v-for="(result, index) in search_results.results"
              :key="index"
            >
              <b-list-group-item
                class="text-light font-weight-light bg-dark pl-0 ml-0 mb-0 pb-0"
                :to="{ name: 'Song', params: { id: result.id } }"
                >{{ result.value }}</b-list-group-item
              >
            </b-list-group>
          </div>
        </div>

        <b-button
          v-if="!isAuthenticated"
          block
          :to="{ name: 'ForArtists' }"
          class="
            text-left
            px-0
            pt-1
            mb-0
            font-weight-lighter
            text-light
             hamburger-menu-link
          "
          
          size="lg"
          variant="link"
          >Trackd For Artists</b-button
        >

        <b-button
          v-if="whatUserType() === 'creator' && isAuthenticated"
          @click="navSwitchUserType()"
          block
          class="
            text-left
            px-0
            pt-0
            mb-2
            font-weight-lighter
            trackd-blue
             hamburger-menu-link
          "
          size="lg"
          variant="link"
          >Switch to Discover</b-button
        >
        <b-button
          v-if="whatUserType() === 'discover' && isAuthenticated"
          @click="navSwitchUserType()"
          block
          class="
            text-left
            px-0
            pt-0
            mb-2
            font-weight-lighter
            trackd-red
             hamburger-menu-link
          "
          size="lg"
          variant="link"
          >Switch to Create</b-button
        >

        <b-button
          v-if="$route.name == 'Home'"
          :to="{
            name: 'LibraryArea',
            params: { area: 'discover', name: 'Home' },
          }"
          block
          class="mt-1 text-left pl-0 text-light font-weight-lighter pb-0 hamburger-menu-link"
          size="lg"
          variant="link"
          >Switch Home View</b-button
        >
        <b-button
          v-if="$route.name == 'LibraryArea'"
          :to="{ name: 'Home' }"
          block
          class="mt-1 text-left pl-0 text-light font-weight-lighter pb-0 hamburger-menu-link"
          size="lg"
          variant="link"
          >Switch Home View</b-button
        >

        <UnAuthenticatedNav v-if="!isAuthenticated"></UnAuthenticatedNav>

        <!-- Authenticated -->
        <div v-else>
          <DiscoverNav v-if="whatUserType() === 'discover'"></DiscoverNav>
          <CreatorNav v-else></CreatorNav>
        </div>
      </div>
      <template #footer="{}">
        <b-button
          v-if="isAuthenticated"
          block
          @click="logout_"
          class="
            text-left text-light
            pt-0
            mt-0
            pl-0
            pb-0
            font-weight-lighter
            px-3
            pb-0
             hamburger-menu-link
          "
          style="margin-left: 15px"
          size="lg"
          variant="link"
          :class="{ 'trackd-dark-grey': isMobile() }"
          >Logout</b-button
        >


        <b-col>
          <b-button
            variant="link"
            class="px-0 
              px-3 mt-2"
            @click="app_store_click"
          >
            <img
              src="../../assets/app-store-dark.svg"
              class="ml-3 hamburger-app-store"
              alt="Logo"
            />
          </b-button>

        </b-col>

        <div
          v-if="isMobile()"
          class="mt-3 px-3"
          style="font-size: 0.9em; margin-left: 15px"
        >
          <small class="text-muted font-weight-lighter">
            <router-link
              class="text-muted mr-4 menu-support"
              :to="{ name: 'Legal' }"
              >Support</router-link
            >|<router-link
              class="ml-4 text-muted menu-support"
              :to="{ name: 'Legal' }"
              >Share Trackd</router-link
            >
          </small>
        </div>

        <div
          block
          class="d-flex text-light align-items-center px-3 py-3"
          style="margin-left: 15px"
          :class="{ 'bg-dark': !isMobile() }"
        >
          <b-media class="sidebar-footer-links">
            <template #aside>
              <img
                src="../../assets/trackd-icon-white.svg"
                blank-color="#ccc"
                height="20"
                class="footer-logo"
                alt="placeholder"
              />
            </template>

            <small class="text-muted font-weight-lighter sidebar-footer-links">
              © {{ new Date().getFullYear() }}. Trackd is a Registered Trademark
              <br />
              <router-link class="text-muted" :to="{ name: 'Legal' }"
                >Terms</router-link
              >
              |
              <router-link class="text-muted" :to="{ name: 'Legal' }"
                >Legal</router-link
              >
              <!--|  <router-link class="text-muted"  :to="{'name': 'Legal'}">Credits</router-link>-->
              | Version: 3.0
            </small>
          </b-media>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script
  async
  defer
  crossorigin="anonymous"
  src="https://connect.facebook.net/en_US/sdk.js"
></script>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

import CreatorNav from "./CreatorNav.vue";
import UnAuthenticatedNav from "./UnAuthenticatedNav.vue";
import DiscoverNav from "./DiscoverNav.vue";

import { eventPlayerDisplay } from "../../services/PlayerDisplayEventBus";

export default {
  name: "App",
  data() {
    return {
      billing_clicked: false,
      search_param: "",
      search_results: null,
      displayPlayerStatus: "",
    };
  },
  components: {
    UnAuthenticatedNav,
    CreatorNav,
    DiscoverNav,
  },
  methods: {
    ...mapGetters(["whatUserType"]),
    ...mapActions("Auth", ["reauth"]),
    ...mapActions("Library", ["search"]),
    ...mapMutations(["switchUserType"]),
    ...mapActions({ main_logout: "logout", portal: "portal" }),
    scrollTo: function(hashtag) {
      setTimeout(() => {
        location.href = hashtag;
      }, 1000);
    },
    logout_() {
      this.main_logout();
      if (this.$route.name !== "Home") {
        this.$router.replace("/");
      }
    },
    app_store_click() {
      window.open(
        "https://apps.apple.com/gb/app/trackd-songs-start-here/id1496465739",
        "_blank"
      );
    },
    ideapros_click() {
      window.open(
        "https://www.ideapros.com/team/",
        "_blank"
      );
    },
    navSwitchUserType() {
      this.switchUserType();

      if (this.$store.state.chosen_user_type === "discover") {
        this.$router.push({ name: "Home" });
      } else {
        this.$router.push({ name: "Dashboard" });
      }
    },
    BillingLink() {
      this.billing_clicked = true;
      this.portal()
        .then((res) => {
          this.billing_clicked = false;
          window.location = res.url;
        })
        .catch((error) => {
          //console.log(error);
          error
          this.billing_clicked = false;
        });
    },
    clearSearch() {
      this.search_param = null;
      this.search_results = null;
    },
    search_change() {
      if (this.search_param.length > 3) {
        this.search(this.search_param)
          .then((res) => {
            this.search_results = res.data;
          })
          .catch((error) => {
            error
            //console.log(error);
          });
      }
      if (!this.search_param) {
        this.search_param = null;
        this.search_results = null;
      }
    },
  },
  computed: {
    ...mapGetters("Generic", { loading: "loading" }),
    ...mapGetters([
      "isAuthenticated",
      "username",
      "user_image",
      "location",
      "isChipInUser",
      "isSupporter",
      "showBilling",
      "isWebUser",
    ]),
    dark_nav: function() {
      if (this.$route.name === "Home" || this.$route.name === "Legal") {
        return true;
      } else {
        return false;
      }
    },
    mobile_header_margin() {
      if (!this.isMobile()) return false;
      if (this.$route.name === "Home") {
        return true;
      } else {
        return false;
      }
    },
    switchHomeView() {
      if (this.$route.name === "Home" || this.$route.name == "LibraryArea") {
        return false;
      }

      return true;
    },
    banner_margin() {
      if (this.isMobile()) return false;
      if (this.$route.name === "LibraryArea") {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    let that = this;

    eventPlayerDisplay.$on(
      "onChangePlayerShowStatus",
      (displayPlayerStatus) => {
        that.displayPlayerStatus = displayPlayerStatus;
      }
    );
  },
};
</script>

<style>
#menu-sidebar {
  width: 33%;
  min-width: 350px;
  max-width: 450px;
}

.text-small {
  font-size: 0.9em;
}

/* Search */

.search-input,
.search-input:focus {
  /*background-color: #4a4a4a;
  border-color: #4a4a4a;*/
  background-color: #fff;
  color: #c1c0c9;
  padding-top: 0px;
  padding-bottom: 0px;
  font-size: 1.25rem;
  border: none;
  padding-left: 0px;
  padding-right: 0px;
}

.search-input.input-group-prepend,
.search-input.input-group-append {
  background-color: #fff;
  /*background-color: #4a4a4a;
  border-color: #4a4a4a;*/
  color: #c1c0c9;
}

#input-search {
  border-radius: 0px;
  background-color: #fff;
  /*background-color: #4a4a4a;
  border-color: #4a4a4a; */
  color: #9b9b9b;
  font-size: 1.25em;
}

.sidebar-colour {
  border-right: 7px solid #e76e66;
  border-image: linear-gradient(
    to bottom,
    #f2b471 33.33%,
    #01afdc 33.33%,
    #01afdc 66.67%,
    #e76e66 66.67%
  );
  border-image-slice: 1;
  border-top: 0;
  border-bottom: 0;
  border-left: 0;

  /*border-image: linear-gradient(to bottom, #F2B471 33.33%, #01AFDC 33.33%,#01AFDC 66.67%, #E76E66 66.67%);*/
}

#input-search::placeholder {
  color: #9b9b9b;
}

.mobile-search-bar {
  font-size: 1.25em;
}

.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none;
}

.bg-none {
  background: transparent !important;
}

.padding {
  margin-bottom: 4em;
}

.modal-header {
  border-bottom: none !important;
}

* {
  font-family: "Roboto";
}

.footer-logo {
  border-radius: 50%;
  -webkit-transition: -webkit-transform 0.8s ease-in-out;
  transition: transform 0.8s ease-in-out;
}
.footer-logo:hover {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}

.b-sidebar > .b-sidebar-header {
  display: unset;
  justify-content: space-between;
}

@media (min-width: 768px) {
  .search-input {
    height: 40px;
  }
}

@media (max-width: 992px) {
  #menu-sidebar {
    width: 45%;
  }
}

@media (max-width: 576px) {
  #menu-sidebar {
    width: 85%;
    min-width: 85%;
    max-width: auto !important;
  }

  .hamburger-menu-link {
    font-size: 18px;
  }
}

@media (max-width: 575.98px) {
  .mobile-search-bar {
    background-color: #fff;
    border: none;
    font-size: 14px;
  }

  .mobile-search-bar:focus {
    font-size: 14px;
  }

  #menu-sidebar {
    width: 87%;
    min-width: 87%;
    max-width: auto !important;
  }

  .hamburger-menu-link {
    font-size: 16px;
  }

  #input-search {
    font-size: 12px !important;
    height: 2.25rem;
  }

  .text-muted {
    font-size: 11px !important;
  }
}

@media (max-width: 375px) {
  .text-muted {
    font-size: 10px !important;
  }
}

@media (max-width: 320px) {
  #menu-sidebar {
    width: 100%;
  }

  .hamburger-app-store {
    width: 80px;
  }

  #input-search {
    font-size: 1em !important;
  }

  .text-muted {
    font-size: 10px !important;
  }
}

.player-margin {
  margin-bottom: 180px !important;
}

.mobile-header-margin {
  margin-top: 130px;
}

.mobile-player-margin {
  margin-bottom: 200px;
}

.sidebar-footer-links {
  font-size: 0.8em;
  line-height: 95%;
}

.modal-lg {
  max-width: 600px !important;
}

.input-box {
  box-shadow: none !important;
  border: none !important;
  border-radius: 0 !important;
  font-size: 15px;
  font-weight: 300;
  line-height: 22px;
}

.next-btn {
  border-radius: 10px !important;
  text-transform: uppercase;
  font-size: 15px;
  line-height: 22px;
  font-weight: 700;
}
.tag-box {
  border-radius: 10px;
}

.text-title {
  font-size: 34px;
  font-weight: 500;
  line-height: 36px;
}

.text-des {
  font-size: 34px;
  font-weight: 200;
  line-height: 36px;
}

.text-des > b {
  font-weight: 500 !important;
}

.text-fdes {
  font-size: 18px;
  font-weight: 300 !important;
  line-height: 24px;
}

.text-fdes-sec {
  font-size: 18px;
  font-weight: 200 !important;
  line-height: 24px;
}

.text-fdes > b {
  font-weight: 500 !important;
}

.text-fdes-sec > b {
  font-weight: 500 !important;
}

.text-sub {
  font-size: 15px;
  font-weight: 300 !important;
  line-height: 22px;
}

.text-short {
  font-size: 12px;
  font-weight: 300 !important;
  line-height: 16px;
}

.menu-text {
  font-weight: 300 !important;
  font-size: 18px;
  line-height: 40px;
}

.text-muted {
  font-weight: 300 !important;
  font-size: 12px;
  line-height: 16px;
  /* display: flex;
    align-items: flex-end; */
  color: #9b9b9b !important;
}

.menu-support {
  font-size: 14px !important;
  line-height: 24px !important;
}

.danger-upgrade {
  color: #e76e66 !important;
}
</style>
