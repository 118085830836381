<template>
  <div>
    <b-button
      v-if="!isMobile()"
      block
      v-b-modal.login-modal
      content-class="bg-dark"
      class="text-left text-light font-weight-lighter pl-0 pt-0 pb-0 mt-0 mt-1 hamburger-menu-link"
      size="lg"
      variant="link"
      >Login</b-button
    >
    <b-button
      block
      class="text-left text-light font-weight-lighter pl-0 pt-0 pb-0 mt-3 mt-md-5 hamburger-menu-link"
      size="lg"
      variant="link"
      :to="{ name: 'Pricing' }"
      >Features & Pricing</b-button
    >
    <b-button
      block
      :to="{ name: 'AppPage' }"
      content-class="bg-dark"
      class="text-left text-light font-weight-lighter pl-0 pt-0 pb-0 mt-0 mt-1 hamburger-menu-link"
      size="lg"
      variant="link"
      >The App</b-button
    >
    <b-button
      block
      class="text-left text-light font-weight-lighter pl-0 pt-0 pb-0 mt-0 mt-1 hamburger-menu-link"
      size="lg"
      variant="link"
      :to="{ name: 'AboutUs' }"
      >About Us</b-button
    >
    <b-button
      block
      class="text-left text-light font-weight-lighter pl-0 pt-0 pb-0 mt-0 mt-1 hamburger-menu-link"
      size="lg"
      variant="link"
      :to="{ name: 'MusicRights' }"
      >Your Music Rights</b-button
    >
  </div>
</template>

<script>
export default {
  name: "CreatorNav",
};
</script>

<style></style>
