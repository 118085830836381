<template>
  <div class="d-flex flex-column justify-content-center">
    <div
      class="card rounded-circle border-none border-light bg-none c-container-image-song"
      :class="'c-container-image-song__' + this.componentUuid"
    >
      <div v-if="artist_image" class="song-mini-artist-image">
        <img
          v-if="artist_image"
          class="card-img rounded-circle"
          @click="play"
          :src="getImagePath(artist_image, { width: 80, height: 80 })"
          alt="song image"
        />
      </div>
      <div v-if="chipin_plus && locked && playerleft" class="song-mini-locked">
        <img
          v-if="chipin_plus && locked && playerleft"
          class="card-img rounded-circle"
          src="../assets/chipin+-locked-light.svg"
          alt="song image"
        />
      </div>

      <!-- Playlist -->
      <div
        v-if="player_type === 'playlistasset' && image.length > 0"
        class="play_wrapper other-image-mobile"
        v-bind:class="{
          playing:
            playing &&
            id === getCurrentPlaylist,
          paused:
            paused &&
            (id === getLoadedSong ||
              queue.filter((song) => song.id === getLoadedSong).length > 0),
        }"
      >
        <div
          class="quarter left-top split_image"
          :style="'background: url(' + image[0 % image.length] + ')'"
        />
        <div
          class="quarter right-top split_image"
          :style="'background: url(' + image[1 % image.length] + ')'"
        />
        <div
          class="quarter left-bottom split_image"
          :style="'background: url(' + image[2 % image.length] + ')'"
        />
        <div
          class="quarter right-bottom split_image"
          :style="'background: url(' + image[3 % image.length] + ')'"
        />
      </div>

      <!-- Song -->
      <div v-else>

        <template> <!--  v-if="isAuthenticated" -->
          <b-img
            v-if="player_image"
            class="card-img rounded-circle other-image-mobile"
            @click="play"
            :style="player_image_style"
            :src="player_image"
            v-bind:class="{
              playing:
                playing && id === getLoadedSong && player_type != 'playlist',
              paused:
                paused && id === getLoadedSong && player_type != 'playlist',
              playlist_playing:
                playing && id === getLoadedSong && player_type == 'playlist',
              playlist_paused:
                paused && id === getLoadedSong && player_type == 'playlist',
              'border-white': !playing && id === getLoadedSong && show_border,
            }"
            alt="song image"
          ></b-img>
          <b-img
            v-else
            blank
            rounded="circle"
            blank-color="#00AFDC"
            class="other-image-mobile"
            :style="player_image_style"
            @click="play"
            :src="player_image"
            v-bind:class="{
              playing: playing && id === getLoadedSong,
              paused: paused && id === getLoadedSong,
              'border-white': !playing && id === getLoadedSong && show_border,
            }"
            alt="placeholder"
          ></b-img>
        </template>
        <!--
        <template v-else>
          <b-img
            v-if="player_image"
            class="card-img rounded-circle other-image-mobile"
            @click.native="$bvModal.show('login-modal')"
            :src="player_image"
            alt="song image"
          ></b-img>
          <b-img
            v-else
            blank
            rounded="circle"
            blank-color="#00AFDC"
            class="other-image-mobile"
            @click.native="$bvModal.show('login-modal')"
            :src="player_image"
            v-bind:class="{
              playing: playing && id === getLoadedSong,
              paused: paused && id === getLoadedSong,
            }"
            alt="placeholder"
          ></b-img>
        </template>
        -->
      </div>

      <radial-progress-bar
        :diameter="this.playerDiameter"
        :total-steps="this.total_time"
        :completed-steps="this.executed_time"
        :startColor="'#e76e66'"
        :stopColor="'#e76e66'"
        :animateSpeed="5"
        :class="
          'c-song-progress-bar c-song-progress-bar__' + this.componentUuid
        "
        v-show="
          id === getLoadedSong ||
            (player_type === 'playlistasset' &&
              playing && id === getCurrentPlaylist)
        "
      >
      </radial-progress-bar>

      <div
        class="card-img-overlay d-flex justify-content-center align-items-center c-photo-player"
        v-bind:class="{ 'mx-auto': !playerleft }"
      >
        <!-- Un-Authorised -->
        <!--<img
          v-if="!isAuthenticated"
          @click="$bvModal.show('login-modal')"
          src="../assets/play-icon-filled.svg"
          rounded="circle"
          class="mx-auto play-icon"
          style="z-index: 10;"
          alt="play icon"
        />-->
        <!-- Authorised -->
        <template > <!-- v-else -->
          <img
            v-if="!playing && !loadedSong"
            @click="play"
            src="../assets/play-icon-filled.svg"
            rounded="circle"
            class="mx-auto  play-icon"
            :class="this.player_type == 'playlist' ? 'playlist-play-icon' : ''"
            style="z-index: 10"
            alt="play icon"
          />
          <img
            v-if="!playing && loadedSong && id !== getLoadedSong"
            @click="play"
            src="../assets/play-icon-filled.svg"
            rounded="circle"
            class="mx-auto play-icon"
            :class="this.player_type == 'playlist' ? 'playlist-play-icon' : ''"
            style="z-index: 10"
            alt="play icon"
          />
          <img
            v-if="!playing && loadedSong && id === getLoadedSong"
            @click="play"
            src="../assets/play-icon-filled.svg"
            rounded="circle"
            class="mx-auto play-icon"
            :class="this.player_type == 'playlist' ? 'playlist-play-icon' : ''"
            style="z-index: 10"
            alt="play icon"
          />
          <img
            v-if="playing && loadedSong && id !== getLoadedSong"
            @click="play"
            src="../assets/play-icon-filled.svg"
            rounded="circle"
            class="mx-auto play-icon"
            :class="this.player_type == 'playlist' ? 'playlist-play-icon' : ''"
            style="z-index: 10"
            alt="play icon"
          />
          <img
            v-if="playing && loadedSong && id === getLoadedSong"
            @click="pause"
            src="../assets/playing-icon.svg"
            rounded="circle"
            class="mx-auto playing-icon"
            width="25"
            alt="play icon"
          />
        </template>
      </div>
    </div>
    <RadioSubscribe v-bind:user_image="image" v-bind:artist_name="username" v-bind:user_id="id" v-bind:index="componentUuid"></RadioSubscribe>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import RadialProgressBar from "vue-radial-progress";
import { SongDataInfo } from "../services/SongDataEventBus";
import { v4 as uuidv4 } from "uuid";
import RadioSubscribe from "../components/modal/RadioSubscribe.vue"

// import func from "vue-editor-bridge";

export default {
  props: [
    "image",
    "file",
    "title",
    "username",
    "id",
    "useSmallPlayButton",
    "player_type",
    "locked",
    "chipin_plus",
    "indexed",
    "artist_id",
    "show_border",
    "playerleft",
    "artist_image",
    "queue",
    "playlistId",
  ],
  components: {
    RadialProgressBar,
    RadioSubscribe,
  },
  data: function() {
    return {
      play_button_effect: "",
      player_image_style: "",
      player_image: null,
      player: null,
      componentUuid: uuidv4(),
      executed_time: 0,
      total_time: 0,
      playerDiameter: 0,
      songWasPlayed: false,
      preview: false,
    };
  },
  methods: {
    ...mapMutations("Player", [
      "playSong",
      "pauseSong",
      "setSong",
      "addSong",
      "addPreviousSong",
      "clearQueue",
      "setPlayAfterAuth",
    ]),
    play: function() {
      this.songWasPlayed = true;

      // Determine if clicked asset is a playlist or a song
      if (this.player_type === "playlistasset") {
        SongDataInfo.$emit("PlaylistIdIsBeingPlayed", {
          playlistId: this.$props.id,
        });
      } else {
        SongDataInfo.$emit("PlaylistIdIsBeingPlayed", {
          playlistId: null,
        });
      }

      // Is the user authenticated?
      if (!this.isAuthenticated) {
        this.$store.commit("storeNextUrl", this.$route.path);
        
        if (!this.locked && !this.chipin_plus) {
          this.setPlayAfterAuth({ shouldPlay: true, songToPlay: this.$props });
        }
        this.setPlayAfterAuth({ shouldPlay: true, songToPlay: this.$props });
        this.$bvModal.show("login-modal");
        return;
      }
      
      //  Check if song is locked + chipin_plus
      if (this.locked && this.chipin_plus) {
        if (this.indexed) {
          this.$parent.$bvModal.show("chipin-plus-modal-" + this.artist_id + "-" + this.indexed);
        } else {
          this.$parent.$bvModal.show(
            "chipin-plus-modal-" + this.artist_id
          );
        }
      } else {
        var data;
        var modal_id;

        if (this.loadedSong && this.id !== this.getLoadedSong.id) {
          this.pauseSong();

          data = this.$props;
          if (this.locked && this.chipin_plus) {
            if (this.indexed) {
              // play preview - on finish launch modal
              modal_id =
                "chipin-plus-modal-" + this.artist_id + "-" + this.indexed;
            } else {
              // play preview - on finish launch modal
              modal_id = "chipin-plus-modal-" + this.artist_id;
            }
            data["preview"] = true;
            data["modal_id"] = modal_id;
          } else {
            data["preview"] = false;
          }
          this.setSong(data);

          setTimeout(
            function() {
              //BIND
              this.playSong();
            }.bind(this),
            300
          );

          this.loadQueue();
        } else if (!this.loadedSong) {
          data = this.$props;
          if (this.locked && this.chipin_plus) {
            if (this.indexed) {
              // play preview - on finish launch modal
              modal_id =
                "chipin-plus-modal-" + this.artist_id + "-" + this.indexed;
            } else {
              // play preview - on finish launch modal
              modal_id = "chipin-plus-modal-" + this.artist_id;
            }
            data["preview"] = true;
            data["modal_id"] = modal_id;
          } else {
            data["preview"] = false;
          }
          this.setSong(data);

          setTimeout(
            function() {
              //BIND
              this.playSong();
            }.bind(this),
            300
          );

          this.loadQueue();
        } else {
          this.playSong();
        }
      }
    },
    pause: function() {
      if (!this.getLoadedSongData.radio) {
        this.pauseSong();
      }
    },
    loadQueue() {
      // Set the queue in the player store

      this.clearQueue();

      let selectedSongIsPassed = false;
      let inversedQueue = [];

      for (const song of this.queue) {
        if (song.id !== this.getLoadedSong) {
          if (!song.username) {
            song.username = this.username;
          }

          //flag to know if the song should be added on previous or next queues
          if (!selectedSongIsPassed && this.player_type !== "playlistasset") {
            inversedQueue.push(song);
          } else {
            this.addSong(song);
            song.indexed = ++this.indexed;
          }
        } else {
          selectedSongIsPassed = true;
        }
      }

      for (var i = inversedQueue.length - 1; i >= 0; i--) {
        let song = inversedQueue[i];
        this.addPreviousSong(song);
      }
    },
    changeProgressPlayerSize() {
      let progressBarElement = document.querySelector(
        ".c-song-progress-bar__" + this.componentUuid
      );

      if (!progressBarElement) {
        return;
      }

      let containerImageSong = progressBarElement.closest(
        ".c-container-image-song__" + this.componentUuid
      );

      this.playerDiameter = containerImageSong.clientHeight + 20;
    },
    playRadio() {
      this.$parent.playRadio()
      this.$bvModal.hide("radio-subscribe-modal-" + this.id + this.componentUuid);
    },
    Subscribe() {
      this.$parent.$bvModal.show('chipin-plus-modal-' + this.artist_id);
    },
  },
  computed: {
    ...mapMutations("Player", ["setCurrentPlaylist"]),
    ...mapGetters("Player", {
      paused: "isPaused",
      playing: "isPlaying",
      loadedSong: "loadedSong",
      getLoadedSong: "getLoadedSong",
      getLoadedSongData: "getLoadedSongData",
      getSongQueue: "getSongQueue",
      getCurrentPlaylist: "getCurrentPlaylist",
    }),
    ...mapGetters(["isAuthenticated"]),
  },
  watch: {
    getSongQueue() {
      
    },
  },
  mounted: function() {
    if (this.image) {
  
      if (this.player_type == 'playlist' || this.player_type == 'playlistasset') {
        this.player_image = this.getImagePath(this.image, {
          func: "crop",
          width: 150,
          height: 150,
        });

      } else {
        this.player_image = this.getImagePath(this.image, {
          func: "crop",
          width: 150,
          height: 150,
        });
      }
    }

    this.changeProgressPlayerSize();

    this.$nextTick(() => {
      window.addEventListener("resize", this.changeProgressPlayerSize);
    });

    const that = this;

    const songChanged = function(songData) {
      that.executed_time = songData.executed;
      that.total_time = songData.total_duration;
    };

    SongDataInfo.$on("songChanged__" + this.$props.id, songChanged);
  },
};
</script>

<style>
.c-play-icon--playlist {
  width: 7px;
}

.c-play-icon {
  width: 25px;
}

.play-icon {
  width: 31px;
  height: 42px;
}

.c-photo-player {
  align-items: flex-start;
}

.song-disk {
  position: relative;
}

.song-mini-artist-image {
  border: solid 3px white;
  border-radius: 50%;
  position: absolute;
  z-index: 998;
  top: 0px;
  width: 80px;
  height: 80px;
}

.song-mini-locked {
  border-radius: 50%;
  position: absolute;
  z-index: 998;
  top: 0px;
  right: 0px;
  width: 60px;
  height: 60px;
}

.playing {
  -webkit-animation: rotation 3s infinite linear;
  -webkit-animation-play-state: running;
}

.paused {
  -webkit-animation: rotation 3s infinite linear;
  -webkit-animation-play-state: paused;
}

.playlist_playing {
  -webkit-animation: rotation 3s infinite linear;
  -webkit-animation-play-state: running;
}

.playlist_paused {
  -webkit-animation: rotation 3s infinite linear;
  -webkit-animation-play-state: paused;
}

.played {
  -webkit-animation: rotation_back 1s linear;
}

.border-white {
  border: 3px #fff solid !important;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}

@-webkit-keyframes rotation_back {
  from {
    -webkit-transform: rotate(359deg);
  }
  to {
    -webkit-transform: rotate(0deg);
  }
}

.border-none {
  border: none !important;
}

.bg-none {
  background: transparent;
}

.test {
  width: 20px;
}

.song-disk {
  float: left;
}

/* Playlist - List */
.playlist-list div.play_wrapper {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 99%;
}

div.quarter {
  position: absolute;
  width: 50%;
  height: 50%;
  transition: transform 0.5s, opacity 0.5s;
}
div.play {
  position: absolute;
  width: 24px;
  height: 24px;
  left: 38px;
  top: 38px;
  z-index: 999;
}
div.quarter.left-top {
  left: 0;
  top: 0;
  border-top-left-radius: 99%;
}
div.quarter.right-top {
  left: 50%;
  top: 0;
  border-top-right-radius: 99%;
}
div.quarter.left-bottom {
  left: 0;
  top: 50%;
  border-bottom-left-radius: 99%;
}
div.quarter.right-bottom {
  left: 50%;
  top: 50%;
  border-bottom-right-radius: 99%;
}

.split_image {
  background-position: center !important;
  background-repeat: no-repeat;
}

.c-song-progress-bar {
  position: absolute !important;
}

.c-song-progress-bar svg {
  margin-top: -10px;
  margin-left: -20px;
}

/* Artist profile - most popular songs */
#most-popular-songs .c-container-image-song,
#most-popular-songs .c-photo-player,
#most-popular-songs .b-avatar .b-avatar-img img,
#most-popular-songs .other-image-mobile,
#most-popular-songs .other-image-mobile > span > img,
/* Artist profile - featured songs */
.c-featured-song .c-container-image-song,
.c-featured-song .c-photo-player,
.c-featured-song .b-avatar .b-avatar-img img,
.c-featured-song .other-image-mobile,
.c-featured-song .other-image-mobile > span > img,
/* Song profile - most listened songs */
#most-listened .c-container-image-song,
#most-listened .c-photo-player,
#most-listened .b-avatar .b-avatar-img img,
#most-listened .other-image-mobile,
#most-listened .other-image-mobile > span > img,
/* Library - Song List Asset */
.song-list .c-container-image-song,
.song-list .c-photo-player,
.song-list .b-avatar .b-avatar-img img,
.song-list .other-image-mobile,
.song-list .other-image-mobile > span > img,
/* Library - Song Asset */
.library-asset .c-container-image-song,
.library-asset .c-photo-player,
.library-asset .b-avatar .b-avatar-img img,
.library-asset .other-image-mobile,
.library-asset .other-image-mobile > span > img, 
/* Library - Playlist Asset */
.playlist-list .c-container-image-song,
.playlist-list .c-photo-player,
.playlist-list .b-avatar .b-avatar-img img,
.playlist-list .other-image-mobile,
.playlist-list .other-image-mobile > span > img, 
/* More songs button */
.c-container-song-more-btn .other-image-mobile {
  height: 150px;
  width: 150px;
}

/* Artist profile - most popular songs - play icon */
#most-popular-songs .c-container-image-song .play-icon,
#most-popular-songs .c-photo-player .play-icon,
#most-popular-songs .b-avatar .b-avatar-img img .play-icon,
#most-popular-songs .other-image-mobile .play-icon,
#most-popular-songs .other-image-mobile > span > img .play-icon,
/* Artist profile - featured songs - play icon */
.c-featured-song .c-container-image-song .play-icon,
.c-featured-song .c-photo-player .play-icon,
.c-featured-song .b-avatar .b-avatar-img img .play-icon,
.c-featured-song .other-image-mobile .play-icon,
.c-featured-song .other-image-mobile > span > img .play-icon,
/* Song profile - most listened songs - play icon */
#most-listened .c-container-image-song .play-icon,
#most-listened .c-photo-player .play-icon,
#most-listened .b-avatar .b-avatar-img img .play-icon,
#most-listened .other-image-mobile .play-icon,
#most-listened .other-image-mobile > span > img .play-icon,
/* Library - Song List Asset  - play icon */
.song-list .c-container-image-song .play-icon,
.song-list .c-photo-player .play-icon,
.song-list .b-avatar .b-avatar-img img .play-icon,
.song-list .other-image-mobile .play-icon,
.song-list .other-image-mobile > span > img .play-icon,
/* Library - Song Asset  - play icon */
.library-asset .c-container-image-song .play-icon,
.library-asset .c-photo-player .play-icon,
.library-asset .b-avatar .b-avatar-img img .play-icon,
.library-asset .other-image-mobile .play-icon,
.library-asset .other-image-mobile > span > img .play-icon {
  height: 36px;
  width: 25px;
}

#profile_player .b-avatar {
  border: solid 5px white;
}

/* Song profile - large song */
#profile_player .c-container-image-song,
#profile_player .c-photo-player,
#profile_player .b-avatar .b-avatar-img img,
#profile_player .other-image-mobile,
#profile_player .other-image-mobile > span > img {
  height: 250px;
  width: 250px;
}

#profile_player .c-container-image-song .play-icon,
#profile_player .c-photo-player .play-icon,
#profile_player .b-avatar .b-avatar-img img .play-icon,
#profile_player .other-image-mobile .play-icon,
#profile_player .other-image-mobile > span > img .play-icon {
  height: 60px;
  width: 40px;
}

/* Playlist - Song  */
.playlist .c-container-image-song,
.playlist .c-photo-player,
.playlist .b-avatar .b-avatar-img img,
.playlist .other-image-mobile,
.playlist .other-image-mobile > span > img {
  height: 50px;
  width: 50px;
}

/* Playlist - Song  - play icon */
.playlist .c-container-image-song .play-icon,
.playlist .c-photo-player .play-icon,
.playlist .b-avatar .b-avatar-img img .play-icon,
.playlist .other-image-mobile .play-icon,
.playlist .other-image-mobile > span > img .play-icon {
  height: 19px;
  width: 12px;
}

.playlist-play-icon {
  height: 19px;
  width: 12px;
}

@media (min-width: 575.98px) {
  /* Artist profile - most popular songs */
  #most-popular-songs .c-container-image-song,
#most-popular-songs .c-photo-player,
#most-popular-songs .b-avatar .b-avatar-img img,
#most-popular-songs .other-image-mobile,
#most-popular-songs .other-image-mobile > span > img,
/* Artist profile - featured songs */
.c-featured-song .c-container-image-song,
.c-featured-song .c-photo-player,
.c-featured-song .b-avatar .b-avatar-img img,
.c-featured-song .other-image-mobile,
.c-featured-song .other-image-mobile > span > img,
/* Song profile - most listened songs */
#most-listened .c-container-image-song,
#most-listened .c-photo-player,
#most-listened .b-avatar .b-avatar-img img,
#most-listened .other-image-mobile,
#most-listened .other-image-mobile > span > img,
/* Library - Song List Asset */
.song-list .c-container-image-song,
.song-list .c-photo-player,
.song-list .b-avatar .b-avatar-img img,
.song-list .other-image-mobile,
.song-list .other-image-mobile > span > img,
/* Library - Playlist Asset */
.playlist-list .c-container-image-song,
.playlist-list .c-photo-player,
.playlist-list .b-avatar .b-avatar-img img,
.playlist-list .other-image-mobile,
.playlist-list .other-image-mobile > span > img, 
/* Library - Song Asset */
.library-asset .c-container-image-song,
.library-asset .c-photo-player,
.library-asset .b-avatar .b-avatar-img img,
.library-asset .other-image-mobile,
.library-asset .other-image-mobile > span > img, 
  /* More songs button */
  .c-container-song-more-btn .other-image-mobile {
    height: 105px;
    width: 105px;
  }

  /* Artist profile - most popular songs - play icon */
  #most-popular-songs .c-container-image-song .play-icon,
  #most-popular-songs .c-photo-player .play-icon,
  #most-popular-songs .b-avatar .b-avatar-img img .play-icon,
  #most-popular-songs .other-image-mobile .play-icon,
  #most-popular-songs .other-image-mobile > span > img .play-icon,
  /* Artist profile - featured songs - play icon */
  .c-featured-song .c-container-image-song .play-icon,
  .c-featured-song .c-photo-player .play-icon,
  .c-featured-song .b-avatar .b-avatar-img img .play-icon,
  .c-featured-song .other-image-mobile .play-icon,
  .c-featured-song .other-image-mobile > span > img .play-icon,
  /* Song profile - most listened songs - play icon */
  #most-listened .c-container-image-song .play-icon,
  #most-listened .c-photo-player .play-icon,
  #most-listened .b-avatar .b-avatar-img img .play-icon,
  #most-listened .other-image-mobile .play-icon,
  #most-listened .other-image-mobile > span > img .play-icon,
  /* Library - Song List Asset  - play icon */
  .song-list .c-container-image-song .play-icon,
  .song-list .c-photo-player .play-icon,
  .song-list .b-avatar .b-avatar-img img .play-icon,
  .song-list .other-image-mobile .play-icon,
  .song-list .other-image-mobile > span > img .play-icon,
  /* Library - Song Asset  - play icon */
  .library-asset .c-container-image-song .play-icon,
  .library-asset .c-photo-player .play-icon,
  .library-asset .b-avatar .b-avatar-img img .play-icon,
  .library-asset .other-image-mobile .play-icon,
  .library-asset .other-image-mobile > span > img .play-icon,
  /* Library - Song Asset */
  .song-list .c-container-image-song .play-icon,
  .song-list .c-photo-player .play-icon,
  .song-list .b-avatar .b-avatar-img img .play-icon,
  .song-list .other-image-mobile .play-icon,
  .song-list .other-image-mobile > span > img .play-icon {
    height: 29px;
    width: 20px;
  }
}

@media (min-width: 768px) {
  /* Artist profile - most popular songs */
  #most-popular-songs .c-container-image-song,
  #most-popular-songs .c-photo-player,
  #most-popular-songs .b-avatar .b-avatar-img img,
  #most-popular-songs .other-image-mobile,
  #most-popular-songs .other-image-mobile > span > img,
  /* Artist profile - featured songs */
  .c-featured-song .c-container-image-song,
  .c-featured-song .c-photo-player,
  .c-featured-song .b-avatar .b-avatar-img img,
  .c-featured-song .other-image-mobile,
  .c-featured-song .other-image-mobile > span > img,
  /* Song profile - most listened songs */
  #most-listened .c-container-image-song,
  #most-listened .c-photo-player,
  #most-listened .b-avatar .b-avatar-img img,
  #most-listened .other-image-mobile,
  #most-listened .other-image-mobile > span > img,
  /* Library - Playlist Asset */
  .playlist-list .c-container-image-song,
  .playlist-list .c-photo-player,
  .playlist-list .b-avatar .b-avatar-img img,
  .playlist-list .other-image-mobile,
  .playlist-list .other-image-mobile > span > img, 
  /* Library - Song List Asset */
  .song-list .c-container-image-song,
  .song-list .c-photo-player,
  .song-list .b-avatar .b-avatar-img img,
  .song-list .other-image-mobile,
  .song-list .other-image-mobile > span > img,
  /* Library - Song Asset */
  .library-asset .c-container-image-song,
  .library-asset .c-photo-player,
  .library-asset .b-avatar .b-avatar-img img,
  .library-asset .other-image-mobile,
  .library-asset .other-image-mobile > span > img, 
  /* More songs button */
  .c-container-song-more-btn .other-image-mobile {
    height: 150px;
    width: 150px;
  }

  /* Artist profile - most popular songs - play icon */
  #most-popular-songs .c-container-image-song .play-icon,
  #most-popular-songs .c-photo-player .play-icon,
  #most-popular-songs .b-avatar .b-avatar-img img .play-icon,
  #most-popular-songs .other-image-mobile .play-icon,
  #most-popular-songs .other-image-mobile > span > img .play-icon,
  /* Artist profile - featured songs - play icon */
  .c-featured-song .c-container-image-song .play-icon,
  .c-featured-song .c-photo-player .play-icon,
  .c-featured-song .b-avatar .b-avatar-img img .play-icon,
  .c-featured-song .other-image-mobile .play-icon,
  .c-featured-song .other-image-mobile > span > img .play-icon,
  /* Song profile - most listened songs - play icon */
  #most-listened .c-container-image-song .play-icon,
  #most-listened .c-photo-player .play-icon,
  #most-listened .b-avatar .b-avatar-img img .play-icon,
  #most-listened .other-image-mobile .play-icon,
  #most-listened .other-image-mobile > span > img .play-icon,
  /* Library - Song List Asset  - play icon */
  .song-list .c-container-image-song .play-icon,
  .song-list .c-photo-player .play-icon,
  .song-list .b-avatar .b-avatar-img img .play-icon,
  .song-list .other-image-mobile .play-icon,
  .song-list .other-image-mobile > span > img .play-icon,
  /* Library - Song Asset  - play icon */
  .library-asset .c-container-image-song .play-icon,
  .library-asset .c-photo-player .play-icon,
  .library-asset .b-avatar .b-avatar-img img .play-icon,
  .library-asset .other-image-mobile .play-icon,
  .library-asset .other-image-mobile > span > img .play-icon,
  /* Library - Song Asset */
  .song-list .c-container-image-song .play-icon,
  .song-list .c-photo-player .play-icon,
  .song-list .b-avatar .b-avatar-img img .play-icon,
  .song-list .other-image-mobile .play-icon,
  .song-list .other-image-mobile > span > img .play-icon {
    width: 25px;
    height: 36px;
  }
}

/*  Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .song-mini-artist-image {
    border: solid 3px white;
    border-radius: 50%;
    position: absolute;
    z-index: 1000;
    top: 0px;
    width: 80px;
    height: 80px;
  }

  .song-mini-locked {
    top: 5px;
    right: 15px;
  }
}

/*  Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .song-mini-artist-image {
    border: solid 3px white;
    border-radius: 50%;
    position: absolute;
    z-index: 1000;
    top: 0px;
    width: 80px;
    height: 80px;
  }
}

@media (min-width: 992px) and (max-width: 1999.98px) {
}

@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (min-width: 576px) and (max-width: 767.98px) {
}

@media (max-width: 991.98px) {
  /* Song profile - large song */
  #profile_player .c-container-image-song,
  #profile_player .c-photo-player,
  #profile_player .b-avatar .b-avatar-img img,
  #profile_player .other-image-mobile,
  #profile_player .other-image-mobile > span > img {
    height: 200px;
    width: 200px;
  }

  #profile_player .c-container-image-song .play-icon,
  #profile_player .c-photo-player .play-icon,
  #profile_player .b-avatar .b-avatar-img img .play-icon,
  #profile_player .other-image-mobile .play-icon,
  #profile_player .other-image-mobile > span > img .play-icon {
    height: 50px;
    width: 34px;
  }

  .playlist_name {
    font-size: 24px;
    line-height: 34px;
    font-weight: 400;
  }

  .song-mini-artist-image {
    border: solid 3px white;
    border-radius: 50%;
    position: absolute;
    z-index: 1000;
    top: 0px;
    width: 80px;
    height: 80px;
  }
}

@media (max-width: 768px) {
  /* Song profile - large song 
  #profile_player .c-container-image-song,
  #profile_player .c-photo-player,
  #profile_player .b-avatar .b-avatar-img img,
  #profile_player .other-image-mobile,
  #profile_player .other-image-mobile > span > img */
  /* Library - Playlist Asset */
  .playlist-list .c-container-image-song,
  .playlist-list .c-photo-player,
  .playlist-list .b-avatar .b-avatar-img img,
  .playlist-list .other-image-mobile,
  .playlist-list .other-image-mobile > span > img {
    height: 160px;
    width: 160px;
  }

  #profile_player .c-container-image-song .play-icon,
  #profile_player .c-photo-player .play-icon,
  #profile_player .b-avatar .b-avatar-img img .play-icon,
  #profile_player .other-image-mobile .play-icon,
  #profile_player .other-image-mobile > span > img .play-icon {
    height: 50px;
    width: 34px;
  }

  .playlist-list .play-icon {
    height: 29px;
    width: 20px;
  }

  .playing-icon {
    width: 20px !important;
  }

  /* Library - Playlist Asset */
  .playlist-list .c-photo-player {
    height: 105px;
    width: 105px;
  }

  /* Playlist - List */
  .playlist-list div.play_wrapper {
    position: relative;
    height: 105px;
    width: 105px;
    border-radius: 99%;
  }

  .song-mini-artist-image {
    border: solid 3px white;
    border-radius: 50%;
    position: absolute;
    z-index: 1000;
    top: 0px;
    width: 60px;
    height: 60px;
  }

  .song-mini-locked {
    width: 50px;
    height: 50px;
  }
}

@media (max-width: 575.98px) {
  #profile_player .song-mini-artist-image {
    width: 50px;
    height: 50px;
  }

  /* Song profile - large song */
  #profile_player .c-container-image-song,
  #profile_player .c-photo-player,
  #profile_player .b-avatar .b-avatar-img img,
  #profile_player .other-image-mobile,
  #profile_player .other-image-mobile > span > img {
    height: 150px;
    width: 150px;
  }

  #profile_player .c-container-image-song .play-icon,
  #profile_player .c-photo-player .play-icon,
  #profile_player .b-avatar .b-avatar-img img .play-icon,
  #profile_player .other-image-mobile .play-icon,
  #profile_player .other-image-mobile > span > img .play-icon {
    height: 50px;
    width: 34px;
  }

  /* Artist profile - most popular songs */
  #most-popular-songs .c-container-image-song,
  #most-popular-songs .c-photo-player,
  #most-popular-songs .b-avatar .b-avatar-img img,
  #most-popular-songs .other-image-mobile,
  #most-popular-songs .other-image-mobile > span > img,
  /* Artist profile - featured songs */
  .c-featured-song .c-container-image-song,
  .c-featured-song .c-photo-player,
  .c-featured-song .b-avatar .b-avatar-img img,
  .c-featured-song .other-image-mobile,
  .c-featured-song .other-image-mobile > span > img,
  /* Library - Song List Asset */
  .song-list .c-container-image-song,
  .song-list .c-photo-player,
  .song-list .b-avatar .b-avatar-img img,
  .song-list .other-image-mobile,
  .song-list .other-image-mobile > span > img,
  /* Library - Playlist Asset */
  .playlist-list .c-container-image-song,
  .playlist-list .c-photo-player,
  .playlist-list .b-avatar .b-avatar-img img,
  .playlist-list .other-image-mobile,
  .playlist-list .other-image-mobile > span > img, 
  /* Library - Song Asset */
  .library-asset .c-container-image-song,
  .library-asset .c-photo-player,
  .library-asset .b-avatar .b-avatar-img img,
  .library-asset .other-image-mobile,
  .library-asset .other-image-mobile > span > img, 
  /* More songs button */
  .c-container-song-more-btn .other-image-mobile {
    height: 80px;
    width: 80px;
  }

  /* Playlist - List */
  .playlist-list div.play_wrapper {
    position: relative;
    height: 80px;
    width: 80px;
    border-radius: 99%;
  }

  /* Artist profile - most popular songs - play icon */
  #most-popular-songs .c-container-image-song .play-icon,
  #most-popular-songs .c-photo-player .play-icon,
  #most-popular-songs .b-avatar .b-avatar-img img .play-icon,
  #most-popular-songs .other-image-mobile .play-icon,
  #most-popular-songs .other-image-mobile > span > img .play-icon,
  /* Artist profile - featured songs - play icon */
  .c-featured-song .c-container-image-song .play-icon,
  .c-featured-song .c-photo-player .play-icon,
  .c-featured-song .b-avatar .b-avatar-img img .play-icon,
  .c-featured-song .other-image-mobile .play-icon,
  .c-featured-song .other-image-mobile > span > img .play-icon,
  /* Library - Song List Asset  - play icon */
  .song-list .c-container-image-song .play-icon,
  .song-list .c-photo-player .play-icon,
  .song-list .b-avatar .b-avatar-img img .play-icon,
  .song-list .other-image-mobile .play-icon,
  .song-list .other-image-mobile > span > img .play-icon,
  /* Library - Song Asset  - play icon */
  .library-asset .c-container-image-song .play-icon,
  .library-asset .c-photo-player .play-icon,
  .library-asset .b-avatar .b-avatar-img img .play-icon,
  .library-asset .other-image-mobile .play-icon,
  .library-asset .other-image-mobile > span > img .play-icon {
    height: 17px;
    width: 18px;
  }

  .playing-icon {
    width: 18px !important;
  }

  .playlist-list .play-icon {
    height: 19px;
    width: 13px;
  }

  #profile_player .song-mini-locked {
    height: 40px;
    width: 40px;
  }
}

@media (max-width: 414px) {
  .song-mini-locked {
    width: 30px !important;
    height: 30px !important;
  }

  /* Song profile - large song */
  #profile_player .c-container-image-song,
  #profile_player .c-photo-player,
  #profile_player .b-avatar .b-avatar-img img,
  #profile_player .other-image-mobile,
  #profile_player .other-image-mobile > span > img {
    height: 140px;
    width: 140px;
  }

  #profile_player .c-container-image-song .play-icon,
  #profile_player .c-photo-player .play-icon,
  #profile_player .b-avatar .b-avatar-img img .play-icon,
  #profile_player .other-image-mobile .play-icon,
  #profile_player .other-image-mobile > span > img .play-icon {
    height: 35px;
    width: 24px;
  }

  /* Artist profile - most popular songs */
  #most-popular-songs .c-container-image-song,
  #most-popular-songs .c-photo-player,
  #most-popular-songs .b-avatar .b-avatar-img img,
  #most-popular-songs .other-image-mobile,
  #most-popular-songs .other-image-mobile > span > img,
  /* Artist profile - featured songs */
  .c-featured-song .c-container-image-song,
  .c-featured-song .c-photo-player,
  .c-featured-song .b-avatar .b-avatar-img img,
  .c-featured-song .other-image-mobile,
  .c-featured-song .other-image-mobile > span > img, 
  /* More songs button */
  .c-container-song-more-btn .other-image-mobile {
    height: 95px;
    width: 95px;
  }

  /* Playlist - List */
  .playlist-list div.play_wrapper {
    position: relative;
    height: 80px;
    width: 80px;
    border-radius: 99%;
  }

  /* Artist profile - most popular songs - play icon */
  #most-popular-songs .c-container-image-song .play-icon,
  #most-popular-songs .c-photo-player .play-icon,
  #most-popular-songs .b-avatar .b-avatar-img img .play-icon,
  #most-popular-songs .other-image-mobile .play-icon,
  #most-popular-songs .other-image-mobile > span > img .play-icon,
  /* Artist profile - featured songs - play icon */
  .c-featured-song .c-container-image-song .play-icon,
  .c-featured-song .c-photo-player .play-icon,
  .c-featured-song .b-avatar .b-avatar-img img .play-icon,
  .c-featured-song .other-image-mobile .play-icon,
  .c-featured-song .other-image-mobile > span > img .play-icon {
    height: 19px;
    width: 13px;
  }

  /* Song profile - most listened songs */
  #most-listened .c-container-image-song,
  #most-listened .c-photo-player,
  #most-listened .b-avatar .b-avatar-img img,
  #most-listened .other-image-mobile,
  #most-listened .other-image-mobile > span > img
  /* Library - Song List Asset */
  .song-list .c-container-image-song,
  .song-list .c-photo-player,
  .song-list .b-avatar .b-avatar-img img,
  .song-list .other-image-mobile,
  .song-list .other-image-mobile > span > img,
  /* Library - Playlist Asset */
  .playlist-list .c-container-image-song,
  .playlist-list .c-photo-player,
  .playlist-list .b-avatar .b-avatar-img img,
  .playlist-list .other-image-mobile,
  .playlist-list .other-image-mobile > span > img, 
  /* Library - Song Asset */
  .library-asset .c-container-image-song,
  .library-asset .c-photo-player,
  .library-asset .b-avatar .b-avatar-img img,
  .library-asset .other-image-mobile,
  .library-asset .other-image-mobile > span > img {
    height: 80px;
    width: 80px;
  }

  /* Song profile - most listened songs - play icon */
  #most-listened .c-container-image-song .play-icon,
  #most-listened .c-photo-player .play-icon,
  #most-listened .b-avatar .b-avatar-img img .play-icon,
  #most-listened .other-image-mobile .play-icon,
  #most-listened .other-image-mobile > span > img .play-icon
  /* Library - Song List Asset  - play icon */
  .song-list .c-container-image-song .play-icon,
  .song-list .c-photo-player .play-icon,
  .song-list .b-avatar .b-avatar-img img .play-icon,
  .song-list .other-image-mobile .play-icon,
  .song-list .other-image-mobile > span > img .play-icon,
  /* Library - Song Asset  - play icon */
  .library-asset .c-container-image-song .play-icon,
  .library-asset .c-photo-player .play-icon,
  .library-asset .b-avatar .b-avatar-img img .play-icon,
  .library-asset .other-image-mobile .play-icon,
  .library-asset .other-image-mobile > span > img .play-icon {
    height: 19px;
    width: 13px;
  }

  .playing-icon {
    width: 15px !important;
  }
}

@media (max-width: 375px) {
  /* Artist profile - most popular songs */
  #most-popular-songs .c-container-image-song,
  #most-popular-songs .c-photo-player,
  #most-popular-songs .b-avatar .b-avatar-img img,
  #most-popular-songs .other-image-mobile,
  #most-popular-songs .other-image-mobile > span > img,
  /* Artist profile - featured songs */
  .c-featured-song .c-container-image-song,
  .c-featured-song .c-photo-player,
  .c-featured-song .b-avatar .b-avatar-img img,
  .c-featured-song .other-image-mobile,
  .c-featured-song .other-image-mobile > span > img, 
  /* More songs button */
  .c-container-song-more-btn .other-image-mobile {
    height: 80px;
    width: 80px;
  }

  .playing-icon {
    width: 15px !important;
  }
  .song-mini-locked {
    width: 30px !important;
    height: 30px !important;
    top: 5px;
    right: 5px;
  }
}

@media (max-width: 320px) {
  /* Artist profile - most popular songs */
  #most-popular-songs .c-container-image-song,
  #most-popular-songs .c-photo-player,
  #most-popular-songs .b-avatar .b-avatar-img img,
  #most-popular-songs .other-image-mobile,
  #most-popular-songs .other-image-mobile > span > img,
  /* Artist profile - featured songs */
  .c-featured-song .c-container-image-song,
  .c-featured-song .c-photo-player,
  .c-featured-song .b-avatar .b-avatar-img img,
  .c-featured-song .other-image-mobile,
  .c-featured-song .other-image-mobile > span > img, 
  /* More songs button */
  .c-container-song-more-btn .other-image-mobile {
    height: 65px;
    width: 65px;
  }

  /* Playlist - List */
  .playlist-list div.play_wrapper {
    position: relative;
    height: 65px;
    width: 65px;
    border-radius: 99%;
  }

  /* Song profile - large song */
  #profile_player .c-container-image-song,
  #profile_player .c-photo-player,
  #profile_player .b-avatar .b-avatar-img img,
  #profile_player .other-image-mobile,
  #profile_player .other-image-mobile > span > img {
    height: 130px;
    width: 130px;
  }

  /* Song profile - most listened songs */
  #most-listened .c-container-image-song,
  #most-listened .c-photo-player,
  #most-listened .b-avatar .b-avatar-img img,
  #most-listened .other-image-mobile,
  #most-listened .other-image-mobile > span > img
  /* Library - Song List Asset */
  .song-list .c-container-image-song,
  .song-list .c-photo-player,
  .song-list .b-avatar .b-avatar-img img,
  .song-list .other-image-mobile,
  .song-list .other-image-mobile > span > img {
    height: 75px;
    width: 75px;
  }
  /* Library - Song Asset */
  .library-asset .c-container-image-song,
  .library-asset .c-photo-player,
  .library-asset .b-avatar .b-avatar-img img,
  .library-asset .other-image-mobile,
  .library-asset .other-image-mobile > span > img {
    height: 55px;
    width: 55px;
  }

  /* Song profile - most listened songs - play icon */
  #most-listened .c-container-image-song .play-icon,
  #most-listened .c-photo-player .play-icon,
  #most-listened .b-avatar .b-avatar-img img .play-icon,
  #most-listened .other-image-mobile .play-icon,
  #most-listened .other-image-mobile > span > img .play-icon
  /* Library - Song List Asset  - play icon */
  .song-list .c-container-image-song .play-icon,
  .song-list .c-photo-player .play-icon,
  .song-list .b-avatar .b-avatar-img img .play-icon,
  .song-list .other-image-mobile .play-icon,
  .song-list .other-image-mobile > span > img .play-icon,
  /* Library - Song Asset  - play icon */
  .library-asset .c-container-image-song .play-icon,
  .library-asset .c-photo-player .play-icon,
  .library-asset .b-avatar .b-avatar-img img .play-icon,
  .library-asset .other-image-mobile .play-icon,
  .library-asset .other-image-mobile > span > img .play-icon {
    height: 19px;
    width: 13px;
  }

  .playlist-list .play-icon {
    height: 19px;
    width: 13px;
  }

  .playing-icon {
    width: 12.5px !important;
  }
}
</style>
