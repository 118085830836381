var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (this.$route.name !== 'LibraryArea' && _vm.display_header)?_c('div',[_c('b-navbar',{staticClass:"fixed-top",class:_vm.nav_class,attrs:{"toggleable":"lg","fixed":"top","type":"dark"}},[_c('b-container',{staticClass:"nav-container",attrs:{"fluid":""},on:{"mouseover":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false}}},[_c('b-col',{staticClass:"text-center"},[_c('b-navbar-brand',[(this.$route.name !== 'LibraryArea')?_c('img',{class:{
              'logo-normal': !_vm.isAuthenticated && _vm.navbar.open,
              'logo-reduce-unath': !_vm.isAuthenticated && _vm.navbar.collapse,
            },attrs:{"src":require("../assets/trackd-icon-white.svg"),"alt":"Logo","height":"25"}}):_vm._e(),_c('h5',{directives:[{name:"show",rawName:"v-show",value:(_vm.isAuthenticated),expression:"isAuthenticated"}],staticClass:"font-weight-lighter mt-3",class:{
              'show-welcome': _vm.navbar.open,
              'hide-welcome': _vm.navbar.collapse && this.$route.name === 'Home',
            }},[_vm._v(" Welcome, "+_vm._s(_vm.username)+" ")])]),(_vm.$route.name == 'LibraryArea')?_c('b-row',[(_vm.$route.name == 'LibraryArea')?_c('b-col',{staticClass:"text-left ml-0 pl-0 pb-0",attrs:{"cols":"5"}},[(_vm.$route.name == 'LibraryArea')?_c('b-button',{staticClass:"inline-block",attrs:{"variant":"link","to":{ name: 'Home' }}},[_c('img',{staticClass:"float-left",attrs:{"src":require("../assets/menu-icon.svg"),"height":"20","alt":"Logo"}})]):_vm._e()],1):_vm._e()],1):_vm._e(),(_vm.$route.name == 'Home')?_c('b-row',{class:{
            'buttons-normal-unauth': !_vm.isAuthenticated && _vm.navbar.open,
            'buttons-normal': _vm.navbar.open,
            'buttons-reduce-unauth': !_vm.isAuthenticated && _vm.navbar.collapse,
            'buttons-reduce': _vm.navbar.collapse && this.$route.name === 'Home',
          }},[(_vm.$route.name == 'Home')?_c('b-col',{staticClass:"text-left pl-0",attrs:{"cols":"5"}},[(_vm.$route.name == 'Home')?_c('b-button',{staticClass:"inline-block",attrs:{"variant":"link","to":{
                name: 'LibraryArea',
                params: { area: 'discover', name: 'Library' },
              }}},[_c('img',{staticClass:"align-top float-right",attrs:{"src":require("../assets/menu-icon.svg"),"height":"20","alt":"Logo"}})]):_vm._e()],1):_vm._e(),_c('b-col',{staticClass:"text-center",attrs:{"cols":"2"}}),_c('b-col',{staticClass:"text-right pr-0",attrs:{"cols":"5"}},[(_vm.$route.name == 'Home' && _vm.isAuthenticated)?_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.filter-modal",modifiers:{"filter-modal":true}}],attrs:{"variant":"link"}},[_c('img',{attrs:{"src":require("../assets/filter-icon.svg"),"height":"20","alt":"Filter"}})]):_vm._e(),(_vm.$route.name == 'Home')?_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.menu-sidebar",modifiers:{"menu-sidebar":true}}],attrs:{"variant":"link"}},[_c('img',{staticClass:"align-top",attrs:{"src":require("../assets/hamburger-icon.svg"),"height":"20","alt":"Menu"}})]):_vm._e()],1)],1):_vm._e()],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }