<template>
  <div>
    <!-- MODAL -->

    <b-modal
      id="chipin-creator-modal"
      ref="chipin-creator-modal"
      size="lg"
      content-class="bg-dark text-light  "
      centered
      hide-footer
      header-close-variant="light"
    >
      <template #modal-header="{ close }">
        <b-container fluid>
          <b-row>
            <b-col class="float-left">
              <img
                src="../../../assets/trackd-icon-colours.svg"
                class="mb-4 mx-auto text-left mt-2"
                height="25"
                alt="Logo"
              />
            </b-col>

            <b-col class="float-right">
              <b-button
                size="sm"
                variant="link"
                class="float-right"
                @click="close()"
              >
                <img
                  src="../../../assets/section-close.svg"
                  class="mx-auto text-right float-right mt-2"
                  height="20"
                  alt="Close"
                />
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </template>

      <b-row>
        <b-col class="mb-3" cols="10" offset="1" sm="8" offset-sm="2">
          <b-row class="mb-3">
            <b-col class="p-1">
              <b-badge pill variant="info" class="w-100 text-info text-small"
                >&nbsp;a</b-badge
              >
            </b-col>
            <b-col class="p-1">
              <b-badge
                pill
                variant="warning"
                class="w-100 text-warning text-small"
                >&nbsp;b</b-badge
              >
            </b-col>
            <b-col class="p-1">
              <b-badge
                pill
                variant="secondary"
                class="w-100 text-secondary text-small"
                >&nbsp;c</b-badge
              >
            </b-col>
          </b-row>

          <div class="row m-0 pb-3 p-0">
            <div style="width: 100%">
              <b-row>
                <div>
                  <b-img
                    v-if="this.user_image"
                    rounded="circle"
                    :src="getImagePath(this.user_image, { width: 100, height: 100 })"
                    blank-color="#ccc"
                    alt="placeholder"
                    height="100"
                    width="100"
                    class="light-grey-border"
                  ></b-img>
                  <b-img
                    v-else
                    rounded="circle"
                    blank
                    blank-color="#ccc"
                    alt="placeholder"
                    height="100"
                    width="100"
                    class="light-grey-border"
                  ></b-img>
                </div>

                <b-col col class="song-profile-des">
                  <p class="font-weight-light mb-1 mt-2">Make Money<br/> from your Fans with your Music</p>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
      </b-row>

    <b-container fluid>
        <div id="chipin-form">
            <h4 class="text-center text-light mb-0 pb-0">
                <img src="../../../assets/chipin-plus-text.svg" height="25" class="mb-1"/>
                <span style="font-size:24px;"> Features</span>
            </h4>

            

            <b-row class="mt-4 pl-5 pr-5">

                <template v-if="!chipin_slide">
                    <b-col cols="12" class="px-5">
                        <b-row>
                        <b-col class="included-info" cols="2">
                            <img src="../../../assets/tick.svg" alt="" />
                        </b-col>
                        <b-col class="pl-0">
                            <p class="font-weight-light mb-1 mt-1 subscription pl-0 pr-2">
                            Charge users a monthly subscription to play your music and unlock content
                            </p>
                        </b-col>
                        </b-row>
                        <b-row class="mt-3">
                        <b-col class="included-info" cols="2">
                            <img src="../../../assets/tick.svg" alt="" />
                        </b-col>
                        <b-col class="px-0">
                            <p class="font-weight-light mb-1 mt-1 subscription">
                            Artists are able to set the monthly subscription amount payable by users
                            </p>
                        </b-col>
                        </b-row>
                        <b-row class="mt-3 mb-4">
                        <b-col class="included-info" cols="2">
                            <img src="../../../assets/tick.svg" alt="" />
                        </b-col>
                        <b-col class="pl-0">
                            <p class="font-weight-light mb-1 mt-1 subscription px-0">
                            Artist retain 85% of monthly subscription revenue
                            </p>
                        </b-col>
                        </b-row>
                        <b-row class="mt-3 mb-4">
                        <b-col class="included-info" cols="2">
                            <img src="../../../assets/tick.svg" alt="" />
                        </b-col>
                        <b-col class="px-0">
                            <p class="font-weight-light mb-1 mt-1 subscription">
                            Build a valuable monthly income stream
                            </p>
                        </b-col>
                        </b-row>
                        <b-row class="mt-3 mb-4">
                        <b-col class="included-info" cols="2">
                            <img src="../../../assets/tick.svg" alt="" />
                        </b-col>
                        <b-col class="px-0">
                            <p class="font-weight-light mb-1 mt-1 subscription px-0">
                            Designed for Semi or Professional Artists
                            </p>
                        </b-col>
                        </b-row>
                    </b-col>
                </template>

                <template v-else>
                    <b-col cols="10">
                        <b-row>
                        <b-col class="included-info" cols="2">
                            <img src="../../../assets/tick.svg" alt="" />
                        </b-col>
                        <b-col>
                            <p class="font-weight-light mb-1 mt-1 subscription">
                            Allows users to tip you for your content
                            </p>
                        </b-col>
                        </b-row>
                        <b-row class="mt-3">
                        <b-col class="included-info" cols="2">
                            <img src="../../../assets/tick.svg" alt="" />
                        </b-col>
                        <b-col>
                            <p class="font-weight-light mb-1 mt-1 subscription">
                            Artists retain 95% of tip revenue
                            </p>
                        </b-col>
                        </b-row>
                        <b-row class="mt-3 mb-4">
                        <b-col class="included-info" cols="2">
                            <img src="../../../assets/tick.svg" alt="" />
                        </b-col>
                        <b-col>
                            <p class="font-weight-light mb-1 mt-1 subscription">
                            Prefect for Grass Roots, Charities, Education 
                            </p>
                        </b-col>
                        </b-row>
                        <b-row class="mt-3 mb-4">
                        <b-col class="included-info" cols="2">
                            <img src="../../../assets/tick.svg" alt="" />
                        </b-col>
                        <b-col>
                            <p class="font-weight-light mb-1 mt-1 subscription">
                            User option for tips to be recurring tips (monthly subscription)
                            </p>
                        </b-col>
                        </b-row>
                        <b-row class="mt-3 mb-4">
                        <b-col class="included-info" cols="2">
                            <img src="../../../assets/cross.svg" alt="" />
                        </b-col>
                        <b-col>
                            <p class="font-weight-light mb-1 mt-1 subscription">
                            Tips are optional. Content cannot be locked
                            </p>
                        </b-col>
                        </b-row>
                    </b-col>
                </template>

            </b-row>        
        </div>

    </b-container>

    <b-container fluid>
      <b-row class="pb-5">
          <b-col class="text-center py-5" cols="12">
              <b-button
                v-b-modal.chipin-creator-getstarted-modal
                @click="$bvModal.hide('chipin-creator-modal')"
                class="px-4 py-2 rounded-btn select-btn"
                size="md"
                variant="info"
              >
                Select ChipIn+
              </b-button>
          </b-col>
      </b-row>
    </b-container>

    </b-modal>
  </div>
</template>


<script>
import { mapGetters } from 'vuex';


export default {
  
  name: "ChipInCreator",
  data() {
    return {
        chipin_slide: false,
        pages: [

        ]
    };
  },
  methods: {
    
  },
  computed: {
    ...mapGetters(['user_image'])
    
  },
  mounted: function () {
    
  },
};
</script>

<style scoped>
#chipin-form {
  width: 100%;
  margin-top: 10px;
}

.modal-content {
  width: auto !important;
  margin: auto;
}

#chipin-form > h1 {
  font-weight: 900 !important;
  font-size: 36px;
  line-height: 21px;
  letter-spacing: 0.152972px;
}

#chipin-form > p {
  font-weight: 300 !important;
  font-size: 12px;
  line-height: 21px;
  letter-spacing: 0.152972px;
}

#chipin-form > h4 {
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.152972px;
}

.light-grey-border {
  border: 2px solid #9b9b9b;
}

.red-text {
  color: #ff0000;
}

.dark-text {
  color: #595959;
}

.super-small {
  font-size: 0.7em;
  color: #595959;
}

.rounded-btn {
  border-radius: 10px;
}

.line-height {
  line-height: 1.45em;
}

.included-info {
  display: inline-flex;
  align-items: center;
  float: left;
}

.song-profile-des > p {
  font-weight: 200 !important;
  font-size: 34px;
  line-height: 36px;
}

.song-profile-des > h5 {
  font-weight: 500 !important;
  font-size: 34px;
  line-height: 36px;
}

.song-profile-des > span {
  font-weight: 300 !important;
  font-size: 18px;
  line-height: 24px;
  display: block;
  margin-top: 20px;
}

.months-btn {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 10px;
}

.months-btn > button {
  border-radius: 7px;
  font-family: "SFPro";
}

.month-one {
  border-top-right-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
  background: white;
  color: black;
  border: 0.5px solid rgba(0, 0, 0, 0.04);
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04);
  width: 50%;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
  margin-right: -5px;
  z-index: 2;
}

.month-twelve {
  background: black;
  color: white;
  background: rgba(118, 118, 128, 0.12);
  border: 1px solid #ffffff;
  border-left: none;
  width: 50%;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
}

.subscription {
  font-weight: 300 !important;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.152972px;
}

.select-btn {
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
  width: 236px;
  height: 40px;
}

.show-btn {
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
  width: 236px;
  height: 40px;
  border: 1px solid #00AFDC;
  background-color: transparent;
}

.choose-text {
    font-size: 18px;
    font-weight: 700;
}

</style>
