<template>
  
  <div class="text-dark text-left mt-4" @click="updateSelected">

        <div class="rounded-circle shadow circle-icon d-inline-block button-border"
            :class="{
                'bg-dark': $parent.selected !== 'discover',
                'bg-light': $parent.selected === 'discover', 
                'text-light': $parent.selected !== 'discover',
                'text-dark': $parent.selected === 'discover'
            }">
            <img v-if="$parent.selected !== 'discover'" src="../../../assets/onboarding/headphones.svg" class="headphones mt-2"/>
            <img v-else src="../../../assets/onboarding/headphones-dark.svg" class="headphones mt-2"/>
        </div>

        <div class="shadow fan-button-body button-border text-light text-left pl-3"
            :class="{
                'bg-dark': $parent.selected !== 'discover',
                'bg-light': $parent.selected === 'discover', 
                'text-light': $parent.selected !== 'discover',
                'text-dark': $parent.selected === 'discover'
            }"
        >
            <p class="mb-0 button-text">
                <b>Fan!</b> <span class="font-weight-lighter">Just show me the music</span>
            </p>
        </div>

    </div>


</template>

<script>
export default {
    name: "FanAccount",
    methods: {
        updateSelected() {
            this.$emit('onUpdateSelection', 'discover');
        },
    },
}
</script>

<style scoped>

.circle-icon {
    width: 40px;
    height: 40px;
    transform: translate(25%, -10%);
}

.circle-icon:hover {
    cursor: pointer;
}

.fan-button-body {
    width: 264px;
    height: 50px;
    align-items: center;
    display: inline-grid;
}

.fan-button-body:hover {
    cursor: pointer;
}

.button-border {
    border: 1px solid white;
    border-radius: 15px;
}

.headphones {
    width: 24px;
    height: 22px;
    margin-left: 7px;
}

.button-text {
    font-size: 18px;
}


</style>