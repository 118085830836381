<template>
  <b-modal
    id="gender-onboarding-modal"
    :size="getModalSize"
    centered
    hide-footer
    title-sr-only
    no-close-on-backdrop
    no-close-on-esc
    content-class="bg-dark text-light text-center"
    header-close-variant="light"
    title="Onboarding Gender"
    modal-class="onboarding-modal"
    header-class="pb-0"
    body-class="pt-0"
  >
    <template #modal-header="{ close }">
      <b-container fluid>
        <b-row class="text-center">

          <b-col >
          </b-col>

          <b-col cols="8">
            <img
              src="../../assets/trackd-icon-colours.svg"
              class="mb-4 mx-auto text-center mt-2"
              height="35"
              alt="Logo"
            />
          </b-col>

          <b-col class="float-right">
            <b-button
              size="sm"
              variant="link"
              class="float-right"
              @click="close()"
            >
              <img
                src="../../assets/section-close.svg"
                class="mx-auto text-right float-right mt-2"
                height="23"
                alt="Close"
              />
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </template>

    <b-row>
      <b-col cols="10" offset="1" sm="8" offset-sm="2" md="8" offset-md="2">
        <b-row class="mb-4">
          <b-col class="p-1">
            <b-badge pill variant="info" class="w-100 text-info text-small"
              >&nbsp;a</b-badge
            >
          </b-col>
          <b-col class="p-1">
            <b-badge pill variant="warning" class="w-100 text-warning text-small"
              >&nbsp;b</b-badge
            >
          </b-col>
          <b-col class="p-1">
            <b-badge pill variant="secondary" class="w-100 text-secondary text-small"
              >&nbsp;c</b-badge
            >
          </b-col>

          
        </b-row>

        <h2 class="text-left text-light text-title title">Hey Girls! Hey Boys!</h2>
        <h2 class="text-left text-light text-des" style="">
          Superstar DJ’s...<br/>Here we go...
        </h2>

        <b-card class="my-2 pb-2 shadow-sm bg-dark text-left" no-body>
          <b-card-body>
            <p class="mt-4 text-light text-fdes-sec text-left">
              Please choose your sex
            </p>

            <div class="row text-center mx-sm-0 gender_list">
              <div class="col pl-0 pl-md-2" style="">
                <img
                  v-if="form.gender === 'female'"
                  src="../../assets/person-selected.svg"
                  alt="Discover Icon"
                  class=""
                  @click="Gender('')"
                />
                <img
                  v-else
                  src="../../assets/person.svg"
                  alt="Discover Icon"
                  class=""
                  @click="Gender('female')"
                />
                <p class="text-light text-sub">Female</p>
              </div>
              <div class="col" style=";min-width:150px;">
                <img
                  v-if="form.gender === 'unknown'"
                  src="../../assets/person-selected.svg"
                  alt="Discover Icon"
                  class=""
                  @click="Gender('')"
                />
                <img
                  v-else
                  src="../../assets/person-unknown.svg"
                  alt="Discover Icon"
                  class=""
                  @click="Gender('unknown')"
                />
                <p class="text-light text-sub">Prefer not to say</p>
              </div>
              <div class="col pr-0 pr-md-2" style="">
                <img
                  v-if="form.gender === 'male'"
                  src="../../assets/person-selected.svg"
                  alt="Discover Icon"
                  class=""
                  @click="Gender('')"
                />
                <img
                  v-else
                  src="../../assets/person.svg"
                  alt="Discover Icon"
                  class=""
                  @click="Gender('male')"
                />
                <p class="text-light text-sub">Male</p>
              </div>
              <div class="col-12">
                <b-form-invalid-feedback
                  v-if="!api_error & !form.gender"
                  id="gender-feedback"
                  >This is a required field.</b-form-invalid-feedback
                >
              </div>
            </div>

            <p class="mt-3 text-light text-birth text-left">
              Finally, please type your <span class="fw-500">birthdate</span>
            </p>

            <b-form @submit.stop.prevent="onSubmit">
              <b-form-group
                v-if="!submission_success"
                id="input-group-username"
                label=""
                label-for="username"
                class=""
              >
                <b-form-input
                  size="lg"
                  class="bg-dark text-light input-box"
                  id="username"
                  v-model="$v.form.text.$model"
                  :state="validateState('text')"
                  placeholder="Type here"
                  type="text"
                ></b-form-input>
                <b-form-invalid-feedback
                  v-if="!api_error"
                  id="username-feedback"
                  >This is a required field.</b-form-invalid-feedback
                >
                <b-form-invalid-feedback
                  v-if="api_error"
                  id="username-feedback"
                  >{{ api_error_msg }}</b-form-invalid-feedback
                >
              </b-form-group>

              <template v-if="manual_entry">
                <p class="font-weight-light text-danger">
                  Mmm, I don’t recognise that! Here’s another way to select your
                  birthdate
                </p>

                <b-row class="mt-3">
                  <b-col>
                    <b-form-group>
                      <b-form-select
                        size="md"
                        v-model="$v.dob.day.$model"
                        :state="validateDOBState('day')"
                        :options="options.days"
                        value-field="id"
                        text-field="name"
                      ></b-form-select>

                      <b-form-invalid-feedback
                        v-if="!invalid_dob"
                        >This is a required field.</b-form-invalid-feedback
                      >
                      <b-form-invalid-feedback
                        v-if="api_error"
                        id="cityname-feedback"
                        >{{ api_error_msg }}</b-form-invalid-feedback
                      >
                    </b-form-group>
                  </b-col>

                  <b-col>
                    <b-form-group>
                      <b-form-select
                        size="md"
                        v-model="$v.dob.month.$model"
                        :state="validateDOBState('month')"
                        :options="options.months"
                        value-field="id"
                        text-field="name"
                      ></b-form-select>

                      <b-form-invalid-feedback
                        v-if="!invalid_dob"
                        >This is a required field.</b-form-invalid-feedback
                      >
                      <b-form-invalid-feedback
                        v-if="api_error"
                        >{{ api_error_msg }}</b-form-invalid-feedback
                      >
                    </b-form-group>
                  </b-col>

                  <b-col>
                    <b-form-group>
                      <b-form-select
                        size="md"
                        v-model="$v.dob.year.$model"
                        :state="validateDOBState('year')"
                        :options="options.years"
                        value-field="id"
                        text-field="name"
                      ></b-form-select>

                      <b-form-invalid-feedback
                        v-if="!invalid_dob"
                        >This is a required field.</b-form-invalid-feedback
                      >
                      <b-form-invalid-feedback
                        v-if="api_error"
                        >{{ api_error_msg }}</b-form-invalid-feedback
                      >
                    </b-form-group>
                  </b-col>

                  <b-col v-if="invalid_dob" cols="12">
                    <p class="font-weight-light text-danger">Please enter a valid date of birth.</p>
                  </b-col>

                </b-row>
              </template>

              <template v-if="submission_success">
                <b-button
                  size="lg"
                  type="submit"
                  variant="outline-light"
                  class="my-3 px-2 text-sub"
                  >{{ date_of_birth_str }} <span class="ml-2 mr-2" @click="resetDOB()">X</span></b-button
                >
                <p class="mt-2 text-cool">
                  <b>Cool! </b>Born on a {{ date_of_birth_day }} eh?
                </p>

                <p
                  class="
                    col-12
                    my-3
                    px-0
                    float-left
                    text-light text-short
                  "
                >
                  Anyone over the age of 13 is welcome to use Trackd.
                </p>
              </template>

              <div v-if="api_error">
                <p class="mt-4 text-cool pr-5" style="color:#E76E66">
                  Oops! Im afraid you're too young to create an account. Please
                  ask a parent or guardian.
                </p>

                <b-button
                  size="lg"
                  type="submit"
                  variant="outline-light"
                  class="my-3 px-2 text-sub"
                  @click="resetForm()"
                  >Re-enter birthdate</b-button
                >
              </div>

              <b-button
                size="lg"
                type="submit"
                variant="info"
                class="my-2 px-4 float-right next-btn"
                >Next</b-button
              >
            </b-form>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";


function get_years() {
  var currentYear = new Date().getFullYear()
  var endYear = new Date().getFullYear() - 100
  var years = []

  var i = currentYear

  years.push({'id': null, name: 'Year'})

  while (i > endYear) {
    years.push({'id': i, 'name': i});
    i = i - 1;
  }
  return years;
}

function ValidDate(year, month, day) {

  var date = new Date();
  date.setFullYear(year, month - 1, day);
  if (
    date.getFullYear() == year &&
    date.getMonth() + 1 == month &&
    date.getDate() == day
  ) {
      return true
  }

  return false

}
function isFutureDate(year, month, day) {
    var date = new Date();
    date.setFullYear(year, month - 1, day);
    return new Date().getTime() <= date.getTime();
}

export default {
  name: "OnboardingGender",
  mixins: [validationMixin],
  data() {
    return {
      form: {
        gender: null,
        text: null,
      },
      dob: {
        day: null,
        month: null,
        year: null,
      },
      options: {
        genders: [
          { text: "Male", value: "male" },
          { text: "Prefer not to say", value: "unknown" },
          { text: "Female", value: "female" },
        ],
        days: [{'id': null, name: 'Day'}, {'id': 1, 'name': '1'}, {'id': 2, 'name': '2'}, {'id': 3, 'name': '3'}, {'id': 4, 'name': '4'}, {'id': 5, 'name': '5'}, {'id': 6, 'name': '6'}, {'id': 7, 'name': '7'}, {'id': 8, 'name': '8'}, {'id': 9, 'name': '9'}, {'id': 10, 'name': '10'}, {'id': 11, 'name': '11'}, {'id': 12, 'name': '12'}, {'id': 13, 'name': '13'}, {'id': 14, 'name': '14'}, {'id': 15, 'name': '15'}, {'id': 16, 'name': '16'}, {'id': 17, 'name': '17'}, {'id': 18, 'name': '18'}, {'id': 19, 'name': '19'}, {'id': 20, 'name': '20'}, {'id': 21, 'name': '21'}, {'id': 22, 'name': '22'}, {'id': 23, 'name': '23'}, {'id': 24, 'name': '24'}, {'id': 25, 'name': '25'}, {'id': 26, 'name': '26'}, {'id': 27, 'name': '27'}, {'id': 28, 'name': '28'}, {'id': 29, 'name': '29'}, {'id': 30, 'name': '30'}, {'id': 31, 'name': '31'}],
        months: [{'id': null, name: 'Month'}, {'id': 1, name: 'Jan'},{'id': 2, name: 'Feb'},{'id': 3, name: 'Mar'},{'id': 4, name: 'Apr'},{'id': 5, name: 'May'},{'id': 6, name: 'Jun'},{'id': 7, name: 'Jul'},{'id': 8, name: 'Aug'},{'id': 9, name: 'Sep'},{'id': 10, name: 'Oct'},{'id': 11, name: 'Nov'},{'id': 12, name: 'Dec'}],
        years: get_years(),
      },
      date_of_birth_val: null,
      date_of_birth_str: null,
      date_of_birth_day: null,
      api_error: false,
      api_error_msg: null,
      submission_success: false,
      submitted: false,
      manual_entry: false,
      invalid_dob: false,
    };
  },
  validations: {
    form: {
      gender: {
        required,
      },
      text: {
        required,
      },
    },
    dob: {
      day: {required},
      month: {required},
      year: {required},
    }
  },
  methods: {
    ...mapActions("Onboarding", ["update_account", "date_of_birth"]),
    ...mapMutations(["kochavaEvent"]),
    validateState(name) {
      if (this.api_error) {
        return false;
      } else {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      }
    },
    validateDOBState(name) {
      if (this.invalid_dob) {
        return false;
      } else {
        const { $dirty, $error } = this.$v.dob[name];
        return $dirty ? !$error : null;
      }
    },
    resetDOB() {
      this.submission_success = false
      this.manual_entry = false
      this.date_of_birth_val = "";
      this.date_of_birth_str = "";
      this.date_of_birth_day = "";

    },
    resetForm() {
      this.api_error = false;
      this.api_error_msg = null;
      this.submitted = false;
      this.date_of_birth_val = null;
      this.date_of_birth_str = null;
      this.date_of_birth_day = null;
      this.manual_entry = false;
      this.form.text = null;
    },
    Gender(value) {
      this.form.gender = value;
    },
    onSubmit() {
      this.api_error = false;
      this.api_error_msg = null;

      let campaign_code = this.getCampaignCode;
      var utm_campaign = this.$cookie.get('utm_campaign')
      var utm_medium = this.$cookie.get('utm_medium')
      var utm_source = this.$cookie.get('utm_source')
      var cookie_campaign_code = this.$cookie.get('campaign_code')

      if (!this.submission_success && !this.manual_entry) {

        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          return;
        }

        let formData1 = {};
        formData1["device_id"] = "jhshbswhsw";
        formData1["text"] = this.form.text;

        this.submitted = true;

        this.date_of_birth(formData1)
          .then((res) => {
            this.date_of_birth_val = res.data.date;
            this.date_of_birth_str = res.data.date_text;
            this.date_of_birth_day = res.data.date_day;
            // store email
            // go to next screen
            this.submission_success = true;
          })
          .catch((error) => {

            if (error.data.message === "Help, you’re not understanding me!") {
              this.manual_entry = true;
            } else {
              this.api_error = true;
              this.api_error_msg = error.data.message;
            }
          });
      } 
      
      else if (this.submission_success && !this.manual_entry) {

        const formData2 = {};
        formData2["gender"] = this.form.gender;
        formData2["date_of_birth"] = this.date_of_birth_val;

        if (cookie_campaign_code) {
          formData2["campaign_code"] = cookie_campaign_code;
        } else if (!cookie_campaign_code) {

          if (campaign_code) {
            formData2["campaign_code"] = campaign_code;
          }
        }

        if (utm_campaign) {
          formData2["utm_campaign"] = utm_campaign;
        }

        if (utm_source) {
          formData2["utm_source"] = utm_source;
        }

        if (utm_medium) {
          formData2["utm_medium"] = utm_medium;
        }

        this.update_account(formData2)
          .then((res) => {
            res
            // store email
            // go to next screen

            this.$bvModal.hide("gender-onboarding-modal");  
            this.$bvModal.show("user-type-onboarding-modal");
            
          })
          .catch((error) => {
            
            this.api_error = true;
            this.api_error_msg = error.data.message;
          });
      }

      else if (!this.submission_success && this.manual_entry) {

        this.invalid_dob = false

        this.$v.dob.$touch();
        if (this.$v.dob.$anyError) {
          return;
        }

        // Valid Date

        if (!ValidDate(this.dob.year, this.dob.month, this.dob.day)) {
          this.invalid_dob = true
          return
        }

        // In Future

        if (isFutureDate(this.dob.year, this.dob.month, this.dob.day)) {
          this.invalid_dob = true
          return
        }

        const formData3 = {};
        formData3["gender"] = this.form.gender;
        formData3["date_of_birth"] = String(this.dob.year) + '-' + String(this.dob.month) + '-' + String(this.dob.day);

        if (cookie_campaign_code) {
          formData3["campaign_code"] = cookie_campaign_code;
        } else if (!cookie_campaign_code) {

          if (campaign_code) {
            formData3["campaign_code"] = campaign_code;
          }
        }

        if (utm_campaign) {
          formData3["utm_campaign"] = utm_campaign;
        }

        if (utm_source) {
          formData3["utm_source"] = utm_source;
        }

        if (utm_medium) {
          formData3["utm_medium"] = utm_medium;
        }

        this.update_account(formData3)
          .then((res) => {
            res
            // store email
            // go to next screen

            this.$bvModal.hide("gender-onboarding-modal");  
            this.$bvModal.show("user-type-onboarding-modal");  
            
          })
          .catch((error) => {
            
            this.api_error = true;
            this.api_error_msg = error.data.message;
          });
      }

    },
  },
  computed: {
    ...mapGetters("Onboarding", [
      "isCreator",
      "getUserType",
      "getCampaignCode",
    ]),

    getModalSize() {
      return window.matchMedia("(min-width: 576px)").matches ? 'md' : 'lg';
    }
  },
  mounted() {
    //this.$refs['forgot-password'].show()
    
    this.$bvModal.hide("gender-onboarding-modal");
  },
};
</script>

<style>
.card-body {
  padding: 0 !important;
}
.header-spacing {
  margin-top: 120px !important;
}

.text-small {
  font-size: 0.5em !important;
}

.badge-pill {
  padding: 0.001em;
}

.next-btn {
  border-radius: 10px !important;
}

.badge {
  padding: 0 !important;
}


@media (min-width: 575.98px) {
  .gender_list .col {
    padding: 0 !important;
    flex-grow: 0;
    padding-right: 10px;
  }

  .text-des {
    min-width: 500px;
  }
}

.text-birth {
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
}

.text-cool {
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
}

.text-cool b {
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
}

.text-short {
  line-height: 22px !important;
}
</style>

<style scoped>

.fw-500 {
  font-weight: 500 !important;
}

.title {
  margin-right: -20px;
}


</style>