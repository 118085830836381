import Vue from "vue";

// Global mixin to convert a url into a file path
Vue.mixin({
  computed: {
    screen_width() {
      return window.screen.height
    },
  },
});
