<template>
  <div>
    <!-- MODAL -->

    <b-modal
      id="chipin-creator-refer-modal"
      ref="chipin-creator-refer-modal"
      size="lg"
      content-class="bg-dark text-light  "
      centered
      hide-footer
      header-close-variant="light"
    >
      <template #modal-header="{ close }">
        <b-container fluid>
          <b-row>
            <b-col class="float-left">
              <img
                src="../../../../assets/trackd-icon-colours.svg"
                class="mb-4 mx-auto text-left mt-2"
                height="25"
                alt="Logo"
              />
            </b-col>

            <b-col class="float-right">
              <b-button
                size="sm"
                variant="link"
                class="float-right"
                @click="close()"
              >
                <img
                  src="../../../../assets/section-close.svg"
                  class="mx-auto text-right float-right mt-2"
                  height="20"
                  alt="Close"
                />
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </template>

    <b-container fluid>
      <b-row>
        <b-col class="mb-3" cols="10" offset="1" sm="8" offset-sm="2">
          
          <b-row class="mb-3">
            <b-col class="p-1">
              <b-badge pill variant="info" class="w-100 text-info text-small"
                >&nbsp;a</b-badge
              >
            </b-col>
            <b-col class="p-1">
              <b-badge
                pill
                variant="info"
                class="w-100 text-info text-small"
                >&nbsp;a</b-badge
              >
            </b-col>
            <b-col class="p-1">
              <b-badge
                pill
                variant="warning"
                class="w-100 text-warning text-small"
                >&nbsp;c</b-badge
              >
            </b-col>
          </b-row>
        </b-col>

        <b-col cols="2"></b-col>
        
        <b-col cols="2">
          <b-button v-b-modal.chipin-creator-getstarted-modal variant="link" @click="$bvModal.hide('chipin-creator-artists-managers')">
            <img src="../../../../assets/arrow-left.svg" />
          </b-button>
        </b-col>

        <b-col cols="7">

          <div class="row m-0 pb-3 p-0">
            <div style="width: 100%">
              <b-row>
                <b-col col class="song-profile-des">
                  <p class="mb-1 mt-2 font-weight-bold">Lets Share!</p>
                  <p class="mb-1 mt-2 font-weight-lighter">Complete the referral form below</p>
                </b-col>
                <b-col>
                    <img src="../../../../assets/chipin-plus-text.svg" class="my-3" style="height:35px;"/>
                    <p class="complete-form">Complete the form below</p>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <b-container fluid>
        <b-row>
            <b-col class="mb-3" cols="10" offset="1" sm="8" offset-sm="2">
                <b-form-group
                    class="form-label"
                    label-class="text-light py-0 mr-5 mb-2"
                    id="input-group-2"
                    label="Your Artist Name"
                    label-for="input-2"
                    label-cols="12"
                    label-cols-sm="12"
                    label-cols-lg="12"
                >
                    <SettingsTextInput
                    id="input-2"
                    v-model="form_data.name"
                    v-bind:placeholder_text="`Enter name`"
                    v-bind:type="'name'"
                    
                    class="input-width text-input-margin"
                    ></SettingsTextInput>
                </b-form-group>

                <b-form-group
                    class="form-label"
                    label-class="text-light py-0 mr-5 mb-2"
                    id="input-group-2"
                    label="Your Main Social URL"
                    label-for="input-2"
                    label-cols="12"
                    label-cols-sm="12"
                    label-cols-lg="12"
                >
                    <SettingsTextInput
                    id="input-2"
                    v-model="form_data.email"
                    v-bind:placeholder_text="`Enter email address`"
                    v-bind:type="'email'"
                    
                    class="input-width text-input-margin"
                    ></SettingsTextInput>
                </b-form-group>

                <hr class="bg-light mt-4" style="margin-right: -50px;" />                

                <b-form-group
                    class="form-label"
                    label-class="text-light py-0 mr-5 mb-2"
                    id="input-group-2"
                    label="Artist Referral Email 1"
                    label-for="input-2"
                    label-cols="12"
                    label-cols-sm="12"
                    label-cols-lg="12"
                >
                    <SettingsTextInput
                    id="input-2"
                    v-model="form_data.referral_email"
                    v-bind:placeholder_text="`Enter email address`"
                    v-bind:type="'referral_email'"
                    
                    class="input-width text-input-margin"
                    ></SettingsTextInput>
                </b-form-group>

                <b-form-group
                    class="form-label"
                    label-class="text-light py-0 mr-5 mb-2"
                    id="input-group-2"
                    label="Artist Referral Email 2"
                    label-for="input-2"
                    label-cols="12"
                    label-cols-sm="12"
                    label-cols-lg="12"
                >
                    <SettingsTextInput
                    id="input-2"
                    v-model="form_data.referral_email_2"
                    v-bind:placeholder_text="`Enter email address`"
                    v-bind:type="'referral_email_2'"
                    
                    class="input-width text-input-margin"
                    ></SettingsTextInput>
                </b-form-group>

                <b-form-group
                    class="form-label"
                    label-class="text-light py-0 mr-5 mb-2"
                    id="input-group-2"
                    label="Artist Referral Email 3"
                    label-for="input-2"
                    label-cols="12"
                    label-cols-sm="12"
                    label-cols-lg="12"
                >
                    <SettingsTextInput
                    id="input-2"
                    v-model="form_data.referral_email_3"
                    v-bind:placeholder_text="`Enter email address`"
                    v-bind:type="'referral_email_3'"
                    
                    class="input-width text-input-margin"
                    ></SettingsTextInput>
                </b-form-group>

                <p class="statement-text px-3 pt-4 pb-3">
                    I state that the above information is true and the email addresees provided are real 
                    and valid in accordance with our Terms and Conditions. I agree to be contacted by Trackd’s 
                    A&R department. Applications are processed in order and at Trackd’s sole discretion.
                </p>

                <b-button
                    @click="apply"
                    class="px-4 py-2 rounded-btn btn-lg offset-2"
                    size="md"
                    variant="info"
                >
                    Submit
                </b-button>
            </b-col>
        </b-row>
    </b-container>

    </b-modal>
  </div>
</template>


<script>
import { mapActions, mapGetters } from 'vuex';
import SettingsTextInput from '../../../../components/settings/text-input.vue'



export default {
  
  name: "ChipInCreatorRefer",
  data() {
    return {
        form_data: {
            product_type: 'chipin_plus',
            queue_jump: true,
            type: 'artist',
            email: null,
            social: null,
            phone: null,
            referral_email: null,
            referral_email_2: null,
            referral_email_3: null
        }
    };
  },
  components: {
      SettingsTextInput,
  },
  methods: {
      ...mapActions("Chipin", ["chipin_application"]),

    apply() {
        this.chipin_application(this.form_data)
        .then(res => {
            res
            this.$bvModal.hide('chipin-creator-refer-modal')
            this.$bvModal.show('chipin-creator-thanks-modal')
        })
        .catch(error => {
          error
            
        })
    }
  },
  computed: {
    ...mapGetters(['user_image'])
    
  },
  mounted: function () {
    
  },
};
</script>

<style scoped>
#chipin-form {
  width: 100%;
  margin-top: 10px;
}

.modal-content {
  width: auto !important;
  margin: auto;
}

#chipin-form > h1 {
  font-weight: 900 !important;
  font-size: 36px;
  line-height: 21px;
  letter-spacing: 0.152972px;
}

#chipin-form > p {
  font-weight: 300 !important;
  font-size: 12px;
  line-height: 21px;
  letter-spacing: 0.152972px;
}

#chipin-form > h4 {
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.152972px;
}

.light-grey-border {
  border: 2px solid #9b9b9b;
}

.red-text {
  color: #ff0000;
}

.dark-text {
  color: #595959;
}

.super-small {
  font-size: 0.7em;
  color: #595959;
}

.rounded-btn {
  border-radius: 10px;
}

.line-height {
  line-height: 1.45em;
}

.included-info {
  display: inline-flex;
  align-items: center;
  float: left;
}

.song-profile-des > p {
  font-size: 34px;
  line-height: 36px;
}

.song-profile-des > h5 {
  font-weight: 500 !important;
  font-size: 34px;
  line-height: 36px;
}

.song-profile-des > span {
  font-weight: 300 !important;
  font-size: 18px;
  line-height: 24px;
  display: block;
  margin-top: 20px;
}

.months-btn {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 10px;
}

.months-btn > button {
  border-radius: 7px;
  font-family: "SFPro";
}

.month-one {
  border-top-right-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
  background: white;
  color: black;
  border: 0.5px solid rgba(0, 0, 0, 0.04);
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04);
  width: 50%;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
  margin-right: -5px;
  z-index: 2;
}

.month-twelve {
  background: black;
  color: white;
  background: rgba(118, 118, 128, 0.12);
  border: 1px solid #ffffff;
  border-left: none;
  width: 50%;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
}

.subscription {
  font-weight: 300 !important;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.152972px;
}

.select-btn {
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
  width: 236px;
  height: 40px;
}

.show-btn {
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
  width: 236px;
  height: 40px;
  border: 1px solid #00AFDC;
  background-color: transparent;
}

.choose-text {
    font-size: 18px;
    font-weight: 700;
}

.btn-lg {
    width: 374px;
    height: 40px;
    font-size: 18px;
    line-height: 10px;
    font-weight: 500;
}

.btn-lg-outline {
    width: 374px;
    height: 40px;
    font-size: 18px;
    line-height: 10px;
    font-weight: 500;
    background-color: transparent;
    border: 2px solid #00AFDC;
}

.desc {
    font-size: 14px;
    font-weight: 400;
}

.or {
    font-size: 18px;
    font-weight: 700;
}

.complete-form {
    font-size: 18px;
    font-weight: 500;
}

.statement-text {
    font-size: 10px;
    font-weight: 300;
}

.btn-lg {
    width: 236px;
    height: 40px;
    font-size: 18px;
    line-height: 10px;
    font-weight: 500;
}

.form-label {
    font-size: 18px;
    font-weight: 300;
}

</style>
