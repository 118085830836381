<template>
  <b-modal
    id="user-type-onboarding-modal"
    :size="getModalSize"
    centered
    hide-footer
    title-sr-only
    no-close-on-backdrop
    no-close-on-esc
    content-class="bg-dark text-light text-center"
    header-close-variant="light"
    title="Onboarding User Type"
    modal-class="onboarding-modal"
    header-class="pb-0"
    body-class="pt-0"
  >
    <template #modal-header="{ close }">
      <b-container fluid>
        <b-row class="text-center">
          <!-- Required for equal spacing -->
          <b-col>
            <b-button
              size="sm"
              variant="link"
              class="float-left"
              v-b-modal.gender-onboarding-modal
              @click="$bvModal.hide('user-type-onboarding-modal')"
            >
              <img
                src="../../assets/back-icon.svg"
                class="align-top"
                height="40"
                alt="Back icon"
              />
            </b-button>
          </b-col>

          <b-col cols="6">
            <img
              src="../../assets/trackd-icon-colours.svg"
              class="mb-4 mx-auto text-center mt-2"
              height="35"
              alt="Logo"
            />
          </b-col>

          <b-col class="float-right">
            <b-button
              size="sm"
              variant="link"
              class="float-right"
              @click="close()"
            >
              <img
                src="../../assets/section-close.svg"
                class="mx-auto text-right float-right mt-2"
                height="23"
                alt="Close"
              />
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </template>

    <b-row>
      <b-col cols="10" offset="1" sm="8" offset-sm="2" md="8" offset-md="2">
        <b-row class="mb-4">
          <b-col class="p-1">
            <b-badge pill variant="info" class="w-100 text-info text-small"
              >&nbsp;a</b-badge
            >
          </b-col>
          <b-col class="p-1">
            <b-badge pill variant="info" class="w-100 text-info text-small"
              >&nbsp;b</b-badge
            >
          </b-col>
          <b-col class="p-1">
            <b-badge
              pill
              variant="warning"
              class="w-100 text-warning text-small"
              >&nbsp;c</b-badge
            >
          </b-col>
        </b-row>

        <h2 class="text-left text-title pl-2">
          Are you an Artist<br />
          or a music fan?
        </h2>
      </b-col>

      <b-card
        class="mt-3 pb-2 shadow-sm bg-dark text-left offset-1 offset-sm-2 offset-md-2"
        no-body
      >
        <h5 class="subheading pl-4 mb-3">Choose how to tailor Trackd</h5>

        <AccountTypeSelector @onSelected="typeSelected"></AccountTypeSelector>

        <p v-if="api_error" class="text-danger mt-4 ">
          Please select a option above.
        </p>

        <h5 class="mt-4 pt-3 subheading">
          Don't worry, choose either for now,<br />
          you can be <span style="font-weight: 500;">both</span> later!
        </h5>
      </b-card>
    </b-row>

    <b-button
      size="lg"
      variant="info"
      class="mt-2 mb-4 mr-5 float-right next-btn"
      @click="onSubmit"
      >Next</b-button
    >
  </b-modal>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import AccountTypeSelector from "../onboarding/AccountTypeSelector.vue";

export default {
  name: "OnboardingUserType",
  mixins: [validationMixin],
  components: {
    AccountTypeSelector,
  },
  data() {
    return {
      form: {
        user_type: null,
      },
      api_error: false,
      api_error_msg: null,
      submission_success: false,
      submitted: false,
      manual_entry: false,
    };
  },
  validations: {
    form: {
      user_type: {
        required,
      },
    },
  },
  methods: {
    ...mapActions("Onboarding", ["update_account"]),
    ...mapMutations(["kochavaEvent"]),
    validateState(name) {
      if (this.api_error) {
        return false;
      } else {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      }
    },
    resetForm() {
      this.api_error = false;
      this.api_error_msg = null;
      this.submitted = false;
    },
    onSubmit() {
      this.api_error = false;
      this.api_error_msg = null;

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        this.api_error = true;
        return;
      }

      let campaign_code = this.getCampaignCode;
      var utm_campaign = this.$cookie.get("utm_campaign");
      var utm_medium = this.$cookie.get("utm_medium");
      var utm_source = this.$cookie.get("utm_source");
      var cookie_campaign_code = this.$cookie.get("campaign_code");

      const formData2 = {};
      formData2["user_type"] = this.form.user_type;
      formData2["onboarded"] = true;

      if (cookie_campaign_code) {
        formData2["campaign_code"] = cookie_campaign_code;
      } else if (!cookie_campaign_code) {
        if (campaign_code) {
          formData2["campaign_code"] = campaign_code;
        }
      }

      if (utm_campaign) {
        formData2["utm_campaign"] = utm_campaign;
      }

      if (utm_source) {
        formData2["utm_source"] = utm_source;
      }

      if (utm_medium) {
        formData2["utm_medium"] = utm_medium;
      }

      this.update_account(formData2)
        .then((res) => {
          res;
          // store email
          // go to next screen

          this.kochavaEvent("Registration Complete");
          this.$bvModal.hide("user-type-onboarding-modal");

          if (res.data.profile.user_type === "discover") {
            this.$router.push({ name: "Home" });
          } else {
            this.$router.push({ name: "Dashboard" });
          }
        })
        .catch((error) => {
          this.api_error = true;
          this.api_error_msg = error.data.message;
        });
    },
    typeSelected(type) {
      this.api_error = false;
      this.form.user_type = type;
    },
  },
  computed: {
    ...mapGetters("Onboarding", [
      "isCreator",
      "getUserType",
      "getCampaignCode",
    ]),

    getModalSize() {
      return window.matchMedia("(min-width: 576px)").matches ? "md" : "lg";
    },
  },
  mounted() {
    this.$bvModal.hide("gender-onboarding-modal");
  },
};
</script>

<style scoped>
.card-body {
  padding: 0 !important;
}
.header-spacing {
  margin-top: 120px !important;
}

.text-small {
  font-size: 0.5em !important;
}

.badge-pill {
  padding: 0.001em;
}

.next-btn {
  border-radius: 10px !important;
  border: 1px solid white;
}

.badge {
  padding: 0 !important;
}

.text-title {
  font-size: 34px;
  font-weight: 200;
  line-height: 36px;
}

.subheading {
  font-size: 18px;
  font-weight: 300;
  line-height: 22px;
}

.pr-6 {
  padding-right: 4em !important;
}

@media (min-width: 575.98px) {
  .gender_list .col {
    padding: 0 !important;
    flex-grow: 0;
    padding-right: 10px;
  }

  .text-des {
    min-width: 500px;
  }
}

.text-birth {
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
}

.text-cool {
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
}

.text-cool b {
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
}

.text-short {
  line-height: 22px !important;
}
</style>
