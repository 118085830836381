<template>
  <div class="radio r1">
    <label v-for="(option, index) in options" :key="index">
      <b-row>
        <b-col>
          <input
            type="radio"
            name="group1"
            v-bind:value="value"
            :checked="option === value"
            v-on:change="$emit('change', option)"
            v-on:input="$emit('input', option)"
          />
          <span></span>
        </b-col>
        <b-col class="align-self-center option pl-0">
          {{option}}
        </b-col>
      </b-row>
    </label>
  </div>
</template>

<script>
export default {
  name: "SettingsRadioInput",
  props: ["value", "options", "type"],
};
</script>

<style>
.radio label.custom-control-input:checked + span:before {
  background-color: red;
}

.radio {
  margin: 20px;
}

.radio input {
  display: none;
}

.radio label {
  margin-right: 20px;
  display: inline-block;
  cursor: pointer;
}

/* Radio Button Background (Grey Circle) */
.r1 span {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;
  transition: all 0.25s linear;
  background-color: #4a4a4a;
}
/* Radio Button Selected (White Circle) */
.r1 span:before {
  content: "";
  position: absolute;
  left: 5px;
  top: 50%;
  -webkit-transform: translatey(-50%);
  transform: translatey(-50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transition: all 0.25s linear;
  margin-left: 5px;
  padding: 10px;
}

/* Radio Button Selected (White Circle) */
.r1 input:checked + span:before {
  background-color: #fff;
  margin-left: 5px;
  padding: 10px;
}

.option {
  font-size: 18px;
  font-weight: 300;
}

</style>
