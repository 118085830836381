import Vue from "vue";
import VueRouter from "vue-router";
import store from "./store";

const Page404 = () => import("@/views/Page404");

const GoogleAuthCallback = () => import("@/views/auth/GoogleCallback");
const AppleAuthCallback = () => import("@/views/auth/AppleCallback");

const Campaign = () => import("@/views/Campaign");

// Public
const Home = () => import("@/views/Home");
const ForArtists = () => import("@/views/pages/ForArtists");
const TridentRecord = () => import("@/views/TridentRecord");
const Artist = () => import("@/views/Artist");
const ArtistProfileList = () => import("@/views/ArtistProfileList");
const Song = () => import("@/views/Song");
const SongProfileList = () => import("@/views/SongProfileList");
const ChipInSuccess = () => import("@/views/chipin/ChipInSuccess");
const ChipInCancel = () => import("@/views/chipin/ChipInCancel");
const AuthRedirect = () => import("@/views/AuthRedirect");

// Public Site Pages
const Support = () => import("@/views/pages/Support");
const Legal = () => import("@/views/pages/Legal");
const Company = () => import("@/views/pages/Company");
const OurStory = () => import("@/views/pages/OurStory");
const ChipIn = () => import("@/views/ChipIn");
const Landing = () => import("@/views/Landing");
const AppPage = () => import("@/views/pages/AppPage");
const AboutUs = () => import("@/views/pages/AboutUs");
const Pricing = () => import("@/views/pages/Pricing");
const MusicRights = () => import("@/views/pages/MusicRights");

// Authenticated / Account
const Songs = () => import("@/views/account/Songs");
const Upload = () => import("@/views/account/Upload");
const Login = () => import("@/views/Login");
const SignUp = () => import("@/views/SignUp");

// Settings

const AccountSettings = () =>
  import("@/views/account/settings/AccountSettings");
const EditAccountSettings = () =>
  import("@/views/account/settings/EditAccountSettings");
const EditUserProfile = () => import("@/views/account/settings/EditProfile");
const EditUserProfileSkills = () => import("@/views/account/settings/EditProfileSkills");
const EditUserProfileTags = () => import("@/views/account/settings/EditUserProfileTags");
const EditSongProfile = () =>
  import("@/views/account/settings/EditSongProfile");
const EditSongProfileGenres = () => import("@/views/account/settings/EditSongProfileGenres");
const SetupChipIn = () => import("@/views/account/SetupChipIn");
const SignInSettings = () => import("@/views/account/settings/SignInSettings");
const ChangePassword = () => import("@/views/account/settings/ChangePassword");
const SongSettings = () => import("@/views/account/settings/SongSettings");
const Notifications = () => import("@/views/account/settings/Notifications");
const EmailSettings = () => import("@/views/account/settings/EmailSettings");
const PrivacySettings = () => import("@/views/account/settings/PrivacySettings");
const Credits = () => import("@/views/account/settings/Credits");
const Dashboard = () => import("@/views/account/dashboard/ArtistDashboard");

// ChipIn+

const ChipInAccountType = () =>
  import("@/views/account/settings/ChipInAccountType");
const ChipInPlusSettings = () =>
  import("@/views/account/settings/ChipInPlusSettings");
const ChipInPlusSongSettings = () =>
  import("@/views/account/settings/ChipInPlusSongSettings");

// Library
const LibraryArea = () => import("@/views/library/LibraryArea");
const LibrarySectionList = () => import("@/views/library/LibrarySectionList");
const LibrarySection = () => import("@/views/library/Section");
const LibrarySubSection = () => import("@/views/library/SubSection");
const SubSectionList = () => import("@/views/library/SubSectionList");

const Playlist = () => import("@/views/library/types/Playlist");
//const Location = () => import('@/views/library/types/Location')

// ChipIn Account
const ChipInAccount = () => import("@/views/account/chipin/Account");
//const ChipInPayments = () => import('@/views/account/chipin/Payments')
const ChipInTransactions = () => import("@/views/account/chipin/Transactions");
const ChipInSubscriptions = () =>
  import("@/views/account/chipin/Subscriptions");
const ChipInPayouts = () => import("@/views/account/chipin/Payouts");
const ChipInCards = () => import("@/views/account/chipin/card/Cards");
const ChipInAddCard = () => import("@/views/account/chipin/card/AddCard");
const ChipInBankAccounts = () =>
  import("@/views/account/chipin/bank_account/BankAccounts");
const ChipInAddBankAccount = () =>
  import("@/views/account/chipin/bank_account/AddBankAccount");
const SubscriptionsMade = () =>
    import("@/views/account/chipin/SubscriptionsMade");
const DonationsMade = () =>
      import("@/views/account/chipin/DonationsMade");

// Overlays

const CreateOverlay = () => import("@/views/overlays/Create");
const DiscoverOverlay = () => import("@/views/overlays/Discover");

Vue.use(VueRouter);

function requireAuth(to, from, next) {
  if (store.state.accessToken || localStorage.getItem('accessToken')) {

    next();

  } else {

    if (to.path !== "/") {
      store.commit('storeNextUrl', to.path)
      next("/");
      store.commit("Auth/setShowLoginModal", true);
    }

    next();
    

  }
}

export default new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home,
    },
    {
      path: "/auth",
      name: "AuthRedirect",
      component: AuthRedirect,
    },
    {
      path: "/signup",
      name: "SignUp",
      component: SignUp,
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
    },
    {
      path: "/landing",
      name: "Landing",
      component: Landing,
    },
    {
      path: "/onboarding/discover",
      name: "DiscoverOverlay",
      component: DiscoverOverlay,
    },
    {
      path: "/onboarding/create",
      name: "CreateOverlay",
      component: CreateOverlay,
    },
    {
      path: "/app",
      name: "AppPage",
      component: AppPage,
    },
    {
      path: "/about",
      name: "AboutUs",
      component: AboutUs,
    },
    {
      path: "/pricing",
      name: "Pricing",
      component: Pricing,
    },
    {
      path: "/forartists",
      name: "ForArtists",
      component: ForArtists,
    },
    {
      path: "/musicrights",
      name: "MusicRights",
      component: MusicRights,
    },
    {
      path: "/campaign",
      name: "Campaign",
      component: Campaign,
    },
    {
      path: "/tridentrecords",
      name: "TridentRecord",
      component: TridentRecord,
    },
    {
      path: "/auth/google",
      name: "GoogleAuthCallback",
      component: GoogleAuthCallback,
    },
    {
      path: "/auth/apple",
      name: "AppleAuthCallback",
      component: AppleAuthCallback,
    },
    // Historic Redirects
    {
      path: "/terms-conditions",
      redirect: "/legal",
    },
    {
      path: "/info",
      redirect: "/legal",
    },
    {
      path: "/privacy-policy",
      redirect: "/legal",
    },
    {
      path: "/the-team",
      redirect: "/company",
    },
    {
      path: "/info/legal",
      redirect: "/legal",
    },
    // Public Site Pages
    {
      path: "/legal",
      name: "Legal",
      component: Legal,
    },
    {
      path: "/company",
      name: "Company",
      component: Company,
    },
    {
      path: "/our-story",
      name: "OurStory",
      component: OurStory,
    },
    {
      path: "/support",
      name: "Support",
      component: Support,
    },
    {
      path: "/chipin",
      name: "ChipIn",
      component: ChipIn
    },
    {
      path: "/error",
      name: "error",
      component: Page404,
    },
    // Library
    {
      path: "/library/:area/:name/section/:id/list/",
      name: "LibraryAreaSubSectionList",
      component: SubSectionList,
      beforeEnter: requireAuth,
    },
    {
      path: "/library/:area/:name/section/:id/:type",
      name: "LibraryAreaSubSection",
      component: LibrarySubSection,
      beforeEnter: requireAuth,
    },
    {
      path: "/library/:area/:name",
      name: "LibraryArea",
      component: LibraryArea,
    },
    {
      path: "/library/sub-section/:id/list",
      name: "LibrarySubSectionList",
      component: SubSectionList,
      beforeEnter: requireAuth,
    },
    {
      path: "/library/sub-section/:id",
      name: "LibrarySubSection",
      component: LibrarySubSection,
      beforeEnter: requireAuth,
    },
    {
      path: "/library/:area/list/:id",
      name: "LibrarySectionList",
      component: LibrarySectionList,
      beforeEnter: requireAuth,
    },
    {
      path: "/library/:id",
      name: "LibrarySection",
      component: LibrarySection,
      beforeEnter: requireAuth,
    },
    {
      path: "/playlist/:id",
      name: "Playlist",
      component: Playlist,
    },
    {
      path: "/library/location/:id",
      name: "Location",
      component: Playlist,
      beforeEnter: requireAuth,
    },
    {
      path: "/library/genre/:id",
      name: "Genre",
      component: Playlist,
      beforeEnter: requireAuth,
    },
    // Authenticated / Account
    {
      path: "/songs",
      name: "Songs",
      component: Songs,
      beforeEnter: requireAuth,
    },
    {
      path: "/upload",
      name: "Upload",
      component: Upload,
      beforeEnter: requireAuth,
    },
    {
      path: "/account/song/:id/edit-profile/genres",
      name: "EditSongProfileGenres",
      component: EditSongProfileGenres,
      beforeEnter: requireAuth,
    },
    {
      path: "/account/song/:id/edit-profile",
      name: "EditSongProfile",
      component: EditSongProfile,
      beforeEnter: requireAuth,
    },
    {
      path: "/account/edit-profile",
      name: "EditProfile",
      component: EditUserProfile,
      beforeEnter: requireAuth,
    },
    {
      path: "/account/edit-profile/skills",
      name: "EditUserProfileSkills",
      component: EditUserProfileSkills,
      beforeEnter: requireAuth,
    },
    {
      path: "/account/edit-profile/tags",
      name: "EditUserProfileTags",
      component: EditUserProfileTags,
      beforeEnter: requireAuth,
    },
    {
      path: "/account/chipin/register",
      name: "SetupChipIn",
      component: SetupChipIn,
      beforeEnter: requireAuth,
    },
    {
      path: "/account/settings/sign-in",
      name: "SignInSettings",
      component: SignInSettings,
      beforeEnter: requireAuth,
    },
    {
      path: "/account/settings/songs",
      name: "SongSettings",
      component: SongSettings,
      beforeEnter: requireAuth,
    },
    {
      path: "/account/settings/chipin/plus/songs",
      name: "ChipInPlusSongSettings",
      component: ChipInPlusSongSettings,
      beforeEnter: requireAuth,
    },
    {
      path: "/account/settings/chipin/plus",
      name: "ChipInPlusSettings",
      component: ChipInPlusSettings,
      beforeEnter: requireAuth,
    },
    {
      path: "/account/settings/chipin/donations-made",
      name: "DonationsMade",
      component: DonationsMade,
      beforeEnter: requireAuth,
    },
    {
      path: "/account/settings/chipin/subscriptions-made",
      name: "SubscriptionsMade",
      component: SubscriptionsMade,
      beforeEnter: requireAuth,
    },
    {
      path: "/account/settings/edit",
      name: "EditAccountSettings",
      component: EditAccountSettings,
      beforeEnter: requireAuth,
    },
    {
      path: "/account/settings/chipin",
      name: "ChipInAccountType",
      component: ChipInAccountType,
      beforeEnter: requireAuth,
    },
    {
      path: "/account/settings/change-password",
      name: "ChangePassword",
      component: ChangePassword,
      beforeEnter: requireAuth,
    },
    {
      path: "/account/settings/email",
      name: "EmailSettings",
      component: EmailSettings,
      beforeEnter: requireAuth,
    },
    {
      path: "/account/settings/notifications",
      name: "Notifications",
      component: Notifications,
      beforeEnter: requireAuth,
    },
    {
      path: "/account/settings/credits",
      name: "Credits",
      component: Credits,
      beforeEnter: requireAuth,
    },
    {
      path: "/account/settings/privacy",
      name: "PrivacySettings",
      component: PrivacySettings,
      beforeEnter: requireAuth,
    },
    {
      path: "/account/settings",
      name: "AccountSettings",
      component: AccountSettings,
      beforeEnter: requireAuth,
    },
    {
      path: "/account/settings/dashboard",
      name: "Dashboard",
      component: Dashboard,
      beforeEnter: requireAuth,
    },
    /*{
            path: '/account/chipin/payments',
            name: 'ChipInPayments',
            component: ChipInPayments,
            beforeEnter: requireAuth
        },*/
    // ChipIn Manager
    {
      path: "/account/chipin",
      name: "ChipInAccount",
      component: ChipInAccount,
      beforeEnter: requireAuth,
    },
    {
      path: "/account/chipin/transactions",
      name: "ChipInTransactions",
      component: ChipInTransactions,
      beforeEnter: requireAuth,
    },
    {
      path: "/account/chipin/subscriptions",
      name: "ChipInSubscriptions",
      component: ChipInSubscriptions,
      beforeEnter: requireAuth,
    },
    {
      path: "/account/chipin/payouts",
      name: "ChipInPayouts",
      component: ChipInPayouts,
      beforeEnter: requireAuth,
    },
    {
      path: "/account/chipin/cards/add",
      name: "ChipInAddCard",
      component: ChipInAddCard,
      beforeEnter: requireAuth,
    },
    {
      path: "/account/chipin/cards",
      name: "ChipInCards",
      component: ChipInCards,
      beforeEnter: requireAuth,
    },
    {
      path: "/account/chipin/bank-accounts/add",
      name: "ChipInAddBankAccount",
      component: ChipInAddBankAccount,
      beforeEnter: requireAuth,
    },
    {
      path: "/account/chipin/bank-accounts",
      name: "ChipInBankAccounts",
      component: ChipInBankAccounts,
      beforeEnter: requireAuth,
    },
    // Site pages
    {
      path: "/:id",
      name: "ArtistUsername",
      component: Artist,
    },
    {
      path: "/artist/:id",
      name: "Artist",
      component: Artist,
    },
    {
      path: "/v3/:id",
      name: "ArtistV3",
      component: Artist,
    },
    {
      path: "/:username/:id/list/:list",
      name: "ArtistProfileList",
      component: ArtistProfileList,
      beforeEnter: requireAuth,
    },
    {
      path: "/chipin/:id",
      name: "ChipIn",
      component: Artist,
    },
    {
      path: "/song/:id",
      name: "Song",
      component: Song,
    },
    {
      path: "/song/:username/:id",
      name: "SongProfileID",
      component: Song,
    },
    {
      path: "/:username/:id",
      name: "SongProfile",
      component: Song,
      beforeEnter: function (to, from, next) {
        if (!from.name) { 
          store.commit('storePreviousRoute', from.name)
        }
        next();
      }
    },
    {
      path: "/:username/:id/list/:list",
      name: "SongProfileList",
      component: SongProfileList,
      beforeEnter: requireAuth,
    },
    {
      path: "/chipin/success",
      name: "ChipInSuccess",
      component: ChipInSuccess,
    },
    {
      path: "/chipin/cancel",
      name: "ChipInCancel",
      component: ChipInCancel,
    },
    {
      path: "*",
      name: "404",
      component: Page404,
    },
  ],
});
