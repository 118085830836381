<template>
  
    <div class="text-dark text-left mt-4 pt-3" @click="updateSelected">

        <div class="rounded-circle shadow circle-icon d-inline-block button-border" 
            :class="{
                'bg-dark': $parent.selected !== 'creator',
                'bg-light': $parent.selected === 'creator', 
                'text-light': $parent.selected !== 'creator',
                'text-dark': $parent.selected === 'creator'
            }">
            <img v-if="$parent.selected !== 'creator'" src="../../../assets/onboarding/microphone.svg" class="microphone mt-2"/>
            <img v-else src="../../../assets/onboarding/microphone-dark.svg" class="microphone mt-2"/>
        </div>

        <div class="shadow artist-button-body button-border text-light text-left pl-3"
            :class="{
                'bg-dark': $parent.selected !== 'creator',
                'bg-light': $parent.selected === 'creator', 
                'text-light': $parent.selected !== 'creator',
                'text-dark': $parent.selected === 'creator'
            }">
            <p class="mb-0 button-text">
                <b>Artist!</b> <span class="font-weight-lighter">Show me the tools!</span>
            </p>
        </div>

    </div>

</template>

<script>
export default {
    name: "ArtistAccount",
    methods: {
        updateSelected() {
            console.log(this.$parent.selected)
            this.$emit('onUpdateSelection', 'creator');
        }
    },

}
</script>

<style scoped>

.circle-icon {
    width: 40px;
    height: 40px;
    transform: translate(25%, -10%);
}

.circle-icon:hover {
    cursor: pointer;
}

.artist-button-body {
    width: 234px;
    height: 40px;
    align-items: center;
    display: inline-grid;
}

.artist-button-body:hover {
    cursor: pointer;
}

.button-border {
    border: 1px solid white;
    border-radius: 15px;
}

.microphone {
    width: 13px;
    height: 24px;
    margin-left: 13px;
}

.button-text {
    font-size: 15px;
}

</style>